import { Controller } from "stimulus";
import { getCartCount } from "../helpers/select_listings_helpers";

export default class extends Controller {
  static values = {
    share: Boolean,
    savedSearchId: String
  }
  static targets = [ "button" ]

  connect() {
    if (this.shareValue) {
      this.share();
    }
    this.checkButtonsEnabled();
  }

  share() {
    let params = { 
      type: "search", 
      id: this.savedSearchIdValue,
      flexpanel: true
    };

    window.dispatchEvent(new CustomEvent("display-remote-modal", {
      detail: {
        modalCssClasses: "modal-dialog c-modal c-modal-gray",
        modalHeader: `<div class="t-title--large">Share Search</div>`,
        modalRemote: routes.new_share_path(params)
      }
    }));
  }

  checkButtonsEnabled() {
    this.buttonTargets.forEach((button) => {
      this.toggleButton(button, getCartCount() > 0)
    });
  }

  toggleButton(button, enabled) {
    if(enabled) {
      $(button).removeClass("disabled");
      $(button).attr("title", "")
    } else {
      $(button).addClass("disabled");
      $(button).attr("title", "Select one or more listings")
    }
  }

  shareSelected(evt) {
    evt.preventDefault();
    if (getCartCount() > 0) {
      let params = { 
        "collection_id": 'selected',
        type: "collection",
        "flexpanel": true
      };
      window.dispatchEvent(new CustomEvent("display-remote-modal", {
        detail: {
          modalCssClasses: "modal-dialog c-modal c-modal-gray",
          modalHeader: `<div class="t-title--large">Share Collection</div>`,
          modalRemote: routes.new_share_path(params)
        }
      }));
    }
  }

  shareCollection(evt) {
    evt.preventDefault();
    let params = {
      "collection_id": this.data.get('collectionId'),
      type: "collection",
      "flexpanel": true
    };
    window.dispatchEvent(new CustomEvent("display-remote-modal", {
      detail: {
        modalCssClasses: "modal-dialog c-modal c-modal-gray",
        modalHeader: `<div class="t-title--large">Share Collection</div>`,
        modalRemote: routes.new_share_path(params)
      }
    }));
  }

}