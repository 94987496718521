import { Controller } from "stimulus";
import Cookies from 'js-cookie';
export default class extends Controller {

  showSelected(evt) {
      evt.stopPropagation()
      let selectedHref = evt.target.dataset.href;
      let selected_listing_ids = localStorage.getItem("selectedCart");
      
      if (selectedHref) {
        let url = new URL(window.location.origin + selectedHref)
        url.searchParams.append('results_count', $('.totalCount').first().text())
        url.searchParams.append('results_href', window.location.href)
        url.searchParams.append('selected_listing_ids', selected_listing_ids)
        window.location.href = url.href
      }    
  }

  showResults(evt) {
    evt.stopPropagation()
    let resultsHref = evt.target.dataset.href;
    if (resultsHref) {
      window.location.href = resultsHref
    }
  }

}