import Cookies from 'js-cookie';
import {urlUpdater} from './url_updater';
import mapConverters from '../map/map_converters';


export function MapPositionStorage(scopeKey, map) {
  this.key = scopeKey;
  this.map = map;
}


//  "Class/Static" methods
//  ===========================================

// Delete the map state for a search type. The argument should match the Ruby 
// class name, like "QuickSearch".
MapPositionStorage.deleteByPrefix = function(prefix) {
  var cookie = this.prototype._getMapStateCookie();
  for(var key in cookie) {
    if(key.indexOf(prefix) === 0){
      delete cookie[key];
    }
  }
  Cookies.set('mapState', JSON.stringify(cookie), { path: '/' } );
};



//  "Instance" methods
//  ===========================================

MapPositionStorage.prototype = {
  constructor: MapPositionStorage,

  get: function() {
    var cookie = this._getMapStateCookie();
    return cookie[this.key];
  },

  listenForMapChanges: function() {
    window.addEventListener('stateChange:mapBounds',  this._handleBoundsChange.bind(this));
  },


  //  "Private" methods
  //  ===========================================

  _handleBoundsChange: function (e) { 
    let data;
    const bounds = e.detail.newValue;
    if(bounds !== null && typeof bounds !== 'undefined'){
      const center = bounds.getCenter();

      data = {
        zoom: this.map.getZoom(),
        center: { 
          lat: center.lat(), 
          lng: center.lng() 
        },
        bounds: mapConverters.mapBoundsToSparkString( bounds )
      };    

    }
    this._setCookie(data);

  },

  // The state param should be an object like:
  //    {
  //      bounds: "rectangle('48 -95,46 -97')",
  //      zoom: 10,
  //      center: {
  //        lat: 47,
  //        lng: -96
  //      }
  //    }
  //
  _setCookie: function(state) {
    var cookie = this._getMapStateCookie();
    cookie[this.key] = state;
    Cookies.set('mapState', JSON.stringify(cookie), { path: '/' } );
  },

  _getMapStateCookie: function() {
    var cookie = Cookies.get('mapState');
    if( typeof cookie !== 'undefined' ){
      return JSON.parse( cookie );
    } else {
      return {};
    }
  }

};
