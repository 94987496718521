import { Controller } from "stimulus";
import debounce from 'lodash.debounce';
import Cookies from 'js-cookie';
import {getState, setState} from '../search_results/state'
import Filter from '../filter'

export default class extends Controller {

  connect() {
    // NOT SURE IF THIS IS THE RIGHT WAY TO HANDLE THE RESIZE
    window.addEventListener('resize', debounce(this.windowResize,500))

    if (this.element.dataset.searchResultsSavedsearchId) {
      // For performance reasons it is best to only add the listener if you need to.
      window.addEventListener("beforeunload", this.checkForSearchChanges.bind(this))
    }

    window.addEventListener("pagehide", this.clearTheSessionLdp.bind(this))

    // Make this check before calling other things so we can redirect faster if that
    // is what we are going to do.
    let currentURL = new URL(window.location.href);
    let windowState = window.history.state;

    var filterable = this.data.get('is-filterable');
    var isReactNativeFilterScreenEnabled = window?.nativeAppInterface?.isReactNativeFilterScreenEnabled() ?? false;
    
    if(filterable === 'true'){
       if(isReactNativeFilterScreenEnabled){
        const filter = getState('filter');

        /**
         * Open the Android App's Filter Panel
         * */
        const filterString = filter.toString();
        /**
         * We explicitly want these to be undefined and not null if there is no value
         */
        const templateId = this.data.get('savedsearch-template-id') ?? undefined;
        const quickSearchId = this.data.get('quicksearch-id') ?? templateId;
        const savedSearchId = this.data.get('savedsearch-id') ?? undefined;
        const comparableSearchId = this.data.get('comparablesearch-id') ?? undefined;

        const shapes = filter.shapes();

        window.searchResultsData = {
          filterString,
          quickSearchId,
          savedSearchId,
          comparableSearchId,
          shapes
        };
   
         /*
          Hide the existing filter panel
        */
        document.querySelector('.c-menubar--left')?.classList?.toggle('open');
        document.querySelector('.sub-nav__toggle--left')?.classList?.toggle('active');

        window.nativeAppInterface.displayFilterScreen({ filterString, quickSearchId, savedSearchId, comparableSearchId, shapes });
      }
    }
  }

  disconnect() {
    window.removeEventListener('resize', this.debounceResize)
    if (this.element.dataset.searchResultsSavedsearchId) {
      window.removeEventListener("beforeunload", this.checkForSearchChanges.bind(this))
    }
    window.removeEventListener("pagehide", this.clearTheSessionLdp.bind(this))
    sessionStorage.removeItem("highlightedListingId");

    //window.removeEventListener('popstate', this.handlePopState)
  }

  clearTheSessionLdp(evt) {
    // If we are leaving the page we want to erase this.  The LDP will reload
    // properly if we 'go back' because it is defined in the URL.
    sessionStorage.removeItem("listingsSpaHistory")
  }

  checkForSearchChanges(evt) {
    // We only get here if a saved search has changed.  BUT, if a saved search has
    // changed and we saved those changes to a new search, we dont care about checking
    // the filter.  We want to load the new search as it was saved while keeping the
    // current search untouched.
    if ($('body').attr('data-save-search-complete') !== 'true') {
      if (!getState('filter').equalToOriginal()) {
        evt.preventDefault()
        evt.returnValue = "Changes detected."
      }
    }
  }

  updateCount(data) {
    if (data.detail !== null && typeof data.detail.message !== 'undefined') {
      $('.totalCount').html(data.detail.count);
    }
  }

  filterChanged(data) {
    console.log("FILTER CHANGED DATA: ", data)
  }

  windowResize() {
    console.log("WINDOW RESIZED!")
  }

  filterFormReloaded(data) {
    let count = data.detail.count
    let newExpressions = data.detail.expressions

    setState('listingCountTotal', count)

    let newFilter = new Filter(newExpressions, mapSupport.locationFields)

    let oldExpressions = getState('filter').expressions()

    var added = new Filter(Filter.expressionsAdded(oldExpressions, newExpressions), mapSupport.locationFields)
    var removed = new Filter(Filter.expressionsRemoved(oldExpressions, newExpressions), mapSupport.locationFields)

    // If there is one shape still here, they are all still here.  Only delete annotations if there are no shapes.
    var deleteAnnotations = true;
    newExpressions.forEach( function(expression) {
      if (expression.type == 'shape') {
        deleteAnnotations = true;
      }
    })
    if (deleteAnnotations) {
      setState('annotations',[])
    }

    // TODO: In search_results.js, there is a lot more that goes on before setting the filter state.
    // Still need to figure out how much of that we need to do.
    setState('filter',newFilter)
  }

  /*
  handlePopState(event) {
    const state = history.state;
    if (state) {
      if (state.turbo) {
        console.log("Got a pop state event and it seems as if we need to ignore this turbo state")
        console.log("My session state: ", JSON.parse(sessionStorage.getItem('listingsSpaHistory')));
        return;
      }
      if (state.itemUrl.length > 0) {
        // We have a frame to load.  But don't push do pushState because this load
        // is a result of the previous state going back to here.
        console.log("We have a frame to load.  But don't push do pushState because this load is a result of the previous state going back to here", state)
        //loadLDPTurboFrame(state.itemUrl, false)
      } else {
        // No item to show.  Hide the turbo-frame
        console.log("No item to show.  So we need to do anything else?", state)
        //$('#content-turbo-frame').addClass('hidden');
      }
    } else {
      // We are at the main results page and we just need to make sure there are no
      // listings displayed.
      console.log("We are back at our main page.  Make sure the overlay is hidden????")
      //$('.displayed-in-ldp').removeClass('displayed-in-ldp');
      //$('#content-turbo-frame').addClass('hidden');
    }
  }
  */

  listingCountTotalChanged(data) {
    // Update any header informatin that has the count
    $('.search-results-count .totalCount').text(data.detail.newValue);
  }

}
