import { Controller } from "stimulus";
import axios from 'axios';
import {getState, setState} from '../search_results/state'
import { tableHeaders } from '../helpers/view_helpers'

export default class extends Controller {

  connect() {
    // Set the selected view
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('view_id')) {
      $("#userViews").find('[data-view-id=' + urlParams.get('view_id') + ']').addClass('selected-view')
    }
  }

  viewSelected(evt) {
    evt.preventDefault();
    // Anyone listening for a view change will get it via stateChanged:userViewId event
    setState('userViewId', $(evt.currentTarget).attr('data-view-id'));
    $('#userViews .selected-view').removeClass('selected-view'); // De-select old view, select new view
    $(evt.currentTarget).addClass('selected-view');
    tableHeaders()
  }

}