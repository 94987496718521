import axios from 'axios';
import debounce from 'lodash.debounce';

import customMapControls from './custom_map_controls'
import DrawingManager from './drawing_manager'
import mapConverters from './map_converters'
import MapDataFetcher from './map_data_fetcher'
import mapModals from './map_modals'
import {reasonableNumberShown} from './map_listing_checker'
import {markerManager} from './marker_manager'
import MyLocation from './my_location'
import nosyNeighbor from './nosy_neighbor'
import OverlayManager from './overlay_manager'
import {searchResults} from './../search_results/search_results'
import {getState, setState} from './../search_results/state'
import {MapPositionStorage} from './../search_results/map_position_storage'
import { iteratee } from 'lodash';

// **********************************************
// 
//  Options:
//  
//    mlsDefaultBounds: (array of two points) Required. The default boundaries for the map as specified
//      by the mls from SystemInfo.
// 
//    and more...
// 
// **********************************************


export function FlexmlsMap(element, options) {
  var self = this;

  // Turn on debugging for different things
  this.debug = false;
  this.debugBounds = false;

  this.element = element;
  
  this.loadingMessage = options.loadingMessage;

  this.translations = $.extend({}, options.translations);
  this.currencySymbol = options.currencySymbol;

  this.drawingCompleteCallback = options.drawingCompleteCallback || function(){};

  let mapPositionStorage = new MapPositionStorage(mapSupport.mapScopeKey, this);
  mapPositionStorage.listenForMapChanges();

  this._frozen = false;

  markerManager.init({map: this});

  this.googleMapSettings = {
    disableDefaultUI: true,
    gestureHandling: 'greedy', // All touch gestures and scroll events pan or zoom the map.
    minZoom: 5 // don't let the user zoom out too far
  };

  if(options.mlsDefaultBounds) {
    this.mlsDefaultBounds = mapConverters.getGoogleBoundsFromPoints(options.mlsDefaultBounds);
  }

  // show price pins instead of dots if there are less than this many visible listings
  this.pricePinCutoff = 30;

  this.parcelOutlines = [];

  $('body').append(mapModals.confirmDeleteDrawing(this.translations));
  $('body').append(mapModals.shapeContextMenu(this.translations));

  this.mapDataFetcher = new MapDataFetcher({
    url: mapSupport.clusterDataRetrieverUrl,
    fromList: mapSupport.isListDriven
  });
}

FlexmlsMap.prototype = {
  constructor: FlexmlsMap,

  init: function() {
    this._startLoadingMessage();
    this._createMap();
    
    this.bindMapEvents();
    
    this.overlayManager = new OverlayManager(this.googleMap);
    this.restoreOverlaysToMap();

    this.drawingManager = new DrawingManager(this.googleMap, markerManager);

    customMapControls.init({
      map: this.googleMap, 
      drawingManager: this.drawingManager,
      overlayManager: this.overlayManager
    });

    nosyNeighbor.init(this.googleMap);
    this.bindMapControls();
    this.getLocationAndAddMarker();
  
    this.refreshMapShapes();

    this.drawingCompleteHandler = this.drawingComplete.bind(this);
    this.drawingErrorHandler = this.drawingError.bind(this);
    this.filterChangeHandler = this.handleFilterChanged.bind(this);

    window.addEventListener('map:drawingComplete', this.drawingCompleteHandler);
    window.addEventListener('map:drawingError', this.drawingErrorHandler);
    window.addEventListener('stateChange:filter', this.filterChangeHandler);
  },

  onMapUnload: function() {
    window.removeEventListener('map:drawingComplete', this.drawingCompleteHandler);
    window.removeEventListener('map:drawingError', this.drawingErrorHandler);
    window.removeEventListener('stateChange:mapBounds', this.handleMapBoundsStateChangeHandler );
    window.removeEventListener('stateChange:filter', this.filterChangeHandler);
    this.unBindMapControls()
  },

  handleFilterChanged: function () {
    if(!this._frozen) {
      this.updateMap();
    }
  },

  _handleMapBoundsStateChange: function() {
    if(!this._frozen){
      this.updateMap();
    }
  },

  _createMap: function() {
    var self = this;

    this.handleMapBoundsStateChangeHandler = this._handleMapBoundsStateChange.bind(this)
    this.googleMap = new google.maps.Map(this.element, this.googleMapSettings);
    // Try to restore the map from a previous session.
    const previousSessionBounds = getState('mapBounds');
    if( previousSessionBounds !== null ){
      this._log('restoring previous map position');
      window.addEventListener('stateChange:mapBounds', this.handleMapBoundsStateChangeHandler );
      this.moveMapToBoundary(previousSessionBounds);

    } else {

      let filter = getState('filter');

      if(mapSupport.isListDriven){
        this._log('The map is list driven. Setting up map using the MLS Default Bounds.');
        window.addEventListener('stateChange:mapBounds', this.handleMapBoundsStateChangeHandler );
        this.moveMapToMlsDefaultBounds();
      } else if( filter.locations().length || filter.shapes().length ){
        // If the filter includes locations or shapes shapes, position the map around those shapes.
        this._log('setting up map around locations or shapes');
        this._setUpMapAroundListingData(filter.toString());
      } else {
        // There's no previous session, and there are no shapes in the filter,
        // so use the MLS default bounds.
        this._log('setting up map using the MLS Default Bounds');

        this.moveMapToMlsDefaultBounds();

        const filter = getState('filter').toString();
        
        // The map will be positioned to include the mls default bounds, but the
        // actual map bounds will be larger than the mls default bounds, so we 
        // need to get the the bounds from the map.
        this.getBounds().then((bounds) => {
          
          this._getListingData(filter, bounds).done(() => {

            if (reasonableNumberShown(this.listingData, bounds)) {
              this.drawMarkers();
              setState('mapBounds', bounds);
              window.addEventListener('stateChange:mapBounds', this.handleMapBoundsStateChangeHandler );
            } else {
              this._log( 'Not enough listings are shown. The MLS default bounds will be abandoned and ths listing data bounds will be used instead.' );
              this._setUpMapAroundListingData(filter);
            }

          });
        });

      }
    }
  },


  // Position the map around the listing data. If there are no results, or there
  // is some problem moving the map to the listing data bounds, then fall back to
  // the mls default bounds.
  _setUpMapAroundListingData: function(filter) {

    this._getListingData(filter).done(() => {
      this.moveMapToListingDataBoundary().then((bounds) => {
        this.drawMarkers();
        // The map is already in the right spot and all the listings have been 
        // correctly plotted on the map. The state needs to be set before the 
        // listener is set up so that setting the state doesn't trigger a reload. 
        setState('mapBounds', bounds);
        window.addEventListener('stateChange:mapBounds', this.handleMapBoundsStateChangeHandler );          
      }).catch((error) => {
        // The map may not be in the correct spot. The listener is set before 
        // moving the map so the move will then trigger the necessary reload.
        window.addEventListener('stateChange:mapBounds', this.handleMapBoundsStateChangeHandler );
        this.moveMapToMlsDefaultBounds();
      });

    });
  },

  showDrawingModeHeader: function() {
    if($('#drawingModeHeader').length === 0){
      this.insertDrawingModeHeader();
    }
    $('#mapHeader').css('opacity', 0);
    $('#drawingModeHeader').show();
  },

  hideDrawingModeHeader: function() {
    $('#mapHeader').css('opacity', 1);
    $('#drawingModeHeader').hide();
  },

  insertDrawingModeHeader: function() {
    var headerHtml = $('<div>', {
      class: 'c-header t-type-base-strong drawing-mode-header',
      id: 'drawingModeHeader',
      text: 'Draw to Select Area'
    });

    $('#mapContainer').append(headerHtml);
  },

  refreshMapShapes: function() {
    // We do not refresh the shapes when frozen.  We are probably adding/deleting
    // them or moving the map if we are frozen.  No need to redraw them at that point.
    // This will protect against callers calling this method in a frozen state.
    if (this._frozen) return;

    // clear all the drawings from the map and redraw shapes from the filter
    this.drawingManager.deleteAll();
    markerManager.deleteRadiusMarkers();
    let filter = getState('filter');
    let annotations = getState('annotations');

    if(filter.shapes().length){
      var self = this;
      filter.shapes().forEach(function(element){
        let shape = mapConverters.getGoogleShapeFromFilterShape(element);
        if (shape instanceof google.maps.Circle) {
          markerManager.addRadiusMarker(shape);
        }
        let annotation = self.getAnnotationByCondition(annotations, element.condition);
        self.drawingManager.add(shape,annotation);
        if (annotation == null) {
          var shapeOptions = mapConverters.getNextShapeProperties();
          shape.set("fillColor", shapeOptions.fillColor);
          shape.set("strokeColor", shapeOptions.strokeColor);
          shape.set("fillOpacity", shapeOptions.fillOpacity);
        }
      });
    }
  },

  getAnnotationByCondition: function(annotations, condition) {
    for (var x = 0; x < annotations.length; x++) {
      if (annotations[x].Match.Substring == condition) {
        return annotations[x];
      }
    }
    return null;
  },

  drawParcelOutline: function(polygons) {
    let parcelOutline = new google.maps.Polygon({
      path: polygons,
      geodesic: true,
      strokeColor: '#0077d9',
      strokeOpacity: 1.0,
      strokeWeight: 2,
      fillColor: '#0077d9',
      fillOpacity: 0.35
    });
    this.parcelOutlines.push(parcelOutline);
    parcelOutline.setMap(this.googleMap);    
  },

  deleteParcelOutlines: function() {
    this.parcelOutlines.forEach(function(drawing){
      drawing.setMap(null);
    });
    this.parcelOutlines = [];
  },

  restoreOverlaysToMap: function() {
    var ids = [];
    let filter = getState('filter');
    
    filter.overlays().forEach(function(overlay) {
      overlay.value.forEach(function(value) {
        ids.push(value);
      });
    });

    this.overlayManager.findAndAddShapes(ids);
  },

  getGeoposition: function() {
    var self = this;
    var deferredObject = $.Deferred();
    var myLocation = new MyLocation();

    if(typeof this._geoposition !== 'undefined'){
      deferredObject.resolve(self._geoposition);
    } else {

      function success(geoposition) {
        self._geoposition = {
          lat: geoposition.coords.latitude,
          lng: geoposition.coords.longitude
        };
        deferredObject.resolve(self._geoposition);
      }

      function error(message) {
        deferredObject.resolve();
      }

      myLocation.get({success: success, error: error});
    }

    return deferredObject;
  },

  moveMapToMlsDefaultBounds: function() {
    this._log( 'moving map to the MLS default bounds' );
    return this.moveMapToBoundary(this.mlsDefaultBounds);
  },

  _getListingData: function(filter, bounds) {
    this._log( 'getting listing data' );
    var self = this;
    var listingDataPromise = $.Deferred();

    this._startLoadingMessage();

    var dataFetcherPromise = this.mapDataFetcher.fetch(filter, bounds);

    dataFetcherPromise.done(function(responseData) {
      self.listingData = responseData;

      self._setNewVisibleCountFromClustersData();
      if (self.filterIncompatibleWithClusters()) {
        self.setIncompatibleLinkWarning();
      }
      listingDataPromise.resolve(responseData);
    });

    dataFetcherPromise.fail(function(jqXHR, textStatus, errorThrown){
      listingDataPromise.reject();
    });

    listingDataPromise.fail(function() {
      self.showErrorModal();
    });

    listingDataPromise.always(function() {
      self._stopLoadingMessage();
    });

    return listingDataPromise;
  },

  getBounds: function() {
    var self = this;
    let promise = new Promise(function(resolve, reject) {

      if (typeof self.googleMap  === 'undefined') {
        reject(new Error('the map is undefined'));
      }

      let bounds = self.googleMap.getBounds();

      // If the map is not yet initialized or center and zoom have not been 
      // set then the result is undefined.
      if (bounds !== null && typeof bounds !== 'undefined'){
        resolve( bounds );
      } else {
        // wait for the idle event, or fail after 5 seconds

        google.maps.event.addListenerOnce(self.googleMap, 'idle', () => {
          bounds = self.googleMap.getBounds();
          if(bounds){
            resolve(bounds);
          } else {
            reject(new Error('could not get bounds from map even after idle event'));
          }
        });

        setTimeout(() => {
          reject(new Error('could not get bounds from map after 5 seconds'));
        }, 5000);
      }

    });
    return promise;

  },

  getZoom: function() {
    return this.googleMap.getZoom();
  },

  filterIncompatibleWithClusters: function() {
    let filter = getState('filter');
    return ( filter.toString().indexOf('EmailLink') > -1 || filter.toString().indexOf('SavedSearch') > -1 );
  },

  setIncompatibleLinkWarning: function() {
    var totalCount = getState('listingCountTotal');
    var visibleCount = getState('listingCountVisible');
    this.removeWarningMessage();
    if ( totalCount > 100 && visibleCount === 100 ) {
      var warningMessage = 'This search contains ' + totalCount + ' listings, but only 100 are shown. Zoom in to see more.';
      this.addWarning(warningMessage);
    }
  },

  showErrorModal: function(errorMessage) {
    if(typeof errorMessage == 'undefined'){
      errorMessage = this.translations.error;
    }
    alertModal(errorMessage);
  },

  removeWarningMessage: function() {
    $('.mapWarning').remove();
  },

  addWarning: function(warningMessage) {
    var flash = '<div class="ui-corner-all flash info mapWarning flash-map">';
    flash += '<p>' + warningMessage + '</p>';
    flash += '</div>';
    $('#map').prepend(flash);
  },

  // Returns a promise that resolves the new bounds when successful
  moveMapToListingDataBoundary: function(){

    if( this.listingData.Boundary === null && this.listingData.Clusters.length === 1) {
      var points = mapConverters.sparkBoundaryToPoints(this.listingData.Clusters[0].Centroid);
      this.googleMap.setZoom(17);
      this.moveMapToPoint(points[0]);
      return this.getBounds();
    } else if( this.listingData.Boundary !== null && typeof this.listingData.Boundary !== 'undefined' ) {
      var boundaryPoints = mapConverters.sparkBoundaryToPoints(this.listingData.Boundary);
      var newBounds = mapConverters.getGoogleBoundsFromPoints(boundaryPoints);
      return this.moveMapToBoundary(newBounds);
    } else {
      return Promise.reject(new Error('cannot move the map to the listing data because the listing data is missing'));
    }
  },

  updateMap: function(overrides){
    var self = this;
    var deferredObject = $.Deferred();

    overrides ||= {};

    const filter = typeof overrides.filter !== 'undefined' ? overrides.filter : getState('filter').toString();
    const bounds = typeof overrides.bounds !== 'undefined' ? overrides.bounds : getState('mapBounds');

    self._getListingData(filter, bounds).done(function() {
      self.drawMarkers();
      deferredObject.resolve();
    });
    return deferredObject;
  },

  _setNewVisibleCountFromClustersData: function() {
    if(!mapSupport.isListDriven !== false){
      setState('listingCountVisible', this.mappedListingCount());
    }
  },

  mappedListingCount: function() {
    // The total count is getting stored as a property on the listing data object. 
    // When the map data is reloaded, the whole listingData object will be replaced, 
    // so the TotalCount attribute should never be out of date.
    if(typeof this.listingData.TotalCount === 'undefined'){
      this.listingData.TotalCount = this.listingData.Clusters.reduce((accumulator, cluster) => {
        return accumulator + (cluster.Count || 0);
      }, 0);
    }
    return this.listingData.TotalCount;
  },

  drawMarkers: function() {
    markerManager.deleteMarkers();
    var self = this;
    this.listingData.Clusters.forEach(function(cluster){
      if (cluster.Listings === null){
        markerManager.addClusterMarker(cluster);
      } else {
        self.plotListings.call(self, cluster.Listings);
      }
    });
  },

  moveMapToBoundary: function(googleBoundsObject) {
    let self = this;

    if (this.debugBounds && googleBoundsObject){
      let shape = new google.maps.Rectangle({bounds: googleBoundsObject});
      shape.setMap(this.googleMap);
    }

    // There are cases where we want to know when the map is done being moved.
    // This returns a promise that resolves the new bounds.
    let promise = new Promise(function(resolve, reject) {

      if(typeof googleBoundsObject === 'undefined'){
        self.getBounds().then((bounds) => resolve(bounds) );
      } else {
        // This sets up a one time event listener so that when the map next becomes
        // idle, we can get the bounds and return them.
        google.maps.event.addListenerOnce(self.googleMap, 'idle', () => {
          self.getBounds().then((bounds) => {
            resolve(bounds)
          });
        });
  
        self.googleMap.fitBounds(googleBoundsObject, 0); // second argument is for padding
      }
    });

    return promise;
  },

  moveMapToPoint: function(googleLatLngObject) {
    let self = this;

    return new Promise(function(resolve, reject) {
      google.maps.event.addListenerOnce(self.googleMap, 'idle', () => {
        self.getBounds().then((bounds) => {
          resolve(bounds);
        });
      });

      self.googleMap.setCenter(googleLatLngObject);
    });
  },

  plotListings: function(listingsArray) {
    listingsArray.forEach(this.plotListing.bind(this));
  },

  plotListing: function(listing) {
    var self = this;

    if( this.mappedListingCount() < this.pricePinCutoff && listing.StandardFields.CurrentPrice !== null && 
        typeof listing.StandardFields.CurrentPrice !== 'undefined' ) {
      markerManager.addPriceMarker(listing, this.currencySymbol);
    } else {
      var opts = {
        listingId: listing.Id,
        lat: listing.StandardFields.Latitude,
        lng: listing.StandardFields.Longitude,
        listingStatus: listing.StandardFields.StandardStatus
      }
      markerManager.addListingMarker(opts);
    }
  },

  bindMapEvents: function() {
    this.bindBoundsChanges();
    this.googleMap.addListener('click',     this.deleteTemporaryMarkersAndStuff.bind(this) ); 
    this.googleMap.addListener('dragstart', this.deleteTemporaryMarkersAndStuff.bind(this) ); 
  },

  bindBoundsChanges: function() {
    this.boundsListener = this.googleMap.addListener('bounds_changed', debounce(this.boundsChangedHandler.bind(this), 500) ); 
  },

  deleteTemporaryMarkersAndStuff: function() {
    this.deleteParcelOutlines();
    markerManager.deletePopupMarkers();
  },

  boundsChangedHandler: function() {
    this._log('map bounds change');
    if(!this.drawingManager.drawingInProgress()){
      // it's safe to use googleMap.getBounds here because we are in the 
      // bounds_changed even handler that was triggered by the map
      setState('mapBounds', this.googleMap.getBounds() );
    }
  },

  // 
  // When the map is frozen:
  // 
  // - stateChange:filter events will be ignored
  // - stateChange:mapBounds events will be ignored
  // - the bounds_change listener on the google map will be temporarily removed,
  //   so moving the map will not trigger mapBounds state changes
  //   
  // This function is useful if you want to move the map programatically 
  // without it triggering a data refresh, or if you need to update both the
  // filter and mapBounds in the state, but don't want to trigger two refreshes.
  // 
  // If a function is passed as an argument, the function will be run while the
  // map is frozen and it will be unfrozen automatically afterwards.
  // 
  // If no argument is given, then `unfreeze` needs to be called manually.
  // 
  freeze: function(doWhileFrozen) {
    const previouslyWasFrozen = this._frozen;
    this._log( 'freezing map' );
    this._frozen = true;

    google.maps.event.removeListener(this.boundsListener);

    if(typeof doWhileFrozen === 'function'){
      doWhileFrozen();

      // If the map was previously frozen before freeze was called with a function
      // then we probably shouldn't unfreeze it after the callback runs
      if(!previouslyWasFrozen){
        this.unfreeze();
      }
    }
  },

  unfreeze: function() {
    this._log( 'unfreezing map' );
    this._frozen = false;
    this.bindBoundsChanges();
  },

  bindMapControls: function() {
    $('#myLocation:not(.bound)').addClass('bound').on('click', this.myLocationClickEventHandler.bind(this));
    //$('#drawOnMapBtn:not(.bound)').addClass('bound').on('click', this.drawOnMapClickHandler.bind(this));
    $('#discardDrawingsBtn:not(.bound)').addClass('bound').on('click', this.startDrawing.bind(this));
    $('#cancelDrawOnMapBtn:not(.bound)').addClass('bound').on('click', this.cancelDrawingHandler.bind(this));
    
    $('#shapeMenuDeleteShape:not(.bound)').addClass('bound').on('click', this.confirmDeleteDrawing.bind(this));
    $('#deleteDrawingBtn:not(.bound)').addClass('bound').on('click', this.deleteDrawing.bind(this));
    $('#cancelDeleteDrawingBtn:not(.bound)').addClass('bound').on('click', this.cancelDeleteDrawing.bind(this));
    // This will be called when a the popover is finished constructing/showing itself.
    $('.shapesPopover').on('shown.bs.popover', this.shapesPopoverVisibleHandler.bind(this));
  },

  unBindMapControls: function(){
    $('#myLocation').removeClass('bound').off('click', this.myLocationClickEventHandler.bind(this));
    $('#discardDrawingsBtn').removeClass('bound').off('click', this.startDrawing.bind(this));
    $('#cancelDrawOnMapBtn').removeClass('bound').off('click', this.cancelDrawingHandler.bind(this));
    
    $('#shapeMenuDeleteShape').removeClass('bound').off('click', this.confirmDeleteDrawing.bind(this));
    $('#deleteDrawingBtn').removeClass('bound').off('click', this.deleteDrawing.bind(this));
    $('#cancelDeleteDrawingBtn').removeClass('bound').off('click', this.cancelDeleteDrawing.bind(this));
    $('.shapesPopover').off('shown.bs.popover', this.shapesPopoverVisibleHandler.bind(this));
  },

  doDrawing: function(e) {
    switch(e.target.id)
    {
      case "shapeChoiceCircleBtn":
        this.drawingShape = "circle";
        break;
      case "shapeChoicePolygonBtn":
        this.drawingShape = "polygon";
        break;
      case "shapeChoiceRectangleBtn":
        this.drawingShape = "rectangle";
        break;
      default:
        return;
    }
    this.startDrawing();
  },

  shapesPopoverVisibleHandler: function(e) {
    // When the popover is visible we can attach the close button handler.
    // Before this, the close button doesnt exist.
    // Using the 'bound' class to make sure we don't add the click handler multiple times.
    $('.map-shapes-popover').find('.map-shape-choice-control:not(.bound)').addClass('bound').on('click', this.doDrawing.bind(this));
  },

  myLocationClickEventHandler: function(e) {
    var self = this;

    e.preventDefault();
    this._startLoadingMessage();

    this.getGeoposition().done(function(geoposition) {
      if(typeof geoposition !== 'undefined'){
        var point = new google.maps.LatLng(geoposition.lat, geoposition.lng);
        markerManager.addMyLocationMarker(point);
        self.moveMapToPoint(point);
        self.googleMap.setZoom(15);
      }
      self._stopLoadingMessage();
    });
  },

  getLocationAndAddMarker: function() {
    var self = this;
    this.getGeoposition().done(function(geoposition) {
      if(typeof geoposition !== 'undefined'){
        markerManager.addMyLocationMarker(geoposition);
      }
    });
  },

  drawOnMapClickHandler: function(e) {
    e.preventDefault();
    this.startDrawing();
  },

  startDrawing: function() {
    markerManager.deleteMarkers();
    this.turnOnDrawingUI();

    this.drawingManager.startDrawing(this.drawingShape);
  },

  turnOnDrawingUI: function() {
    $('#drawOnMapBtn').hide();
    $('#cancelDrawOnMapBtn').show();

    this.showDrawingModeHeader();
  },

  turnOffDrawingUI: function() {
    $('#cancelDrawOnMapBtn').hide();
    $('#drawOnMapBtn').show();
    this.hideDrawingModeHeader();
  },

  cancelDrawingHandler: function(e) {
    e.preventDefault();

    let filter = getState('filter');

    const countParams = { 
      _filter: filter.toString()
    };
   
    axios.get(mapSupport.listingCountRetrieverUrl, {params: countParams}).then((response) => {
      setState('listingCountTotal', response.data);
    });  

    this.drawingManager.cancelDrawing();
    this.turnOffDrawingUI();
  },

  drawingComplete: function(e) {
    let newBounds;
    let filter = getState('filter');
    let shapeResults = null;

    if (e.detail instanceof google.maps.Polygon) {
      const polygon = e.detail;
      if(polygon !== null){
        const pointsArray = polygon.getPath();
        if (this.drawingManager.drawings.length > 1)
          newBounds = this.drawingManager.getBoundsForMultipleShapes();
        else
          newBounds = mapConverters.getGoogleBoundsFromPoints(pointsArray);
        shapeResults = filter.addPolygon(pointsArray, "Polygon", polygon.get("fillColor"));
        polygon.set('condition', shapeResults.expression.condition);
      }
    } else if (e.detail instanceof google.maps.Circle) {
      const circle = e.detail;
      var radius = circle.getRadius();
      markerManager.addRadiusMarker(circle);
      // Minimum radius = 0.01 miles (16.0934 meters).
      if (radius < 16.0934) {
        // Reset radius and redraw the circle
        radius = 16.0934;
        circle.setRadius(radius);
      }
      
      // Convert to miles
      var radiusInMiles = radius / 1609.34;
      
      shapeResults = filter.addRadius(circle.getCenter().lat(), circle.getCenter().lng(), radiusInMiles, "Circle", circle.get("fillColor"));
      circle.set('condition', shapeResults.expression.condition);
      if (this.drawingManager.drawings.length > 1)
        newBounds = this.drawingManager.getBoundsForMultipleShapes();
      else
        newBounds = circle.getBounds();
    } else if (e.detail instanceof google.maps.Rectangle) {
      const rectangle = e.detail;
      if (rectangle !== null) {
        shapeResults = filter.addRectangle(rectangle.getBounds(), "Rectangle", rectangle.get("fillColor"));
        rectangle.set('condition', shapeResults.expression.condition);
        if (this.drawingManager.drawings.length > 1)
          newBounds = this.drawingManager.getBoundsForMultipleShapes();
        else
          newBounds = rectangle.getBounds();
      }
    }


    this.turnOffDrawingUI();

    this.drawingCompleteCallback(filter, newBounds, (shapeResults == null ? null : shapeResults.annotation));
  },

  drawingError: function() {
    var message = "<h4>" + this.translations.invalid_shape + "</h4>";
    message += this.translations.invalid_shape_message;
    this.showErrorModal(message);
  },

  confirmDeleteDrawing: function(event) {
    event.preventDefault();
    if (this.drawingManager.selectedShape == null) {
      return;
    }
    $('#shapeContextMenu').css({ display: 'none'});
    $("#confirmDeleteDrawingModal").modal('show');
  },
  
  cancelDeleteDrawing: function(event) {
    event.preventDefault();
    this.drawingManager.selectedShape = null;
    this.drawingManager.resetAllShapesOpacity();
  },

  deleteDrawing: function(event) {
    event.preventDefault();
    // The drawing manager knows what shape we want to delete.
    if (this.drawingManager.selectedShape == null) {
      return;
    }

    let filter = getState('filter');
    // Make a copy of annotations so the state's actual annotations value
    // doesn't change until we call setState.
    let annotations = JSON.parse(JSON.stringify(getState('annotations')));
    let shapeCondition = this.drawingManager.selectedShape.get('condition');

    for(var x = 0; x < annotations.length; x++) {
      // There should be an annotation for the shape.
      // Remove it from the list of annotations.
      if (annotations[x].Match.Substring == shapeCondition) {
        annotations.splice(x,1);
        setState('annotations',annotations);
        break;
      }
    }

    // Remove the shape from the filter
    filter.removeShapeByCondition(shapeCondition);
    // Remove any labels associated with the shape
    markerManager.removeShapeMarker(this.drawingManager.selectedShape);
    // Remove the shape from the map
    this.drawingManager.deleteDrawing(this.drawingManager.selectedShape);
    // Get the bounds for remaining shapes
    var newBounds = this.drawingManager.getBoundsForMultipleShapes();

    if (newBounds) {
      // One or more shapes still remain.  Pretend we just drew a shape
      // and let the callback update the map and the counts.
      this.drawingCompleteCallback(filter, newBounds);
    } else {
      // No shapes left.  Need to use the default bounds and update the
      // map and the count here.
      setState('filter',filter);
      filter = filter.toString();

      this.moveMapToMlsDefaultBounds();
      this.getBounds().then((bounds) => {
        this._getListingData(filter, bounds).done(() => {
          // Update the 'Viewing x of x' count
          axios.get(mapSupport.listingCountRetrieverUrl, {params: {_filter: filter}}).then((response) => {
            setState('listingCountTotal', response.data);
          });

          if (reasonableNumberShown(this.listingData, bounds)) {
            this.drawMarkers();
            setState('mapBounds', bounds);
          } else {
            this._setUpMapAroundListingData(filter);
          }
        });
      });
    }
  },

  _startLoadingMessage: function() {
    if(typeof this.loadingMessage !== 'undefined' && typeof this.loadingMessage.start === 'function'){
      this.loadingMessage.start();
    }
  },

  _stopLoadingMessage: function() {
    if(typeof this.loadingMessage !== 'undefined' && typeof this.loadingMessage.stop === 'function'){
      this.loadingMessage.stop();
    }
  },

  _log: function(message) {
    if(this.debug){
      console.log('[FlexmlsMap] ' + message);
    }
  },

  resetShapesMenu() {
    this.drawingManager.resetShapesMenu()
  }

};
