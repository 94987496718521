import {getState} from './state';

const LARGE_DISPLAY_SIZE = 992;

export function shouldUseDesktopView() {
  return window.innerWidth >= LARGE_DISPLAY_SIZE;
}

export function shouldShowMap() {
  if(mapSupport.mapEnabled === false) {
    return false;
  }

  if(shouldUseDesktopView()) {
    return getState('desktopSearchResultsView') === 'split';
  } else {
    return getState('mobileSearchResultsView') === 'map';
  }
}
