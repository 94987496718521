import mapConverters from './map_converters';
import axios from 'axios';

function MapDataRequest(url, filter, bounds) {
  this.url = url;
  this.filter = filter;
  this.bounds = bounds;
  this.deferred = $.Deferred();
}


MapDataRequest.prototype = {
  constructor: MapDataRequest,

  get: function () {

    var self = this;
    var data = {
      '_filter': this.filter
    };

    if(this.bounds){
      data._location = mapConverters.mapBoundsToSparkString( this.bounds );
    }

    axios.get(this.url, {
      withCredentials: true,
      params: data,
    })
      .then(responseData => { self.deferred.resolve(responseData.data)} )
      .catch(() => { self.deferred.reject() });
  },

  getMapList: function() {

    return axios.get(this.url, {
      withCredentials: true,
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
  }

};

export default MapDataRequest;
