// Trigger the event:
//  
//   window.dispatchEvent(mapEvents.drawingComplete());
//  
// Listen for the event:
// 
//  window.addEventListener('map:drawingComplete', function(){});

var mapEvents = {

  drawingComplete: (shape) => {
    return new CustomEvent('map:drawingComplete', {detail: shape});
  },
  
  drawingError: () => {
    return new CustomEvent('map:drawingError');
  },
  
};

export default mapEvents;
