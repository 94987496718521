function setContainerFocus(container,focus_special_fields) {
  var focusable = container.find('.c-collapsible__content.filter_item_container').find('button, input:not([type=hidden]):not(:disabled), select, textarea, [tabindex]:not([tabindex="-1"])');
  if (focusable.length > 0) {
    if ($(focusable[0]).hasClass('date_field')) {
      if (focus_special_fields) {
        $(focusable[0]).focus();
      }
    } else if ($(focusable[0]).hasClass('select2-hidden-accessible')) {
      if (focus_special_fields) {
        $(focusable[0]).focus();
        $(focusable[0]).select2('open');
      }
    } else {
      $(focusable[0]).focus();
    }
  }
}

function collapseContainer(container) {
  container.removeClass('c-card--expanded');
  container.find('.c-collapsible__title').first().attr('aria-expanded', 'false');
  container.find('.collapse').first().removeClass('in');
  container.find('.collapse').first().attr('aria-expanded','false');
  container.prev('.c-card').prevClass('c-card-next-is-expanded');
}

function expandContainer(container) {
  container.addClass('c-card--expanded');
  container.find('.c-collapsible__title').first().attr('aria-expanded', 'true');
  container.find('.collapse').first().addClass('in').removeAttr('style');
  container.find('.collapse').first().attr('aria-expanded','true');
  container.prev('.c-card').addClass('c-card-next-is-expanded');
}

function checkContainer(container, shouldBeChecked) {
  if (shouldBeChecked) {
    container.find('input[type=checkbox].filter_item_container').prop('checked',true)
    container.find('.c-card-enable-summary').css('color', '#0077d9');
    container.find('.c-collapsible__content.filter_item_container').find('*').removeClass('dont_serialize');
  } else {
    container.find('input[type=checkbox].filter_item_container').prop('checked',false)
    container.find('.c-card-enable-summary').css('color', '#a6a6a6');
    container.find('.c-collapsible__content.filter_item_container').find('*').addClass('dont_serialize');
  }
}

function expandAndScrollContainer(container) {
  SearchItemContainer.expandContainer(container);
  SearchItemContainer.scrollToContainer(container);
}

function scrollToContainer(container) {
  var panelTop = $('#filterPanel').scrollTop();
  var panelBottom = $('#filterPanel').height();
  var myTop = container.position().top;
  var myBottom = myTop + container.height();
  if (myBottom > panelBottom) {
    var scrollTarget = $('#filterPanel')[0].scrollHeight - container.outerHeight();
    $('#filterPanel').scrollTop(scrollTarget);
  } // else the container is already totally visible.  No worries.  Unless the item is to big to fit in the window.  :(
}

function summarizeBooleanOperator(item) {
  let $parent = item.parents('.control-group');
  if ($parent.hasClass('op-not')) {
    return "NOT: ";
  } else if ($parent.hasClass('op-or')) {
    return "OR: ";
  }
  return "";
}

function updateBooleanGroupSummary(changedItem) {
  var summary = "";
  var summary_separator = "";
  changedItem.closest('.bool-group').find('input:checked').each(function() {
    // Get the text and not the other items included with the text.
    summary += summary_separator;
    summary += $(this).next().clone()    //clone the element
    .children() //select all the children
    .remove()   //remove all the children
    .end()  //again go back to selected element
    .text();
    summary_separator = ", ";
  });
  if (summary.length > 0) {
    summary = SearchItemContainer.summarizeBooleanOperator(changedItem) + summary;
  }
  changedItem.closest('.c-card').find('.c-card-enable-summary').text(summary);
}

function updateSelectSummary(target) {
  var resultsArray = [];
  let summary = ''
  $(target).find("option:selected").each(function(){
    if ($(this).val()) {
      resultsArray.push($(this).text());
    }
  });
  summary = resultsArray.join(', ');
  if (summary.length > 0) {
    summary = SearchItemContainer.summarizeBooleanOperator($(target)) + summary;
  }
  $(target).closest('.c-card').find('.c-card-enable-summary').text(summary);
}

function updateSplitControlsSummary(splitControlDiv) {
  var left, right;
  var datepattern = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
  var currency = "" 
  var removeCommas = false;
  var precision=2;

  splitControlDiv.find("input[type=text]").each(function() {
    removeCommas = $(this).hasClass('numeric-no-commas') || removeCommas;
    if (this.id.endsWith('_left')) {
      currency = $(this).hasClass('currency') ? "$" : currency;      
      left = $(this).val();   
    } else if (this.id.endsWith('_right')) {
      currency = $(this).hasClass('currency') ? "$" : currency;
      right = $(this).val();
    }
  });

  splitControlDiv.find("input[type=date]").each(function() {
    if (this.id.endsWith('_left')) {
      left = $(this).val();
      if (/\d{4}-\d{2}-\d{2}/.test(left)) {
        var ymd = left.split('-');
        left = ymd[1] + '/' + ymd[2] + '/' + ymd[0];
      }
      } else if (this.id.endsWith('_right')) {
      right = $(this).val();
      if (/\d{4}-\d{2}-\d{2}/.test(right)) {
        var ymd = right.split('-');
        right = ymd[1] + '/' + ymd[2] + '/' + ymd[0];
      }
    }
  });

  if(!currency){
    precision=3;
  }

  var summary = "";
  if (left.length > 0 && right.length > 0) {
    if (isNaN(left) || isNaN(right)) {
      summary = left + " - " + right;
    } else { 
      left = SearchItemContainer.toFixed(left*1, precision);
      right = SearchItemContainer.toFixed(right*1, precision);
      summary = currency + 
                left.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 20}) + 
                "-" + 
                currency + 
                right.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 20});
    }
  } else if (left.length > 0) {
    if (isNaN(left)) {
      summary = left + " +";
    } else {
      left = SearchItemContainer.toFixed(left*1, precision); 
      summary = currency + left.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 20}) + "+";
    }
  } else if (right.length > 0) {
    if (isNaN(right)) {
      if (datepattern.test(right))
        summary += "Before "
      summary += right;
    } else {
      right = SearchItemContainer.toFixed(right*1, precision); 
      summary = currency + "0 - " + currency + right.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 20});
    }
  } else {
    // Empty summary
  }

  if(removeCommas)
  {
    summary = summary.replaceAll(',', '');     
  }
  if (summary.length > 0) {
    summary = SearchItemContainer.summarizeBooleanOperator(splitControlDiv) + summary;
  }
  splitControlDiv.closest('.c-card').find('.c-card-enable-summary').text(summary);
}

function toFixed(num, fixed) {
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return parseFloat(num.toString().match(re)[0]);
}

function updateRelativeDateSummary(relativeDateDiv) {
  var summary = "";
  relativeDateDiv.find("input[type=text]").each(function() {
    summary += $(this).val();
  });
  relativeDateDiv.find("input[type=hidden]").each(function() {
    summary += " " + $(this).val();
  })
  if (summary.length > 0) {
    summary = SearchItemContainer.summarizeBooleanOperator(relativeDateDiv) + summary;
  }
  relativeDateDiv.closest('.c-card').find('.c-card-enable-summary').text(summary);
}

export var SearchItemContainer = {
  setContainerFocus: setContainerFocus,
  collapseContainer: collapseContainer,
  expandContainer: expandContainer,
  checkContainer: checkContainer,
  expandAndScrollContainer: expandAndScrollContainer,
  scrollToContainer: scrollToContainer,
  summarizeBooleanOperator: summarizeBooleanOperator,
  updateBooleanGroupSummary: updateBooleanGroupSummary,
  updateSelectSummary: updateSelectSummary,
  updateSplitControlsSummary: updateSplitControlsSummary,
  toFixed: toFixed,
  updateRelativeDateSummary: updateRelativeDateSummary
}