import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    let options = {
      startDate: this.data.get('startdate'),
      endDate: this.data.get('enddate'),
      entries: JSON.parse(this.data.get('entries')),
      translations: {
        days: JSON.parse(this.data.get('translate-days')),
        months: JSON.parse(this.data.get('translate-months')),
        key: this.data.get('translate-key'),
        available: this.data.get('translate-available'),
        unavailable: this.data.get('translate-unavailable')
      }
    }
    $('.rentalCalendar').flexRentalCalendar(options)
    $('.rentalCalendar .c-month:gt(1)').hide();
  }
  showMoreCalendar(evt) {
    evt.preventDefault()
    $('.showMoreCalendarLink').hide()
    $('.showLessCalendarLink').show()
    $('.rentalCalendar .c-month').show();
  }

  showLessCalendar(evt) {
    evt.preventDefault()
    $('.showLessCalendarLink').hide()
    $('.showMoreCalendarLink').show()
    $('.rentalCalendar .c-month:gt(1)').hide();
  }

}