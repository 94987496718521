import axios from 'axios';
import {defaultModal} from './components/modals';
import {translations} from './translations.js.erb'

export class ShowingInfo {

  constructor(selector) {
    this.element = $(selector);
    this.listingId = this.element.attr('data-listing-id');
    this.modalId = 'showing-info-modal-' + this.listingId;
    this.contentLoaded = false;

    this.element.click(this._handleClick.bind(this));
  }

  _handleClick(e) {
    this._modal().modal('show');

    if(!this.contentLoaded) {

      const url = routes.showing_instructions_listing_path(this.listingId);

      return axios.get(url).then((response) => {
        this._modal().find('.c-modal__content').html(response.data);
      }).catch(() => {
        this._modal().find('.c-modal__content').html(translations.showing_instructions.index.error);
      }).finally(() => {
        this.contentLoaded = true;
      });
    }
  }

  _modal() {
    if(typeof this.modal === 'undefined') {
      this._insertModal();
      this.modal = $(`#${this.modalId}`).modal();
    }
    return this.modal;
  }

  _insertModal() {
    const modalTitle = translations.showing_instructions.index.title;
    const modal = defaultModal(this.modalId, modalTitle, loadingSpinner(), {class: 'c-modal-gray showing-info-modal'});

    $('body').append(modal);
  }

}
