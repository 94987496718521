import {translations} from '../translations.js.erb'

export class OverlayLegend {

  constructor() {
    this._overlays = []
    this._button = $('#overlayKeyBtn');

    this._button.on('inserted.bs.popover', function() {
      $('.overlayLegendContent').css('max-height', $('#map').height() - 100);
    });
  }

  
  add(meta) {
    this._overlays.push(meta);
    this._buildContent();
    this._button.show();
  }
  
  remove(id) {
    this._overlays = this._overlays.filter( meta => meta.Id !== id);
    this._buildContent();

    if(this._overlays.length === 0){
      this._button.hide();
    }
  }

  _buildContent() {
    let html = '<div class="overlay-legend-content overlayLegendContent">';

    this._overlays.forEach((meta) => {
      html += '<div class="t-title--large">' + meta.Description + ' ' + translations.map.legend + '</div>';
      html += '<table class="overlay-legend-table">'
      meta.Legends.forEach((legend) => {
        html += '<tr>';
        html += '<td><div class="overlay-legend-color" style="background-color: #' + legend.Color + ';"></div></td>'
        html += '<td>' + legend.Description.replace(/'/g, '"') + '</td>'
        html += '</tr>';
      })
      html += '</table>'

      if(typeof meta.DataSource === 'object'){
        html += '<div class="overlay-legend-source">' + translations.maps.overlays_legend_popover.source + ': <a href="' + meta.DataSource.Url + '">' + meta.DataSource.Label + '</a></div>';
      }
    })

    html += '</div>';

    this._button.attr('data-content', html);
  }

}
