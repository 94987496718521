import axios from 'axios';

function OverlayShape(data) {
  this.Id = data.Id;
  this.OverlayId = data.OverlayId;
  this.Name = data.Name;

  // reparse the WKT string to be compatible with Google Maps
  if (data.Wkt) {
    let points = data.Wkt.replace("POLYGON((", "").replace("))", "").split(",");
    let new_points = [];
    let new_geo = "polygon(";
    points.forEach(function(point) {
      let coords = point.split(" ");
      new_points.push(coords[1] + " " + coords[0])
    })
    new_geo += new_points.join(",") + ")";
    this.Geometry = new_geo;
  }
  this.Color = data.Color;
}


OverlayShape.prototype = {
  constructor: OverlayShape

};


OverlayShape.find = function(ids) {
  var deferred = $.Deferred();

  if(!Array.isArray(ids)){
    ids = [ids];
  }

  var filter = "Id Eq " + ids.join(',')

  axios.get(window.routes.shapes_overlays_path(), {
    params: {_filter: filter}
  }).then((response) => {
    var shapes = [];
    response.data.forEach(function(shape) {
      shapes.push(new OverlayShape(shape));
    });
    deferred.resolve(shapes);    
  });

  return deferred;
}

export default OverlayShape;
