// This is a copy of carts_controller.js code, modified so that it can work for multiple selected listings instead of just the current.
// carts_controller.js wasn't modified as it is currently used by the current live route which won't support this.
// This should be combined with carts_controller.js once v1 is launched
import { Controller } from "stimulus";
import { getCartCount } from "../helpers/select_listings_helpers";

export default class extends Controller {

  static targets = [ "button" ]

  cartBodyContent = `<div>
      <div class="form-group max-width">
        <select id="contactForCartAction" class="form-control contact-select" data-placeholder="Choose a contact"></select>
      </div>
    </div>`

  cartModalButtons = `<button class="btn btn-secondary" data-dismiss="modal" type="button">Cancel</button><button disabled id="btnConfirmCartAction" data-action="click->cart-action-modal#confirm" class="btn btn-primary" data-dismiss="modal" data-contact-id="" data-listing-id="LISTING_ID_HERE" data-cart-id="CART_ID_HERE" type="button">OK</button>`

  connect() {
    this.checkButtonsEnabled();
  }

  checkButtonsEnabled() {
    this.buttonTargets.forEach((button) => {
      this.toggleButton(button, getCartCount() > 0)
    });
  }

  toggleButton(button, enabled) {
    if(enabled) {
      $(button).removeClass("disabled");
      $(button).attr("title", "")
    } else {
      $(button).addClass("disabled");
      $(button).attr("title", "Select one or more listings")
    }
  }

  recommend(evt) {
    if (!$(evt.currentTarget).hasClass("disabled")) {
      window.dispatchEvent(new CustomEvent("display-local-modal", {
        detail: {
          modalCssClasses: "modal-dialog modal-sm c-modal cart-modal",
          modalHeader: this.getHeaderContent('Recommend Listing'),
          modalContent: this.cartBodyContent,
          modalFooterContent: this.getFooterContent('Recommended'),
          modalDataController: "cart-action-modal"
        }
      }));
    }
  }

  hide(evt) {
    evt.preventDefault();
    if (!$(evt.currentTarget).hasClass("disabled")) {
      window.dispatchEvent(new CustomEvent("display-local-modal", {
        detail: {
          modalCssClasses: "modal-dialog modal-sm c-modal cart-modal",
          modalHeader: this.getHeaderContent('Hide Listing'),
          modalContent: this.cartBodyContent,
          modalFooterContent: this.getFooterContent('Removed'),
          modalDataController: "cart-action-modal"
        }
      }));
    }
  }

  getHeaderContent(headerText) {
    return `<div class="t-title--large">${headerText}</div>`
  }

  getFooterContent(cartId) {
    let selected = $('.selectListingsControl:checked');
    let listings = selected.toArray().map((sel) => sel.id.split('_')[1]);

    return this.cartModalButtons.replace("LISTING_ID_HERE", listings.join(",")).replace("CART_ID_HERE", cartId)
  }
}