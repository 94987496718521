import Url from 'domurl';

function init(){
  window.addEventListener('stateChange:filter', handleFilterChange);
  window.addEventListener('stateChange:sortId', handleSortIdChange);
  window.addEventListener('stateChange:userViewId', handleUserViewIdChange);
  window.addEventListener('stateChange:mobileSearchResultsView', handleMobileSearchResultsViewChange);
}

function handleFilterChange(e) {
  setQueryParam('_filter', e.detail.newValue.toString());
}

function handleSortIdChange(e) {
  setQueryParam('sort_id', e.detail.newValue);
}

function handleUserViewIdChange(e){
  setQueryParam('view_id', e.detail.newValue);
}

function handleMobileSearchResultsViewChange(e) {
  if(e.detail.newValue === 'map'){
    setViewSegment('map');
  } else {
    setViewSegment('listings');
  }
}

function setQueryParam(name, value) {
  var newUrl = new Url();
  newUrl.query[name] = value;
  updateUrl(newUrl.toString());
}

function updateUrl(newUrl) {
  history.replaceState({}, "", newUrl);
}

function deleteQueryParam(name) {
  var newUrl = new Url();
  delete newUrl.query[name];
  updateUrl(newUrl.toString());
}

// Update the url to show the current view. Argument should be either 'map' or 'listings'.
function setViewSegment(view) {
  if(view !== 'map' && view !== 'listings'){
    throw new Error('Invalid view segment "' + view + '". Must be "listings" or "map".');
  }
  var url = new Url();
  var parts = url.path.split('/');
  parts[parts.length-1] = view;
  url.path = parts.join('/');

  updateUrl(url);
}

export let urlUpdater = {
  init: init,
  deleteQueryParam: deleteQueryParam,
  // exposed for testing only
  _setViewSegment: setViewSegment
};
