import { Controller } from "stimulus";
import { deSelectAllCartAction, selectAllCartAction, setupNewListings } from "../helpers/select_listings_helpers"
import { toggleCartItem } from "../helpers/select_listings_helpers"
import { updateCartCount } from "../helpers/select_listings_helpers"
import { clearCart } from "../helpers/select_listings_helpers"
import { checkCurrentUser } from "../helpers/select_listings_helpers"
export default class extends Controller {

  inSelectMode = false;

  connect() {
    checkCurrentUser();
    updateCartCount();
    setupNewListings();
  }

  updateElement(evt) {
      evt.stopPropagation()

      let checkbox = $(evt.target)
      if (checkbox.prop('tagName') !== 'INPUT') {
        checkbox = $(evt.target).find('input')
        checkbox.prop('checked', !checkbox.prop('checked'))
      }
      var listingKey = checkbox.attr('data-listing-key');
      toggleCartItem(listingKey);
      if (window.self !== window.top) {
        window.parent.dispatchEvent( new CustomEvent("updateCartCount") );
      }
  }

  selectListingElementChanged(data) {
    let isSelectAllChecked = $(".selectAllListingsCheckbox").prop("checked");

    if(isSelectAllChecked){
      $(".selectAllListingsCheckbox").prop("checked", false);
    };

    let allVisibleCheckboxes = $(".selectListingsControl");
    
    if(allVisibleCheckboxes.filter(":checked").length === allVisibleCheckboxes.length){
      $(".selectAllListingsCheckbox").prop("checked", true);
    }

    let checkbox = $('#' + data.detail)
    if (checkbox.prop('tagName') !== 'INPUT') {
      // Not an input.  Look for the input.
      checkbox = checkbox.find('input')
      if (checkbox.length > 0) {
        // If we found it, do the proper checking.
        checkbox.first().prop('checked', !checkbox.prop('checked'))
      }
    }

    // If this is from the LDP, we need to update the the underlying
    // list/summary element's checkbox, if it exists.  It might not
    // be on the page if the LDP was opened from the map.
    if (data.detail.startsWith('ldp_select')) {
      let listCheckbox = $('#' + data.detail.slice(4))
      if (listCheckbox.length > 0) {
        // Found the list/summary checkbox.  Set it to the same value as the ldp checkbox.
        listCheckbox.first().prop('checked', checkbox.prop('checked'))
      }
    }

    var listingKey = checkbox.attr('data-listing-key');
    toggleCartItem(listingKey);
    if (window.self !== window.top) {
      window.parent.dispatchEvent( new CustomEvent("updateCartCount") );
    }
  }

  selectAll() {
    let isChecked = $(".selectAllListingsCheckbox").prop("checked");
    let checkboxes = $(".selectListingsControl");
    checkboxes.prop("checked", isChecked);

    const listingKeys = checkboxes.map(function() {
      return $(this).attr("data-listing-key");
    }).get();

    if(isChecked) {
      selectAllCartAction(listingKeys);
    } else {
      deSelectAllCartAction(listingKeys);
    }
  }


  updateCartCount(evt) {
    updateCartCount()
  }
  

  // TODO:  This should go away when we go live.  Only the old route uses this.
  toggleSelectMode(evt) {
    evt.preventDefault();
    this.inSelectMode = !this.inSelectMode;
    if (this.inSelectMode) {
      setupNewListings();
    }

    // switch buttons
    $('.optionsButton').toggle(!this.inSelectMode);
    $('.shareButton, .saveAndShareButton').toggle(!this.inSelectMode);
    $('.selectListingsDone').toggle(this.inSelectMode);
    $('.selectListingsControl').toggle(this.inSelectMode);
  }

  clearSelected(evt) {
    evt.preventDefault;
    clearCart();
    window.location.href = routes.collections_path();
  }

  refreshSelectedItems() {
    setupNewListings();
  }


//  selectListing(evt) {
//    debugger;
//    window.parent.dispatchEvent( new CustomEvent("add-to-collection") );
//  }

//  addToCollection(evt) {
//    debugger;
//    console.log("Got the add to collection event")
//  }

}