import {Fields} from './fields';

export let Basics = {

  selectors: {
    mlsId:          '#search_fields_MlsId_value',
    propertyType:   '#search_fields_PropertyType_value',
    propertyClass:  '#search_fields_PropertyClass_value',
    mlsStatus:      '#search_fields_MlsStatus_value',
    standardStatus: '#search_fields_StandardStatus_value'
  },

  ids: ['search_fields_MlsId_value',
  'search_fields_PropertyType_value',
  'search_fields_PropertyClass_value',
  'search_fields_MlsStatus_value',
  'search_fields_StandardStatus_value'],
  
  count: $('#counter'),

  previous_mls_ids: [], // this has to be set on ready 

  proptype: function() {
    return $(Basics.selectors.propertyType);
  },

  propertyTypeValues: function() {
    if (Basics.proptype().length == 0 || Basics.proptype().is(':disabled')) {
      return null
    }
    else {
      var vals = Basics.proptype().val()
      if (vals !== null) {
        return vals;
      } else {
        return Basics.proptype().find("option").map(function() { return this.value; }).get();
      }
    }
  },

  propertyClass: function() {
    return $(Basics.selectors.propertyClass);
  },

  propertyClassValues: function() {
    return Basics.propertyClass().val() || []
  },

  mls_status: function() {
    return $(Basics.selectors.mlsStatus);
  },

  standard_status: function() {
    return $(Basics.selectors.standardStatus);
  },

  mls_id: function() {
    return $(Basics.selectors.mlsId);
  },

  status_select: function() {
    if (Basics.mls_status().length) {
      return Basics.mls_status();
    } else {
      return Basics.standard_status();
    }
  },

  type_or_class_select: function() {
    if (Basics.proptype().length) {
      return Basics.proptype();
    } else {
      return Basics.propertyClass();
    }
  },

  mls_id_onchange: function(e) {
    if( !$(this).data('select2').isOpen() ) {
      console.log("MLS_ID_ONCHANGE: FIELDS RELOAD!")
      return Fields.reload();
    }
  },

  selected_status_labels: function() {
    var extract_text, selected_labels;
    selected_labels = function(values) {
      var text_vals, val;
      text_vals = (function() {
        var i, len, results;
        results = [];
        for (i = 0, len = values.length; i < len; i++) {
          val = values[i];
          results.push(extract_text(val));
        }
        return results;
      })();
      return text_vals;
    };
    extract_text = function(val) {
      return val.text;
    };
    return selected_labels($(Basics.status_select()).find('option:selected'));
  },

  handleStatusOrTypeSelectChange: function() {
    if( !$(this).data('select2').isOpen() ) {
      Basics.update_status_date_fields()
      Fields.reload()
    }
  },

  update_status_date_fields: function() {
    var date_field, i, len, ref, required_date_fields, selected_status;
    required_date_fields = [];
    ref = Basics.selected_status_labels();
    for (i = 0, len = ref.length; i < len; i++) {
      selected_status = ref[i];
      date_field = window.status_map[selected_status];
      if (date_field === void 0) {
        continue;
      } else {
        required_date_fields.push(date_field);
      }
    }
    return $('.standardDateField').each(function() {
      var $container;
      $container = $(this);
      date_field = $container.attr('data-field-id');
      if ($.inArray(date_field, required_date_fields) > -1) {
        if ($('#' + date_field + '-collapsible').length == 1) {
          $container.show();
          $('#' + date_field + '-collapsible').parent().show();
          // Need to remove the collapsible div's 'height: 0' style
          $('#' + date_field + '-collapsible').removeAttr('style');
          $('#' + date_field +  '_container a[data-field-id=" ' + date_field + ' "').prop("disabled", false);
          $('#' + date_field +  '_container .relative_time:visible, .relative_direction:visible').prop('disabled', false)
          $container.find("input:visible, input[type='hidden']").removeAttr('disabled');
          return;
        } else {
          $container.show();
          $container.find("input:visible, input[type='hidden']").removeAttr('disabled');
          return;
        }
      } else {
        // The date is not included in the required fields.  Just make sure everything is disabled so it does not
        // get included when we submit the form.
        if ($('#' + date_field + '-collapsible').length == 1) {
          $('#' + date_field + '-collapsible').parent().hide();
          $('#' + date_field + '-collapsible').parent().find('.c-collapsible__content.filter_item_container *').prop('disabled',true);
          $('#' + date_field + '-collapsible').parent().hide();
          return $("#" + date_field + "_container");
        } else {
          $("#search_fields_" + date_field + "_left").attr('disabled', 'disabled');
          $("#search_fields_" + date_field + "_right").attr('disabled', 'disabled');
          $("#search_fields_" + date_field + "_relative_value").attr('disabled', 'disabled');
          $("#search_fields_" + date_field + "_relative_time").attr('disabled', 'disabled');
          $("#search_fields_" + date_field + "_relative_direction").attr('disabled', 'disabled');
          return $("#" + date_field + "_container").hide();
        }
        ;
      }
    });
  },

  bind_selects: function() {
    var typeClassSelector = Basics.selectors.propertyType + ',' + Basics.selectors.propertyClass;
    var statusSelector    = Basics.selectors.mlsStatus + ',' + Basics.selectors.standardStatus;

    $(document).on('change select2:close', Basics.selectors.mlsId, Basics.mls_id_onchange);
    $(document).on('change select2:close', typeClassSelector, Basics.handleStatusOrTypeSelectChange);
    $(document).on('change select2:close', statusSelector, Basics.handleStatusOrTypeSelectChange);
  }

};
