import Cookies from 'js-cookie'
import {AddToCollection} from '../add_to_collection';
import {setState} from './state'

function init() {
  bindSaveListingButtons();
  bindHideListingButtons();
  bindUnhideListingButtons();
  $(document).one('listingsList:itemsAdded moarify:itemsAdded flexPanel:ldpLoaded', function() {
    handlePostLoginCookieIfPresent(Cookies.get(AddToCollection.postLoginCookieName));
  });
}

function bindSaveListingButtons() {
  $(document).on('listingsList:itemsAdded moarify:itemsAdded flexPanel:ldpLoaded', function() {
    $('.consumerSaveListingLink').each(function() {
      if( !$.data(this, 'addToCollection') ){
        $.data(this, 'addToCollection', new AddToCollection(this, {successCallback: saveSuccessCallback}));
      }
    });
  });     
}

function saveSuccessCallback(data) {
  setState('saveListUpdated', data.$element)
  var image = $(data.$element).toggleClass('active');
}

function bindHideListingButtons () {
  $(document).on('listingsList:itemsAdded moarify:itemsAdded flexPanel:ldpLoaded', function() {
    $('.consumerHideListingLink').each(function() {
      if( !$.data(this, 'addToCollection') ){
        $.data(this, 'addToCollection', new AddToCollection(this, {successCallback: hideSuccessCallback}));
      }
    });
  });
}

function hideSuccessCallback(data) {
  var overlay = $(data.$element).closest('.lc-container').find('.hiddenListingOverlay');
  if(overlay.length){
    var visibility = data.added ? 'visible' : 'hidden';
    overlay.css({visibility: visibility});
  }
  $(data.$element).toggleClass('active');
  setState('saveListUpdated', data.$element)
}

function bindUnhideListingButtons () {
  $(document).on('click', '.unhideListingLink', function() {
    var listingContainer = $(this).closest('.lc-container');
    listingContainer.find('.hideListingLink').click();
    listingContainer.find('.consumerHideListingLink').click();
  });
}

function handlePostLoginCookieIfPresent(cookieVal) {
  if (typeof cookieVal !== 'undefined'){
    /*
    * Remove the cookie first. If we run into an error, we don't want to stick
    * users in a redirect loop
    */
    Cookies.remove(AddToCollection.postLoginCookieName);

    /*
     * If the role is now vow/portal, the user logged in so we can add the
     * listing to the collection
     * If the role isn't one of those, they must have hit cancel on the login
     * screen, so we'll no-op here
     */
    if (Flexmls.currentUser.role === "vow" || Flexmls.currentUser.role === "portal") {
      var cookieData = cookieVal.split(',');
      var collectionName = cookieData[0];
      var preLoginlistingId = cookieData[1];
      var starLink = $('#' + preLoginlistingId).find('.consumerSaveListingLink');
      var hideLink = $('#' + preLoginlistingId).find('.consumerHideListingLink');

      if (collectionName === 'Favorites' && !$(starLink).hasClass('active')) {
        $(starLink).click();
      } else if (collectionName === 'Rejects' && !$(hideLink).hasClass('active')) {
        $(hideLink).click();
      }
    }
  }
}

export let consumerListingCardActions = {
  init: init
};
