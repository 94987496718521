import { Controller } from "stimulus";

export default class extends Controller {

  viewShowingInfodModalButtons = `<button id="btnOK" class="btn btn-primary" data-dismiss="modal" type="button">OK</button>`

  view(event) {
    event.preventDefault();
    let listingId = $(event.target).attr('data-listing-id');
    console.log("View showing info ROUTES: ", routes.showing_instructions_listing_path(listingId))

    window.dispatchEvent(new CustomEvent("display-remote-modal", {
      detail: { 
        modalCssClasses: "modal-dialog c-modal c-modal-gray",
        modalHeader: `<div class="t-title--large">Showing Info</div>`,
        modalRemote: routes.showing_instructions_listing_path(listingId),
        modalPrimary: 'OK'
      }
    }));
  }
}