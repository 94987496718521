import routeBuilder from './route_builder'

// 
// WARNING: DO NOT IMPORT THIS MODULE
// 
// The route prefixes are set in scripts. If you import this module into another
// module, you won't have access to those prefixes. Instead use window.routes to
// access routes from webpacker modules.
// 
// 


var routes = (function() {

  var searchRoutes = {

    // Accepts 0, 2 or 3 arguments
    // 2 - search type, search id
    // 3 - contact id, search type, search id
    listings: function() {
      routeBuilder.requireArgs(arguments, 0, 2, 3);
      var path = '';

      if(typeof arguments !== 'undefined'){
        if(arguments.length === 3){
          path += "/contacts/" + arguments[0] + '/' + arguments[1] + '/' + arguments[2];
        } else if (arguments.length === 2) {
          path += "/" + arguments[0] + '/' + arguments[1];
        }
      }

      return path + "/listings";
    },

    // Accepts 1, 3, or 4 arguments
    // 1 - listing id
    // 2 - search type, search id, listing id
    // 3 - contact id, search type, search id, listing id
    listing: function() {
      routeBuilder.requireArgs(arguments, 1, 3, 4);
      var args = Array.prototype.slice.call(arguments)
      var listingId = args.splice(args.length-1, 1)[0];

      return searchRoutes.listings.apply(this, args) + "/" + listingId;
    },

    listing_permissions: function(id) {
      routeBuilder.requireArgs(arguments, 1);
      return searchRoutes.listing(id) + "/permissions";
    },

    showing_instructions_listing: function(id) {
      routeBuilder.requireArgs(arguments, 1);
      return searchRoutes.listing(id) + "/showing_instructions";
    },

    saved_searches: function(contactId) {
      routeBuilder.requireArgs(arguments, 0, 1);
      var prefix = (typeof contactId !== 'undefined') ? '/contacts/' + contactId : ''
      return prefix + '/saved_searches'; 
    },

    saved_search: function() {
      routeBuilder.requireArgs(arguments, 1, 2);
      var contactId;
      if (arguments.length === 2) {
        contactId = arguments[0];
      }
      return searchRoutes.saved_searches(contactId) + '/' + arguments[arguments.length-1]; 
    },

    count_saved_searches: function() {
      return searchRoutes.saved_searches() + '/count';
    },

    favorites_saved_searches: function() {
      return searchRoutes.saved_searches() + '/favorites';
    },

    favorites_count_saved_searches: function() {
      return searchRoutes.favorites_saved_searches() + '/count';
    },

    saved_search_delete_modal: function() {
      routeBuilder.requireArgs(arguments, 1, 2);
      return searchRoutes.saved_search.apply(this, arguments) + '/delete_modal'; 

    },

    bulk_delete_modal_saved_searches: '/saved_searches/bulk_delete_modal',

    collections: function(contactId) {
      routeBuilder.requireArgs(arguments, 0, 1);
      var prefix = (typeof contactId !== 'undefined') ? '/contacts/' + contactId : ''
      return prefix + '/collections'; 
    },

    count_collections: '/collections/count', 

    collection: function() {
      routeBuilder.requireArgs(arguments, 1, 2);
      var contactId;
      if (arguments.length === 2) {
        contactId = arguments[0];
      }
      return searchRoutes.collections(contactId) + '/' + arguments[arguments.length-1]; 
    },

    // Accepts 1 or 2 arguments
    // 1 - collection id
    // 2 - contact id, collection id
    collection_add_listings: function() { 
      routeBuilder.requireArgs(arguments, 1, 2);
      return searchRoutes.collection.apply(this, arguments) + '/add_listings'; 
    },

    new_report_violation: function(listingId) {
      routeBuilder.requireArgs(arguments, 1);
      return "/listings/" + listingId + "/report_violations/new"; 
    },

    integrations_report_violations: function(listingId) {
      routeBuilder.requireArgs(arguments, 1);
      return "/listings/" + listingId + "/report_violations/integrations"; 
    },

    listing_open_house: function(listing_id, open_house_id) {
      routeBuilder.requireArgs(arguments, 2);
      return "/listings/" + listing_id + "/open_houses/" + open_house_id;
    },

    count: "/count",

    mls_overlays: "/overlays/mls",
    my_overlays: "/overlays/my",
    shapes_overlays: "/overlays/shapes",
    overlay: function(overlayId) {
      routeBuilder.requireArgs(arguments, 1);
      return "/overlays/" + overlayId; 
    },

    form_fields: "/fields/form",
    fields_markup: "/fields/markup",

    hot_sheets: "/hot_sheets",

    filter_hot_sheets: "/hot_sheets/filter",

    form_to_filter: "/form_to_filter",

    new_share: "/shares/new",

    nosy_neighbor: "/listings/nosy_neighbor",

    newsfeeds: "/newsfeeds",

    active_newsfeed_groups:           '/newsfeed_groups/active',
    active_count_newsfeed_groups:     '/newsfeed_groups/active_count',
    inactive_newsfeed_groups:         '/newsfeed_groups/inactive',
    inactive_count_newsfeed_groups:   '/newsfeed_groups/inactive_count',
    agent_only_newsfeed_groups:       '/newsfeed_groups/agent_only',
    agent_only_count_newsfeed_groups: '/newsfeed_groups/agent_only_count',

    ids_newsfeed_groups: function(type) {
      routeBuilder.requireArgs(arguments, 1);
      return searchRoutes[type + '_newsfeed_groups'] + '/ids';
    },

    my_sorts: '/sorts/my',
    
    shared_links: '/shared_links'
  }

  var peopleRoutes = {

    contacts: "/api/contacts"
  
  }

  var mobileRoutes = {
    
    contacts_select: "/contacts/select",
    new_collection: "/listings/carts/new_custom_cart"

  }
  

  return Object.assign({}, 
    routeBuilder.buildPaths(peopleRoutes, 'people'),
    routeBuilder.buildPaths(mobileRoutes, 'mobile'),
    routeBuilder.buildPaths(searchRoutes, 'search')
  );

})(); 

export default routes;
