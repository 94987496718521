import MapDataRequest from './map_data_request';

//
//  MapDataFetcher
//  
//    This class is used to get clusters. The clusters will either come from the 
//    api, or they can be generated from the list for searches where the api
//    doesn't support clusters, like newsfeeds.
//  
//  Options argument:
//  
//    url: (required if fromList is not used) The url that cluster requests will be made to.
//    
//    fromList: (boolean, required to be true if url is not given)
//  
//    

function MapDataFetcher(opts) {
  this.url = opts.url;
  this.fromList = opts.fromList;

  this.requests = []; // keep track of pending requests
}


MapDataFetcher.prototype = {
  constructor: MapDataFetcher,

  // 
  // arguments
  // 
  //  filter: (optional) the filter string to be used in the request to the clusters service
  //  
  //  bounds: (optional) the current map bounds, used to limit the clusters location
  //  
  fetch: function(filter, bounds) {
    if(this.fromList) {
      return this._generateClustersFromList();
    } else {
      return this._fetchClusters(filter, bounds);
    }
  },

  _fetchClusters: function(filter, bounds) {
    var self = this;
    // If fetch was called mulitple times with the same args before the first
    // request could be resolved, we can return the deferred object from the
    // first request.
    this.requests.forEach(function(request) {
      if(request.deferred.state() === 'pending' && request.filter === filter && request.bounds === bounds){
        return request.deferred;
      }
    });

    // we don't have a pending request, so let's create a new one
    var newRequest = new MapDataRequest(this.url, filter, bounds);

    // After the request is done and resolved or rejected, delete this from our list.
    newRequest.deferred.always(function() {
      self.requests.forEach(function(r, index) {
        if(r === newRequest) {
          delete self.requests[index];
        }
      })
    });

    // save the request for later in case we need to return it more than once
    this.requests.push(newRequest);
    newRequest.get();

    return newRequest.deferred;
  },

  _fetchListClusterData: function() {
    var self = this;
    var newUrl = this.url.replace('/clusters', '/listings?list_view=map_list')

    var deferred = $.Deferred();
    for (let request of this.requests) {
      if (request.url === newUrl) {
        return request.promise;
      }
    }
    var newRequest = {
      url: newUrl,
      promise: deferred.promise()
    };

    this.requests.push(newRequest)

    // Get listing data from the server that would have normally been in the
    // listing or summary tabs
    const mapDataRequest = new MapDataRequest(newUrl, null, null);

    mapDataRequest.getMapList()
    .then(response => {
      // Massage the data to be in 'cluster' format.
      // Note: No more than 50 listings and single markers only (no clusters)
      var myData = this._generateClustersFromRetrievedData(response.data)
      deferred.resolve(myData)
    })
    .catch(error => {
      deferred.reject(error)
    })

    return newRequest.promise
  },

  // Generate fake cluster data from the listings in the list.
  _generateClustersFromList: function() {

    var response = { Clusters: [], Boundary: "" };

    // The response from this: function should include a boundary box that includes 
    // all the listings. These variables will be the lat/long of the sides of that
    // box. First, we initialize these variables to the "lowest" possible values. 
    // If these values were used as is, you'd have an inside out box with 0 size.
    // The position of each listing will be compared to the variables to see if the 
    // box needs to be expanded. The first listings with a valid lat/lng will reset
    // all four boundary values.
    var minBoundaryLat = 90;
    var minBoundaryLng = 180;
    var maxBoundaryLat = -90;
    var maxBoundaryLng = -180;

    var list;

    if($('.photoListingCardList').is(':visible')){
      list = $('.photoListingCardList .listingListItem')
    } else if($('.tableListingsListBody').is(':visible')){
      list = $('.tableListingsListBody .listingListItem')
    } else if ($('[data-list-listeners-view-value=table]').length > 0 || $('[data-list-listeners-view-value=summary]').length > 0) {
      list = $('.listingListItem')
    } else {
      // We cannot find a list to use for map clusters lets get listings and convert them to points
      return this._fetchListClusterData()
    }

    $(list).each(function() {

      var lat = parseFloat($(this).attr('data-latitude'));
      var lng = parseFloat($(this).attr('data-longitude'));

      if ( lat !== 0 && lng !== 0 ){
        response.Clusters.push({ 
          Centroid: 'point(' + lat + ' ' + lng + ')',
          Count: 1,
          Listings: [{
            Id: $(this).attr('id'),
            StandardFields: { 
              Latitude: lat, 
              Longitude: lng, 
              StandardStatus: $(this).attr('data-standard-status'),
              CurrentPrice: $(this).attr('data-current-price')
            }
          }]
        })

        if (lat < minBoundaryLat) { minBoundaryLat = lat; }
        if (lat > maxBoundaryLat) { maxBoundaryLat = lat; }
        if (lng < minBoundaryLng) { minBoundaryLng = lng; }
        if (lng > maxBoundaryLng) { maxBoundaryLng = lng; }
      }

    });

    response.Boundary = "polygon('" + minBoundaryLat + " " + minBoundaryLng + "," + maxBoundaryLat +" " + maxBoundaryLng +"')";
    
    var deferred = $.Deferred();
    deferred.resolve(response);
    return deferred;
  },   

  _generateClustersFromRetrievedData: function(data) {

    var response = { Clusters: [], Boundary: "" };

    // The response from this: function should include a boundary box that includes
    // all the listings. These variables will be the lat/long of the sides of that
    // box. First, we initialize these variables to the "lowest" possible values.
    // If these values were used as is, you'd have an inside out box with 0 size.
    // The position of each listing will be compared to the variables to see if the
    // box needs to be expanded. The first listings with a valid lat/lng will reset
    // all four boundary values.
    var minBoundaryLat = 90;
    var minBoundaryLng = 180;
    var maxBoundaryLat = -90;
    var maxBoundaryLng = -180;

    data.forEach(function(item) {

      var lat = parseFloat(item.latitude);
      var lng = parseFloat(item.longitude);

      if ( lat !== 0 && lng !== 0 ){
        response.Clusters.push({
          Centroid: 'point(' + lat + ' ' + lng + ')',
          Count: 1,
          Listings: [{
            Id: item.id,
            StandardFields: {
              Latitude: lat,
              Longitude: lng,
              StandardStatus: item.standardStatus,
              CurrentPrice: item.currentPrice
            }
          }]
        })

        if (lat < minBoundaryLat) { minBoundaryLat = lat; }
        if (lat > maxBoundaryLat) { maxBoundaryLat = lat; }
        if (lng < minBoundaryLng) { minBoundaryLng = lng; }
        if (lng > maxBoundaryLng) { maxBoundaryLng = lng; }
      }

    });

    response.Boundary = "polygon('" + minBoundaryLat + " " + minBoundaryLng + "," + maxBoundaryLat +" " + maxBoundaryLng +"')";
    return response;
  }

};

export default MapDataFetcher;
