const LDP_LOADING_SKELETON = `<div class="ldp-overlay">
<div class="listing-detail-content-column flexpanel listingDetailContentColumn skeleton-ldp">
  <div class="skeleton skeleton-ldp-element-sm"></div>
  <div class="skeleton skeleton-ldp-element-md"></div>
  <div class="skeleton skeleton-ldp-element-sm"></div>
  <div class="skeleton skeleton-ldp-element-xlg"></div>
  <div class="skeleton skeleton-ldp-element-lg"></div>
  <div class="skeleton skeleton-ldp-element-lg"></div>
  <div class="skeleton skeleton-ldp-action-bar"></div>
</div>
</div>`

export function showLDPFrame(target) {
    $('.displayed-in-ldp').removeClass('displayed-in-ldp');
    target.addClass('displayed-in-ldp');
    let requestUrl = target.attr('data-href');
    let targetClass = 'lc-thumbnail--container';

    if (target.hasClass('lc-thumbnail--container')) {
      target = target.closest('li');
    }

    let navigation_params = '&navigation=yes'
    if (target.prev().length == 0) {
      navigation_params += 'noprev'
    }

    // If no next item in the list OR if the next item isnt really an item that we care about
    if (target.next().length == 0 || (!target.next().attr('data-href') && target.next().find('[data-href]').length == 0)) {
      navigation_params += 'nonext'
    }
    requestUrl += navigation_params

    window.postMessage({command:'ldpFrame:Show', url: requestUrl}, '*')
}

export function getListItem(listingId) {
  let listItem = $('#' + listingId)
  if (listItem.length === 0) {
    return listItem;
  }

  if (listItem.hasClass('listingsTableRow')) {
    return listItem;
  }

  if (listItem.find('.lc-thumbnail--container')) {
    return listItem.find('.lc-thumbnail--container')
  }

  return null;
}

// FUNCTION FOR NEW 3 PANEL STUFF SO IT DOES NOT INTERFERE WITH CURRENT DATA PROCESSING
export function showLdp(options) {
  var listingId = options.listingId;
  createLdpModal(options)
}

function createLdpModal(options) {
  // This is the listing we want to see
  var listingUrl = new URL(options.listingUrl)
  $('#priorityOverlayWrapper').attr('data-ldp-navigation-src', options.src)
  $('#priorityOverlayWrapper').attr('data-ldp-navigation-prev', options.priorListings)
  $('#priorityOverlayWrapper').attr('data-ldp-navigation-next', options.moreListings)

  let state = {action: 'mainLdp', listingsPageUrl: options.browserUrl, itemUrl: options.listingUrl, itemId: options.listingId, listSrc: options.src, priorListings: options.priorListings, moreListings: options.moreListings};
  
  if (options.pushState) {
    window.history.pushState(state,'',options.browserUrl)
  } else {
    // When the user navigates from one LDP to another, we want to replace that state instead of pushing it so the modal closes when we hit the close button.
    window.history.replaceState(state,'',options.browserUrl)
  }

  recordHistoryInSession(state, options.browserUrl);

  // Prevent user from scrolling the full page while the LDP is open.  On smaller devices page scrolls can hide the ldp
  $('.c-body').addClass('with-ldp');

  $('#priorityOverlay').html(LDP_LOADING_SKELETON);
  $('#priorityOverlay')[0].src = listingUrl.href;

}

export function recordHistoryInSession(state, currentUrl) {
  state["myHref"] = currentUrl;
  sessionStorage.setItem('listingsSpaHistory', JSON.stringify(state));
}

function deleteHistoryFromSession() {
  sessionStorage.setItem('listingsSpaHistory','[]');
}

export function goBackOneLevel($turboframe) {

  var url = new URL($turboframe.attr('src'));
  // Split the pathname of the URL by '/'
  var pathSegments = url.pathname.split('/');
  // Remove the last portion of the url
  pathSegments.pop();
  // Update the pathname of the URL with the modified pathSegments
  url.pathname = pathSegments.join('/');

  $turboframe[0].src = url.href;
}

// A helper function to determine where we are in the tree
export function stepBack(pathname, mymarker) {
  // Split the pathname into segments
  let segments = pathname.split('/');

  // Find the index of mymarker
  let markerIndex = segments.indexOf(mymarker);

  // Ensure there are segments after mymarker
  if (markerIndex < segments.length - 2) {
      // Adjust the URL by removing one segment after mymarker
      let adjustedSegments = segments.slice(0, markerIndex + 2);
      let adjustedPathname = adjustedSegments.join('/');
      return (adjustedPathname.length > 0) ? adjustedPathname : null;
  } else {
      return null;
  }
}

export function replaceLastPathSegment(url, newSegment) {
  const pathSegments = url.pathname.split('/');
  if (pathSegments[pathSegments.length - 1] === '') {
    // There was a trailing '/'.  We dont need to keep that around.
    pathSegments.pop()
  }
  pathSegments[pathSegments.length - 1] = newSegment;
  return pathSegments.join('/');
}
