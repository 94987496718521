
function init(linkSelector){
  $(document).on('click', linkSelector, function(e){
    e.preventDefault();
    var listingId = $(this).attr('data-listing-id');
    showPageLoadingMsg();
    routeIntegrations(listingId);
  });
}

function showViolationModal(listingId){
  $.ajax({
    url: routes.new_report_violation_path(listingId),
    method: "GET",
    success: function(data){
      $("#reportViolationModal").replaceWith(data.html);
      $("#reportViolationModal").modal('show');
    },
    complete: hidePageLoadingMsg
  });
}

function routeIntegrations(listingId){
  $.ajax({
    url: routes.integrations_report_violations_path(listingId),
    method: "GET",
    success: function(response){
      var integration = response.integration
      if(integration){
        hidePageLoadingMsg();
        window.location.href = integration.url;
      } else {
        showViolationModal(listingId)
      }
    },
    error: function(err){
      hidePageLoadingMsg();
      console.log(err)
    }
  })
}

export let ListingViolationReport = {
  init: init
};
