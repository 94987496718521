import {searchResults, map} from './search_results'
import {shouldShowMap} from './helpers'
import {markerManager} from '../map/marker_manager'

// This object is responsible for making sure that changes in the list are
// reflected in the map, and changes in the map are reflected in the list.
// It doesn't do the actual work of updating things. It just sets up all the
// event handlers 

var mouseoverTimeout;

function connect() {

  if(mapSupport.isListDriven) {

    // In list driven mode, we update the map after listings are added.
    $(document).on('listingsList:itemsAdded', function() {
      searchResults.updateMap();
    });

  }

  // In either mode, hovering over list cards should make a preview pin appear on the map. 
  bindListHover();
}

function bindListHover() {
  $(document).on('mouseenter', '.listingListItem', handleCardMouseEnter);
  $(document).on('mouseleave', '.listingListItem', handleCardMouseLeave);
}

function handleCardMouseEnter(e) {
  if(typeof map() !== 'undefined' && shouldShowMap()){
    var li = $(this);
    
    mouseoverTimeout = setTimeout(function() {
      var data = {
        listingId: li.attr('id'),
        listingStatus: li.attr('data-standard-status'),
        lat: li.attr('data-latitude'),
        lng: li.attr('data-longitude')
      };

      markerManager.addPreviewMarker(data);
    }, 400);
  }
}

function handleCardMouseLeave(e) {
  if(typeof map() !== 'undefined'){
    clearTimeout(mouseoverTimeout);
    markerManager.deletePopupMarkers();
  }
}

export let mapListConnection = {
  connect: connect,
};
