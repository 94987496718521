import ClusterMarker from './markers/cluster_marker';
import ListingMarker from './markers/listing_marker';
import MyLocationMarker from './markers/my_location_marker';
import NosyNeighborMarker from './markers/nosy_neighbor_marker';
import PriceMarker from './markers/price_marker';
import PreviewMarker from './markers/preview_marker';
import RadiusMarker from './markers/radius_marker';

// responsible for:
//   * creating markers
//   * attaching markers to the map
//   * keeping track of the marker objects
//   * deleting markers


var map;

var previewMarkerAnchor; // the dot that the preview marker is for
var previewMarker;
var nosyNeighborMarkerAnchor; // the dot that the nosyNeighbor marker is for
var nosyNeighborMarker;
var myRadiusMarkers = [];

var markersArray = [];
var myLocationMarker;
var _maxZIndex = 0;

var previewMarketTimeout;

function init(options) {
  map = options.map;
}

function findMarkerById(listingId) {
  if(typeof listingId === 'undefined'){
    return;
  }
  for(var i = 0; i < markersArray.length; i++) {
    if(markersArray[i].listingId === listingId) {
      return markersArray[i];
    }
  }
}

function getMarkerInfoById(listingId) {
  console.log("getMarkerInfoById", listingId)
  if(typeof listingId === 'undefined'){
    return null;
  }
  for(var i = 0; i < markersArray.length; i++) {
    if(markersArray[i].listingId === listingId) {
      return {
        listingId: listingId,
        listingUrl: '',
        src: 'map',
        priorListings: i > 0,
        moreListings: i < markersArray.length -1,
      }
    }
  }
  return null;
}

function getPreviousMarkerInfo(listingId) {
  if(typeof listingId === 'undefined'){
    return null;
  }

  var currentListingMarkerIndex = markersArray.findIndex(item => item.listingId === listingId)
  var previousListingMarkerIndex = currentListingMarkerIndex - 1
  var prevListingMarker = markersArray[previousListingMarkerIndex]

  if (prevListingMarker) {
    return {
      listingId: prevListingMarker.listingId,
      listingUrl: '',
      src: 'map',
      priorListings: (previousListingMarkerIndex > 0 ? true : false),
      moreListings: (previousListingMarkerIndex == (markersArray.length -1) ? false : true)
    }
  }
  return null;
}

function getNextMarkerInfo(listingId) {
  if(typeof listingId === 'undefined'){
    return null;
  }

  var currentListingMarkerIndex = markersArray.findIndex(item => item.listingId === listingId)
  var nextListingMarkerIndex = currentListingMarkerIndex + 1
  var nextListingMarker = markersArray[nextListingMarkerIndex]

  if (nextListingMarker) {
    return {
      listingId: nextListingMarker.listingId,
      listingUrl: '',
      src: 'map',
      priorListings: (nextListingMarkerIndex > 0 ? true : false),
      moreListings: (nextListingMarkerIndex == (markersArray.length -1) ? false : true)
    }
  }
  return null;
}

function getListingIds() {
  let listingIds = []
  for(var i = 0; i < markersArray.length; i++) {
    if (markersArray[i].listingId) {
      // Return all existing Ids from the markers
      listingIds.push(markersArray[i].listingId)
    }
  }
  return listingIds;
}

function maxZIndex() {
  if(markersArray.length * 2 > _maxZIndex) {
    _maxZIndex = markersArray.length * 2;
  }
  markersArray.forEach(function(m) {
    var index = m.getZIndex();
    if( index > _maxZIndex){
      _maxZIndex = index;
    }
  });
  return _maxZIndex;
}


function addPreviewMarker(listingData, options) {
  if(previewMarker && previewMarker.listingId === listingData.listingId){
    return;
  }

  if(typeof options === 'undefined') {
    options = {};
  }

  deletePopupMarkers();

  var markerOptions = $.extend({}, listingData);

  markerOptions.mapWidth      = function() { return $(map.element).width(); }
  markerOptions.keepAlive     = options.keepAlive;
  markerOptions.showSlideshow = (typeof options.showSlideshow === 'boolean') ? options.showSlideshow : false;

  var listingMarker = findMarkerById(listingData.listingId);

  if(typeof listingMarker === 'undefined') {
    listingMarker = addListingMarker(listingData);
    listingMarker.dependant = true;
  } else {
    // bring the pin to the front
    listingMarker.setZIndex(maxZIndex() + 1);
  }
  markerOptions.listingMarker = listingMarker;

  previewMarkerAnchor = markerOptions.listingMarker;
  previewMarkerAnchor.addClass('has-preview');

  // Delay creating a preview marker until the user's mouse has
  // settled. Otherwise scrolling, transient mouse movements, and
  // the like trigger useless requests.


  // Sometimes the listing marker has been removed or otherwise
  // lost in the iterim. Don't try to add a preview if there is no
  // listing marker.
  if(!listingMarker || !listingMarker.map) {
    return;
  }

  previewMarker = new PreviewMarker(markerOptions);
  previewMarker.setMap(map.googleMap);

  if (!markerOptions.keepAlive) {

    $(previewMarker.label.eventDiv_).on('mouseleave', function(event){
      deletePreviewMarker();
    });

  }
}

function addNosyNeighborMarker(listingData) {

  if(nosyNeighborMarker && nosyNeighborMarker.listingId === listingData.listingId){
    return;
  }

  // Expected Argument Keys: listingId, listingStatus, lat, lng
  var listingMarker = addListingMarker(listingData, {bindEvents: false});

  // We have to wait for the new marker to be added to the map before adding
  // the preview marker. There doesn't seem to be any event that we can 
  // use so just stall for a few ms.
  deletePopupMarkers();
  setTimeout(function(){

    var options = $.extend({}, listingData, {
      listingMarker: listingMarker,
      mapWidth: function() {
        return $(map.element).width();
      }
    });

    nosyNeighborMarker = new NosyNeighborMarker(options);
    nosyNeighborMarker.setMap(map.googleMap);

    nosyNeighborMarkerAnchor = listingMarker;
    nosyNeighborMarkerAnchor.addClass('has-preview');

  }, 200);

}


function deletePopupMarkers() {
  deletePreviewMarker();
  deleteNosyNeighborMarker();
}

function deletePreviewMarker() {
  if(previewMarkerAnchor) {
    previewMarkerAnchor.removeClass('has-preview');
    if(previewMarkerAnchor.dependant) {
      deleteMarkerById(previewMarkerAnchor.listingId);
    }
    previewMarkerAnchor = null;
  }

  if(previewMarker){
    previewMarker.delete();
    previewMarker = null;
  }
}

function deleteNosyNeighborMarker() {
  if(nosyNeighborMarker){
    if(nosyNeighborMarkerAnchor) {
      nosyNeighborMarkerAnchor.removeClass('has-preview');
      deleteMarkerById(nosyNeighborMarkerAnchor.listingId);
    }
    nosyNeighborMarker.delete();
    nosyNeighborMarker = null;
  }
}

function addPriceMarker(listing, currencySymbol, options, foreignCurrency){

  if(typeof options !== 'object') {
    options = {};
  }   

  var opts = {
    listingId: listing.Id,
    lat: listing.StandardFields.Latitude,
    lng: listing.StandardFields.Longitude,
    price: listing.StandardFields.CurrentPrice,
    low_price: listing.StandardFields.ListPriceLow,
    high_price: listing.StandardFields.ListPriceHigh,
    mls_id: (listing.StandardFields.MlsId || listing.MlsId),
    property_type: listing.StandardFields.PropertyType,
    currencySymbol: currencySymbol,
    foreignCurrency: listing.foreignCurrency ? listing.foreignCurrency : foreignCurrency,
    listingStatus: listing.StandardFields.StandardStatus
  };

  let propertyTypeMeta;
  if(typeof window.mapSupport !== 'undefined'){
   propertyTypeMeta = window.mapSupport.propertyTypeMeta;
  }

  if(listing.StandardFields.PropertyClass) {
    opts.propertyClass = listing.StandardFields.PropertyClass;
  } else if (typeof propertyTypeMeta !== 'undefined') {
    var match = propertyTypeMeta.find(function(propertyType) {
      return listing.StandardFields.PropertyType === propertyType.MlsCode;
    });
    if(typeof match !== 'undefined') {
      opts.propertyClass = match.PropertyClass;
    }
  }

  var marker = new PriceMarker(opts);
  addMarkerToMap(marker);
  if(options.bindEvents !== false) {
    marker.bindEvents(map);
  }
  return marker;
}

// Expected Argument Keys: listingId, listingStatus, lat, lng
function addListingMarker(listing, options) {
  if(typeof options === 'undefined') {
    options = {};
  }
  var bindEvents = (typeof options.bindEvents === 'boolean') ? options.bindEvents : true;
  var marker = new ListingMarker(listing);
  addMarkerToMap(marker);
  if(bindEvents){
    marker.bindEvents(map);
  }
  return marker;
}

function addClusterMarker(cluster) {
  var centroidArray = cluster.Centroid.replace("point(", '').replace(")", '').split(" ");

  var opts = {
    lat: parseFloat(centroidArray[0]),
    lng: parseFloat(centroidArray[1]),
    count: cluster.Count,
    location: cluster.Location
  }
  var marker = new ClusterMarker(opts);
  addMarkerToMap(marker);
  marker.bindEvents(map);
  return marker;
}

function addMarkerToMap(marker) {
  markersArray.push(marker);
  marker.setMap(map.googleMap);
}


function addMyLocationMarker(point) {
  if(typeof myLocationMarker !== 'undefined'){
    myLocationMarker.delete()
  }
  myLocationMarker = new MyLocationMarker(point);
  myLocationMarker.setMap(map.googleMap);
}

function deleteMarkers() {
  markersArray.forEach(function(marker) {
    marker.delete();
  });
  markersArray = [];
}

function deleteMarkerById(listingId) {
  for (var i = 0; i < markersArray.length; i++) {
    if (markersArray[i].listingId == listingId) {
      markersArray[i].delete();
      markersArray.splice(i, 1);
      break;
    }
  }
}

function addRadiusMarker(circle) {
  // Try to edit an existing marker first.
  if (!editRadiusMarker(circle)) {
    // If none exist, create a new one.
    var radiusMarker = new RadiusMarker(circle);
    radiusMarker.setMap(map.googleMap);
    myRadiusMarkers.push({ shape: circle, marker: radiusMarker});
  }
}

function editRadiusMarker(circle) {
  var editResult = false;
  // Find the radius marker associated with this shape
  myRadiusMarkers.forEach(function(kv) {
    var shape = kv.shape;
    var marker = kv.marker;
    if (kv.shape == circle) {
      // Remove the current marker
      var map = marker.getMap();
      marker.setMap(null);
      // Create a new marker for this shape
      kv.marker = new RadiusMarker(circle);
      kv.marker.setMap(map);
      editResult = true;
    }
  });
  // True = found and edited a marker
  // False = no marker found for this shape
  return editResult;
}

function deleteRadiusMarkers() {
  myRadiusMarkers.forEach(element => element.marker.setMap(null));
  myRadiusMarkers = [];
}

function removeShapeMarker(shape) {
  var deleteArrayItemIndex = -1;

  for (var index = 0; index < myRadiusMarkers.length; index++) {
    var kv = myRadiusMarkers[index];
    var marker = kv.marker;
    if (kv.shape == shape) {
      marker.setMap(null);
      deleteArrayItemIndex = index;
    }
  }

  if (deleteArrayItemIndex >= 0) {
    myRadiusMarkers.splice(deleteArrayItemIndex,1);
  }
}

export let markerManager = {
  init: init,
  
  addListingMarker: addListingMarker,
  addPriceMarker: addPriceMarker,
  addClusterMarker: addClusterMarker,
  addPreviewMarker: addPreviewMarker,
  addNosyNeighborMarker: addNosyNeighborMarker,

  addMyLocationMarker: addMyLocationMarker,

  addRadiusMarker: addRadiusMarker,
  editRadiusMarker: editRadiusMarker,
  deleteRadiusMarkers: deleteRadiusMarkers,
  removeShapeMarker: removeShapeMarker,

  deleteMarkers: deleteMarkers,
  deleteMarkerById: deleteMarkerById,
  deletePreviewMarker: deletePreviewMarker,
  deletePopupMarkers: deletePopupMarkers,
  getMarkerInfoById: getMarkerInfoById,
  getPreviousMarkerInfo: getPreviousMarkerInfo,
  getNextMarkerInfo: getNextMarkerInfo,
  getListingIds: getListingIds
};
