import { Controller } from "stimulus";
import {getState} from '../search_results/state'
import {SearchTemplateViewControl} from '../search_results/search_template_view_control'

// Debugging helper events
// Can be used to make sure the events are firing when the proper listener(s) is(are) not handling the event yet.
// Can be used to make sure events that you think should be firing are in fact firing.
export default class extends Controller {

  filterChanged(data) {
    if (this.data.get('debug') === 'true' ){
      console.log('DEBUGGER: filter change detected: ', data.detail)
    }
  }

  mapBoundsChanged(data) {
    if (this.data.get('debug') === 'true' ){
      console.log('DEBUGGER: map bounds change detected: ', data.detail)
    }
  }

  sortIdChanged(data) {
    if (this.data.get('debug') === 'true' ){
      console.log('DEBUGGER: sort id change detected: ', data.detail)
    }
  }

  userViewIdChanged(data) {
    if (this.data.get('debug') === 'true' ){
      console.log('DEBUGGER: user view id change detected: ', data.detail)
    }
  }

  mobileSearchResultsViewChanged(data) {
    if (this.data.get('debug') === 'true' ){
      console.log('DEBUGGER: mobile search results view change detected: ', data.detail)
    }
  }

  listItemsAdded(data) {
    if (this.data.get('debug') === 'true' ){
      console.log('DEBUGGER: listing items added event fired: ', data.detail)
    }
  }
  
  moarifyItemsAdded(data) {
    if (this.data.get('debug') === 'true' ){
      console.log('DEBUGGER: moarify items added event fired: ', data.detail)
    }
  }
  
  showLDP(data) {
    if (this.data.get('debug') === 'true' ){
      console.log('DEBUGGER: show LDP event fired: ', data.detail)
    }
  }
  
  showListingCard(data) {
    if (this.data.get('debug') === 'true' ){
      console.log('DEBUGGER: show Listing Card event fired: ', data.detail)
    }
  }
  
  listRequestNext(data) {
    if (this.data.get('debug') === 'true' ){
      console.log('DEBUGGER: request next listing from list view: ', data.detail)
    }
  }

  listRequestPrevious(data) {
    if (this.data.get('debug') === 'true' ){
      console.log('DEBUGGER: request prev listing from list view: ', data.detail)
    }
  }

  selectListingElementChanged(data){
    if (this.data.get('debug') === 'true' ){
      console.log('DEBUGGER: select listiing element changed: ', data.detail)
    }
  }

  listingCountTotalChanged(data) {
    if (this.data.get('debug') === 'true' ){
      console.log("DEBUGGER: listing count total changed: ", data.detail)
    }
  }
}
