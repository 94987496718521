/*
 *    Contact Selector
 *      Inserts a full screen overlay with the Contacts selector
 *
 *    Usage
 *      contactSelector.init(optionsObject);
 *      contactSelector.show();
 *      
 *    Options
 *      iframeUrl:  (required)
 *      iframeId:   The id of the iframe, without '#'.
 *      beforeShow: Function to be called before the iframe is shown
 *      beforeHide: Function to be called before the iframe is hidden
 *      done:       Function to be called when a contact has been selected.
 *                  The selected contact Id will be the first argument.
 *      
 */



var iframeUrl;
var beforeShow = function() {};
var beforeHide = function() {};
var doneCallback = function() {};

var iframeId = 'contactSelectorIframe';

function init(options) {
  if(typeof options === 'undefined' || typeof options.iframeUrl === 'undefined'){
    throw "iframeUrl is a required option";
  }

  iframeUrl = options.iframeUrl;

  if(typeof options.iframeId !== 'undefined'){
    iframeId = options.iframeId;
  }

  if(typeof options.beforeShow === 'function'){
    beforeShow = options.beforeShow;
  }

  if(typeof options.beforeHide === 'function'){
    beforeHide = options.beforeHide;
  }

  if(typeof options.done === 'function'){
    doneCallback = options.done;
  }
}

function show() {
  var iframe = $('<iframe>').attr({src: iframeUrl, id: iframeId});
  iframe.css({
    position: 'fixed',
    'z-index': 1200,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none'
  });

  beforeShow();

  $('body').append(iframe)

  window.addEventListener("message", receiveMessage, false);
}

function receiveMessage(event) {
  if(event.data == 'exit') {
    hide();
  } else if (typeof event.data.contactId !== 'undefined') {
    doneCallback(event.data.contactId);
    hide();
  }
}

function hide() {
  beforeHide();
  $('#' + iframeId).remove();
}

export let contactSelector = {
  init: init,
  show: show
}; 

