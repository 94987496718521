// Opens and closes parenthesis, adds conjunctions
function adjustLevelAndConjunction (state, frag) {
  if (frag.level > state.level) {
    handleElevatedFragmentLevel(state, frag);
  } else if (frag.level < state.level) {
    handleElevatedStateLevel(state, frag);
  } else { // the level doesnt need to be adjusted
    handleBalancedLevels(state, frag);
  }
  
  function handleElevatedFragmentLevel(state, frag) {
    if (frag.conjunction) {
      state.filter += ' ' + frag.conjunction + ' ';
    }
    // Add parens to reach the current fragment's nesting level
    while (frag.level > state.level) {
      state.filter += '(';
      state.level += 1;
    }
  }
  
  function handleElevatedStateLevel(state, frag) {
    // close parens to reach the current fragment's nesting level
    while (frag.level < state.level) {
      state.filter += ')';
      state.level -= 1;
    }
    handleBalancedLevels(state, frag);
  }
  
  function handleBalancedLevels(state, frag) {
    if (state.block_group !== frag.block_group && frag.block_group !== 0) {
      // a new block group needs to be started, so close paren, conjoin, and open paren
      if (frag.conjunction) {
        state.filter += ') ' + frag.conjunction + ' (';
      }
    } else {
      // no nesting level change, and no new blockgroup, so just conjoin
      if (frag.conjunction) {
        state.filter += ' ' + frag.conjunction + ' ';
      }
    }
  }
  
  state.block_group = frag.block_group;
}

function assemble(filterFragments) {

  var expression,
      frag,
      state = {
        filter: '',
        block_group: 0, // each parenthesized expression has a unique block_group
        level: 0 // current level of parenthetical nesting
      };

  if (filterFragments[0]) { // safety first
    // A conjunction joins the current and previous expression
    // therefore the first fragment should not have a conjunction
    filterFragments[0].conjunction = null;
    state.block_group = filterFragments[0].block_group;
  }

  for (var i = 0; i < filterFragments.length; i += 1) {
    expression = [];
    frag = filterFragments[i];

    adjustLevelAndConjunction(state, frag);

    if (frag.unary_operator) {
      expression.push(frag.unary_operator);
    } else if (frag.unary) {
      expression.push(frag.unary);
    }

    var op;
    // 'In' isnt a valid operator - see INTAPI-129
    if(frag.operator === 'In'){
      op = 'Eq';
    } else if(frag.operator === 'Not Eq' || frag.operator === 'Not In'){
      op = 'Ne';
    } else {
      op = frag.operator;
    }

    expression.push(frag.field, op);

    if (frag.type === 'boolean' || frag.type === 'datetime') {
      expression.push(frag.value);
    } else {
      expression.push(frag.condition);
    }

    state.filter += expression.join(' ');
  }

  // close parens as necessary
  for (; state.level > 0; state.level -= 1) {
    state.filter += ')';
  }

  return state.filter;
}

export default assemble;

