function ListingEvent(listingId) {
  'use strict';

  if (!this.isTechId(listingId)) {
    this.assertionFailed('No listing ID provided.');
  }

  this.setListing(listingId);
  this.maxViewedListings = 10;
  this.serviceEndpoint = 'https://gather.flexmls.com/cgi-bin/listing.php';
}

ListingEvent.prototype = {
  constructor: ListingEvent,

  audit: function () {
    var requestUrl,
      self;
    this.assertAuditable();

    this.saveListingEventCookieData();

    requestUrl = this.buildServiceUrl();

    this.submitRequest(requestUrl);
  },

  assertAuditable: function () {
    if (!this.isPresent(this.eventType)) {
      this.assertionFailed('Event type not provided.');
    }

    if (!this.isTechId(this.listingId)) {
      this.assertionFailed('Valid listing ID not provided.');
    }

    if (!this.isTechId(this.userId)) {
      this.assertionFailed('Valid user ID not provided.');
    }

    if (!this.isValidRole(this.role)) {
      this.assertionFailed('Valid role not provided.');
    }

    if (this.isPresent(this.idxLinkId)) {
      if (!this.isTechId(this.idxLinkId) && (this.idxLinkId != 'default')) {
        this.assertionFailed('Valid IDX link ID not provided.');
      }
    }
  },

  assertionFailed: function (errorMessage) {
    if (Error === undefined) {
      throw errorMessage;
    }

    throw new Error(errorMessage);
  },

  buildQueryString: function () {
    var query = '?list_id=',
      viewedListings = this.getViewedListings();

    query += this.listingId;
    query += '&user_id=';
    query += this.userId;
    query += '&type=';
    query += this.getTypeParamValue();

    if (this.isPresent(this.idxLinkId)) {
      query += '&source_id=';
      query += this.idxLinkId;
    }

    if (viewedListings.length > 0) {
      query += '&viewed_listings=';
      query += viewedListings.join(',');
    }

    query += '&';
    query += this.getCacheBusterParamString();

    return query;
  },

  buildServiceUrl: function () {
    return this.serviceEndpoint + this.buildQueryString();
  },

  debug: function () {
    this.debug = true;

    return this;
  },

  getCacheBusterParamString: function () {
    return 'rnd=' + ('1' + Math.random()).substring(3);
  },

  getListingEventCookieData: function () {
    var cookieData = $.cookie('listingEvent');
    if (cookieData === undefined) {
      return {
        searchContext: (this.searchContext === undefined) ? null : this.searchContext,
        viewedListings: []
      };
    }

    return JSON.parse(cookieData);
  },

  getTypeParamValue: function () {
    var value;

    switch (this.role.toLowerCase()) {
    case 'idx':
      value = 'IDX';
      break;
    case 'private':
      value = 'private';
      break;
    case 'vow':
      value = 'portal';
      break;
    case 'public':
    case 'email':
      value = 'email';
      break;
    default:
      value = null;
    }

    return value;
  },

  getViewedListings: function () {
    return this.getListingEventCookieData().viewedListings;
  },

  isPresent: function (value) {
    if (value === undefined || value === null) {
      return false;
    }

    return $.trim(value) !== '';
  },

  isTechId: function (techId) {
    if (!this.isPresent(techId)) {
      return false;
    }

    return (/\d{26}/).test(techId);
  },

  isValidRole: function (role) {
    var current,
      i,
      validRoles = ['email', 'idx', 'portal', 'private', 'public', 'vow'];

    if (!this.isPresent(role)) {
      return false;
    }

    for (i = 0; i < validRoles.length; i += 1) {
      current = validRoles[i];

      if (current === role.toLowerCase()) {
        return true;
      }
    }

    return false;
  },

  log: function (message) {
    if (this.debug !== undefined &&
        this.debug &&
        window !== undefined &&
        window.console &&
        typeof (window.console.log) === 'function') {
      window.console.log(message);
    }
  },

  saveListingEventCookieData: function () {
    var cookieData = this.getListingEventCookieData(),
      cookieDataString,
      current,
      i,
      updatedViewedListings;

    if (!this.isPresent(this.searchContext)) {
      cookieData.searchContext = null;
      cookieData.viewedListings = [ this.listingId ];
    } else {
      if (cookieData.searchContext !== this.searchContext) {
        cookieData.searchContext = this.searchContext;
        cookieData.viewedListings = [ this.listingId ];
      } else {
        updatedViewedListings = [ this.listingId ]; // Front of the line
        for (i = 0; i < cookieData.viewedListings.length; i += 1) {
          current = cookieData.viewedListings[i];
          if (current !== this.listingId) {
            updatedViewedListings.push(current);
          }
          if (updatedViewedListings.length === this.maxViewedListings) {
            break;
          }
        }
        cookieData.viewedListings = updatedViewedListings;
      }
    }

    cookieDataString = JSON.stringify(cookieData);

    this.log(cookieDataString);

    $.cookie('listingEvent', cookieDataString, { path: '/' });
  },

  setEventType: function (eventType) {
    this.eventType = eventType;
  
    return this;
  },

  setIdxLink: function (idxLinkId) {
    this.idxLinkId = idxLinkId;
    this.setSearchContext(idxLinkId);

    return this;
  },

  setListing: function (listing) {
    this.listingId = listing;

    return this;     
  },

  setRole: function (role) {
    this.role = role;

    return this;
  },

  setSearchContext: function (searchContext) {
    this.searchContext = searchContext;

    return this;
  },

  setUser: function (userId) {
    this.userId = userId;

    return this;
  },

  submitRequest: function (requestUrl) {
    let self = this;

    this.log(requestUrl);

    $.ajax({
      url: requestUrl,
      dataType: 'jsonp',
      success: function () {
        self.log('Success!');
      },
      failure: function () {
        self.log('Failure!');
      }
    });
  },

  viewed: function () {
    return this.setEventType('listingViewed');
  }
};

export function logListingEvent(data){

  var listingEvent = new ListingEvent(data.listingId).viewed();
  listingEvent.setUser(data.userId).setRole(data.userRole);

  if(typeof data.searchContext !== 'undefined'){
    listingEvent.setSearchContext(data.searchContext);

    if(typeof data.idxLinkId !== 'undefined'){
      listingEvent.setIdxLink(data.idxLinkId);
    }
  }

  if (window.Flexmls.environment !== "prod") {
    listingEvent.debug();
  }
  listingEvent.audit();
    
}
