import { Controller } from "stimulus"
import { showLdp } from "../helpers/ldp_navigation_helpers"
import { tableHeaders } from '../helpers/view_helpers'
import { shareListing } from '../helpers/share_helpers'

export default class extends Controller {

  mouseoverTimeout = null
  connect() {
    // Make sure the table headers match the loaded view
    tableHeaders();
  }

  tableRowMouseEntered(evt) {
    this.mouseoverTimeout = setTimeout(() => {
      let lat = evt.target.getAttribute('data-latitude') || null
      let lng = evt.target.getAttribute('data-longitude') || null

      let data = null;
      if (lat !== null && lng != null) {
        data = {
          listingId: evt.target.getAttribute('id'),
          listingStatus: evt.target.getAttribute('data-standard-status'),
          lat: lat,
          lng: lng
        }
      }
      this.listingHover(data)
    }, 1000);
  }

  tableRowMouseLeft(evt) {
    clearTimeout(this.mouseoverTimeout)
    this.listingHover(null)
  }

  listingHover(data) {
    // Tell map or any other listener that we would like the listener
    // to display the card for the specified listing.
    dispatchEvent(new CustomEvent('listing:showListingCard', {detail: data}))
  }

  listShareListing(evt) {
    evt.preventDefault()
    shareListing(evt)
  }

  listingClicked(evt) {
    // The user clicked the listing row.  Did they click something
    // that we should process elsewhere?
    if (evt.target.classList.contains('flexicon-ellipsis') || evt.target.classList.contains('flexicon-ellipsis-h') || evt.target.classList.contains('select-listing-control')) return
    if ($(evt.target).closest('.lc-button--container').length >= 1) return

    // Or maybe the dropdown menu was open and we want to close it.
    if (evt.target.classList.contains('dropdown-backdrop')) return

    if(evt.target.type=='checkbox') return

    // if a link within the row was clicked, don't do anything
    if(evt.target.nodeName === 'A') return

    evt.preventDefault()
    // Prevent multiple fast clicks from attempting more than one load of the LDP
    if ($('#priorityOverlay').hasClass('loading')) return
    $('#priorityOverlay').addClass('loading')

    // The user clicked on the table row in a way that tells us to show the LDP

    // Get the actual row (instead of whatever was clicked on inside the row)
    let target = $(evt.currentTarget)
    if (!target.hasClass('listingsTableRow') && !target.hasClass('summary-card')) {
      console.log("Unknown ldp navigation element.");
      return;
    }
    
    // From this location we should't need to do this.  But better safe than sorry.
    $('.current-ldp').removeClass('current-ldp')
    sessionStorage.removeItem("highlightedListingId");

    // Mark the target as the selected/displayed LDP
    target.addClass('current-ldp')
    sessionStorage.setItem(
      "highlightedListingId",
      evt.currentTarget.getAttribute("id")
    );

    // This is the URL that can be shared and will bring you to the search page with the specified LDP loaded.
    let detailUrl = "";
    let newLocation = new URL(window.location.href);
    newLocation.pathname += '/' + evt.currentTarget.getAttribute("id")

    if (window.location.pathname.startsWith('/search/contacts')) {
      detailUrl = newLocation.href.replace('/listings/', '/listing_detail/')
    } else {
      detailUrl = evt.currentTarget.getAttribute('data-href');
      detailUrl = detailUrl.replace('/listings/', '/listing_detail/');
    }

    // Call the helper to show the LDP.
    showLdp({
      listingId: evt.currentTarget.getAttribute('id'),
      listingUrl: detailUrl,
      src: 'list',
      priorListings: target.prev().length > 0,
      moreListings: target.next().length > 0,
      browserUrl: newLocation.href,
      pushState: true
    });
  }

  listingSelectCheckboxClicked(evt) {
    dispatchEvent( new CustomEvent('listing:selectEvent', {detail: $(evt.target).attr('id')} ));
  }

  selectAllListingsClicked(evt) {
    evt.stopPropagation();
    dispatchEvent( new CustomEvent('listings:selectAllEvent'));
  }
}
