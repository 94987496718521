import debounce from 'lodash.debounce';
import Cookies from 'js-cookie'

import {getState} from './state'
import {Moarify} from '@programming/moarify'
import {consumerListingCardActions} from './consumer_listing_card_actions';
import {ShowingInfo} from '../showing_info';
import mapConverters from '../map/map_converters';
import {shouldUseDesktopView} from './helpers';
import {SearchTemplateViewControl} from './search_template_view_control'

export function ListingsList(options) {
  
  this._frozen = false;
  this._photoListWrapper = $('#photoListingsListWrapper');
  this._photoListElement = $('.photoListingCardList');

  this._tableListWrapper = $('.tableListingsList');
  this._tableElement = $('.tableListingsListBody');

  this.propertyTypeMeta = options.propertyTypeMeta;

  this._currentView = this._getView();

  this._mobileSearchResultsViewToggle = new NavPill('.mobileSearchResultsViewToggle');
  
  let photoMoarifyOptions = $.extend({}, this._sharedMoarifyOptions(), {
    appendTo: this._photoListElement
  });
  photoMoarifyOptions.params.list_view = 'photo';
  this._photoMoarify = new Moarify(photoMoarifyOptions);

  let tableMoarifyOptions = $.extend({}, this._sharedMoarifyOptions(), {
    appendTo: this._tableElement,
    moarBtn: '#listingsTableMoarBtn',
    loadingSpinner: '#tableListingsListLoadingSpinner',
  });
  tableMoarifyOptions.params.list_view = 'table';
  this._tableMoarify = new Moarify(tableMoarifyOptions);

  this._setUpViewButtons();
  this._setUpListingCardBindings();

  this._bindStateChanges();
  this._setUpEvents();

  this._setUpBottomActionBtnsHack();


  this._addViewIdToForms($('.selected-view').attr('data-view-id'));

}

ListingsList.prototype = {
  constructor: ListingsList,

  setParam: function(key, value) {
    this._photoMoarify.setParam(key, value);
    this._tableMoarify.setParam(key, value);
  },

  storedDataPresent: function() {
    return this._activeMoarify().storedDataPresent();
  },

  start: function() {
    this._activeMoarify().run();
    this._checkForNoResults(getState('listingCountTotal'));
  },

  reload: function() {
    this._photoMoarify.emptyCache();
    this._tableMoarify.emptyCache();
    this._activeMoarify().reload();
  },

 // 
  // When the list is frozen:
  // 
  // - stateChange:filter events will be ignored
  // - stateChange:mapBounds events will be ignored
  //   
  // This function is useful if you want to update the state without the list reloading.
  // 
  // If a function is passed as an argument, the function will be run while the
  // map is frozen and it will be unfrozen automatically afterwards.
  // 
  // If no argument is given, then `unfreeze` needs to be called manually.
  // 
  freeze: function(doWhileFrozen) {
    const previouslyWasFrozen = this._frozen;
    this._frozen = true;

    if(typeof doWhileFrozen === 'function'){
      doWhileFrozen();

      // If the map was previously frozen before freeze was called with a function
      // then we probably shouldn't unfreeze it after the callback runs
      if(!previouslyWasFrozen){
        this.unfreeze();
      }
    }
  },

  unfreeze: function() {
    this._frozen = false;
  },

  //
  // private functions
  // 
  //
  _sharedMoarifyOptions: function() {
    let filter = getState('filter').toString();
    let options = {
      perPage: 10,
      currentPage: 0,
      infiniteScroll: true,
      totalItems: getState('listingCountTotal'),
      scrollElement: $('#searchResultsListColumn'),
      params: {}
    };

    return options;
  },
  
  _checkForNoResults: function(count) {
    if(count > 0) {
      $('.searchResultsContent').removeClass('no-listings');
    } else {
      $('.searchResultsContent').addClass('no-listings');
    }
  },

  _bindStateChanges: function() {
    window.addEventListener('stateChange:filter', (e) => {
      this.setParam('_filter', e.detail.newValue.toString());
      if(!this._frozen){
        this.reload();
        this._updateTableViewHeader();
      }
    });

    window.addEventListener('stateChange:sortId', (e) => {
      this.setParam('sort_id', e.detail.newValue);
      this.reload();
    });

    window.addEventListener('stateChange:userViewId', (e) => {
      this.setParam('view_id', e.detail.newValue);
      this._addViewIdToForms(e.detail.newValue);
      this.reload();
    });

    window.addEventListener('stateChange:saveListUpdated', (e)=>{
      if(this._currentView == 'table') 
        this.reload();
    })
  },

  

  _activeMoarify: function() {
    if( this._currentView === 'photo' ){
      return this._photoMoarify;
    }
    else if( this._currentView === 'table' ){
      return this._tableMoarify;
    }
  },

  _setUpListingCardBindings: function() {

    // includes event listeners that need to be set up before the list is restored from local storage
    consumerListingCardActions.init();

    $(document).on('listingsList:itemsAdded', function() {
      var tableHeaderPropType = $("#propertyTypeSelector option:selected").val();
      var currListingsPropType = $(".tableListingsListBody tr:first").attr('data-property-type');
      if(tableHeaderPropType != currListingsPropType){
        $("#propertyTypeSelector").val(currListingsPropType);
        let searchTemplateViewControl = new SearchTemplateViewControl('#userViews');
        searchTemplateViewControl._tableHeaders();
      }
      $('.showingInfoLink').each(function() {
        if( !$.data(this, 'showingInfoLink') ){
          $.data(this, 'showingInfoLink', new ShowingInfo(this));
        }
      });
    });
  },

  _setUpViewButtons: function() {
    var self = this;

    if(this._currentView === 'table') {
      this._showTableView();  
    } else {
      this._showPhotoView();
    }

    $('.desktopTableViewLink, .mobileTableViewLink').click(function() {
      self._photoListElement.html(''); //dump the contents so select listings works
      self._currentView = 'table';
      self._showTableView()
      self.reload();
      self._saveView('table');
    });

    $('.desktopPhotoViewLink, .mobilePhotoViewLink').click(function(e) {
      e.preventDefault();
      self._tableElement.html(''); //dump the contents so select listings works
      self._currentView = 'photo';
      self._showPhotoView();
      self.reload();
      self._saveView('photo');
    });

    $('.desktopTableViewLink').click(() => {
      this._mobileSearchResultsViewToggle.select($('.mobileTableViewLink'));
    })

    $('.desktopPhotoViewLink').click(() => {
      this._mobileSearchResultsViewToggle.select($('.mobilePhotoViewLink'));
    })
  },

  _showTableView: function() {
    this._updateTableViewHeader();
    $('.desktopTableViewLink').addClass('active');
    $('.desktopPhotoViewLink').removeClass('active');
    $('.userViewButton').css('visibility', 'visible');
    this._photoListWrapper.hide();
    this._tableListWrapper.show();
    this._checkForHorizontalScroll();
  },

  _showPhotoView: function() {
    $('.desktopTableViewLink').removeClass('active');
    $('.desktopPhotoViewLink').addClass('active');
    $('.userViewButton').css('visibility', 'hidden');
    this._tableListWrapper.hide();
    this._photoListWrapper.show();     
  },

  _setUpEvents: function() {
    this._photoListElement.on('moarify:itemsAdded', function() {
      $(document).trigger('listingsList:itemsAdded');
    });
    this._tableElement.on('moarify:itemsAdded', function() {
      $(document).trigger('listingsList:itemsAdded');
    });

    this._checkForHorizontalScroll();
    $(window).on('resize', debounce(this._checkForHorizontalScroll.bind(this), 250));
  },

  _saveView: function(value) {
    Cookies.set('searchResultsListView', value, {path: '/'});
  },

  _getView: function(value) {
    var cookieValue = Cookies.get('searchResultsListView');
    return (typeof cookieValue !== 'undefined') ? cookieValue : 'photo';
  },

  _updateTableViewHeader: function() {
    var self = this;
    var filter = getState('filter');

    var propertyClasses = filter.propertyClass();

    filter.propertyType().forEach(function(pt) {
      self.propertyTypeMeta.forEach(function(ptMeta) {
        if(ptMeta.MlsCode === pt && propertyClasses.indexOf(ptMeta.PropertyClass) < 0) {
          propertyClasses.push(ptMeta.PropertyClass);
        }
      })
    })
    
    if( propertyClasses.length === 1 && propertyClasses[0] === 'Land' ) {
      this._tableListWrapper.addClass('land-only');
    } else {
      this._tableListWrapper.removeClass('land-only');
    }

  },

  _setUpBottomActionBtnsHack: function() {
    // For the table to scroll horizontally, the list needs "overflow-x: scroll".
    // That causes dropdown menus near the bottom of the table to get cut off. 
    // This adds some extra padding to the table container while the menu is 
    // showing to make sure the menus appear correctly.
    let originalPadding;

    $(document).on('shown.bs.dropdown', '.listings-table-actions .dropdown', (e) => {
      
      if(this._currentView === 'table') {
        let menuDomRect = $(e.target).find('.dropdown-menu')[0].getBoundingClientRect();
        let tableDomRect = $('.searchResultsContent')[0].getBoundingClientRect();
        let bottomOfMenu = menuDomRect.top + menuDomRect.height;
        let bottomOfTable = tableDomRect.top + tableDomRect.height;
        let extraSpaceNeeded = (bottomOfMenu - bottomOfTable);

        if(extraSpaceNeeded > 0){
          originalPadding = parseInt($('.searchResultsContent').css("padding-bottom"));
          $('.searchResultsContent').css({"padding-bottom": originalPadding + extraSpaceNeeded});
        }
      }

    });

    $(document).on('hide.bs.dropdown', '.listings-table-actions .dropdown', (e) => {
      if(this._currentView === 'table' && typeof originalPadding !== 'undefined') {
        $('.searchResultsContent').css({"padding-bottom": originalPadding});
      }
    });
  },

  _checkForHorizontalScroll: function() {
    if(this._currentView === 'table' && this._tableListWrapper.find('.c-card-table-scroller').length > 0) {
      var div = this._tableListWrapper.find('.c-card-table-scroller')[0];
      var hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
      this._tableListWrapper.toggleClass('without-horizontal-scroll', !hasHorizontalScrollbar);
    }
  },

  _setTotalItems: function(number) {
    this._photoMoarify.setTotalItems(number);
    this._tableMoarify.setTotalItems(number);    
  },

  _addViewIdToForms: function(value){
    if(value){
      $('.favoritesToggleLink').attr('href', replaceViewIdInUrl($('.favoritesToggleLink').attr('href'), value));
      $('#saveSavedSearchForm').attr('action', replaceViewIdInUrl($('#saveSavedSearchForm').attr('action'), value));
      $('#new_saved_search_modal form').attr('action', replaceViewIdInUrl($('#new_saved_search_modal form').attr('action'), value));
      $('#saveAndShareForm').attr('action', replaceViewIdInUrl($('#saveAndShareForm').attr('action'), value));
    }
  }

}
function replaceViewIdInUrl(link, view_id){
  if(link){
    if(link.includes('view_id')){
      var newLink = link.replace( /view_id=\d+/, 'view_id='+view_id)
    } else if(link.includes('?')) {
      var newLink = link + '&view_id=' + view_id;
    } else {
      var newLink = link + '?view_id=' + view_id;
    }
  }
  return newLink;
}