import {Basics} from './basics';
import {Fields} from './fields';
import {Field} from './field';

var filterTimerId = null;
var QUERY_TIMEOUT = 250;

var $dropdown;

var events = {
  add: 'addFields:add'
};

function init() {

  $dropdown = $('#addFieldDropdown');

  $('#add_field').click(function(){

    if ($dropdown.css("display") == "none") {
      // Only need to hit the server if we are displaying the drop down
      showPageLoadingMsg();

      $dropdown.find('#find_field').val('').keyup(findField);

      fillAddFieldsDropdown().done(function() {
        hidePageLoadingMsg();
        $dropdown.find('#find_field').focus();
      });
    } // else no need to do anything here.  The dropdown will close without us doing anything.
  });
}

function addFieldListItemClickHandler(e) {
  e.preventDefault();

  if ($(this).parent().hasClass('included')) {
    return;
  }
  var field = new Field($(this).data());
  $(document).trigger(events.add, [[field]]); // double array because we want one arg that's an array
}


function addAllFieldsClickHandler(e) {
  e.preventDefault();

  var fields = [];
  var groupName = $(this).data("group-name");

  $('.add_a_field_list_item[data-group-name="' + groupName + '"]').each(function (i) {

    if ($(this).parent().hasClass('included')) {
      return;
    }

    var field = new Field($(this).data());
    fields.push(field);
  });

  $(document).trigger(events.add, [fields]); // double array because we want one arg that's an array
}

function fillAddFieldsDropdown(){

  var deferredObject = $.Deferred();

  var data = {
    _fields_present: Fields.fields_present().join(","),
    _mls_ids: (Basics.mls_id().val() || []).join(',')
  };

  if (Basics.propertyTypeValues() !== null) {
    data["_property_types"] = Basics.propertyTypeValues().join(',')
  }
  else {
    data["_property_class"] = Basics.propertyClassValues().join(',')
  }

  $dropdown.find('#list-o-fields').empty();

  $.ajax({
    url: routes.fields_markup_path(),
    type: 'post',
    data: data,
    dataType: 'html',
    success: function(data) {
      $dropdown.find('#list-o-fields').html(data)
      $('.add_a_field_list_item').click(addFieldListItemClickHandler);
      $('.addAllFieldsLink').click(addAllFieldsClickHandler);

    },
    error: function(xhr, textStatus, errorThrown) {
      Log.error('Ajax request to /fields.html: [' + textStatus + '][' + errorThrown + ']');
      alertModal('We encountered an error loading the field list. Please refresh the page and try again.');
    },
    complete: function() {
      deferredObject.resolve();
    }
  })

  return deferredObject;
}


function findField() {
  var val = this.value;

  if ($.trim(val) === '') {
    $dropdown.find(".add-field-section-header, li").show();
    return;
  }

  // clear the old queued function, if one exists,
  // then go on to running a new lookup
  if (filterTimerId !== null) {
    clearTimeout(filterTimerId);
  }
  filterTimerId = setTimeout(function(){
    theRealFieldFinder(val);
  }, QUERY_TIMEOUT);
}



function theRealFieldFinder(val) {
  var re = RegExp(val,'i'); // case insensitive

  $dropdown.find("ul").each(function(x) {
    var $this = $(this) 
    var lis = $this.find('li')
    lis.hide()
    lis.filter(function(){
      return re.test(this.innerHTML);
    }).show().parent().prev('.add-field-section-header').show();

    if ($this.find('li:visible').length === 0) {
      $this.prev('.add-field-section-header').hide();
    }
  })
}


export var addFields = {
  init: init
};
