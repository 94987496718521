function MyLocation() {
}

MyLocation.prototype = {

  constructor: MyLocation,

  get: function(args){
    
    if ( typeof this.geoposition !== 'undefined' ){
      if ( typeof args.success === 'function' ) {
        args.success(this.geoposition);
      }
    } else {
      var self = this;

      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      function success(geoposition){
        if(typeof args.success === 'function'){
          self.geoposition = geoposition;
          args.success(geoposition);
        }
      }

      function error(message) {
        if(typeof args.error === 'function'){
          args.error(message);
        }
      }

      navigator.geolocation.getCurrentPosition(success, error, options);
    }
  }

}

export default MyLocation;
