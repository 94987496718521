export function ContactsDropdown(selector) {

  this.element = $(selector);

  this.element.flexSelect2({
    ajax: {
      delay: 250,
      url: routes.contacts_path(),
      data: this._ajaxData,
      processResults: this._processAjaxResults,
      
    },
    dropdownParent: $(selector).parent(),
    dropdownCssClass: 'new-message-recipient-dropdown',
    escapeMarkup: function (markup) { 
      // Override the built in function and don't escape anything so the
      // noResults html will work
      return markup; 
    }
  });

}

ContactsDropdown.prototype = {
  constructor: ContactsDropdown,

  // pass event listeners to the select element
  on: function(event, callback) {
    this.element.on(event, callback);
  },

  unbind: function(event, callback) {
    this.element.unbind(event, callback);
  },

  destroy: function() {
    if(typeof this.element !== 'undefined'){
      this.element.select2('destroy');
      this.element.empty();
    }
  },

  _ajaxData: function(params) {
    return { 
      q: params.term,
      page: params.page || 1
    };
  },

  _processAjaxResults: function(data, params) {
    var results = $.map(data.results, function(result) {
      return {
        id: result.Id,
        text: result.DisplayName,
        email: result.PrimaryEmail,
        primaryPhoneNumber: result.PrimaryPhoneNumber
      };
    });

    return { 
      results: results,
      pagination: {
        more: data.pagination.page < data.pagination.total_pages
      }
    };
  }

};
