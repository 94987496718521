import filterAssembler from './map/filter_assembler'
import mapConverters from './map/map_converters'

function Filter(parsedFilter, locationFields) {

  this.modified = false;
  // The list of expressions that make up the filter
  this._data = parsedFilter;
  // 'City', 'CountyOrParish', 'PostalCode' etc.
  this._locationFields = locationFields;

}

// returns an array containing the expressions from exA that are not present in exB
Filter.expressionsRemoved = function(exA, exB) {
  return this.expressionsAdded(exB, exA);
};

// returns an array containing the expressions from exB that are not present in exA
Filter.expressionsAdded = function(exA, exB) {
  var exC = [];

  exB.forEach(function(expressionB, indexB) {
    var match = false;
    exA.forEach(function(expressionA) {
      try {
        if(expressionB.field === expressionA.field){
          if(expressionB.value.sort().toString().replace(/'/g, '') === expressionA.value.sort().toString().replace(/'/g, '')) {
            match = true;
          }
        }
      }
      catch(e) {
        match = false;
      }
    });
    if(!match){
      exC.push(expressionB);
    }
  });

  return exC;
};

Filter.prototype = {
  constructor: Filter,

  setData: function(newData) {
    if(newData !== this._data){
      this._data = newData;
      this.modified = true;
    }
  },

  expressions: function() {
    return this._data;
  },

  shapes: function() {
    return this._data.filter(function(element){
      return ( element.type === "shape" && element.function_name !== null );
    });
  },

  overlays: function() {
    return this._data.filter(function(element){
      return element.field === "MapOverlay";
    });
  },

  locations: function() {
    var self = this;
    return this._data.filter(function(element){
      return self._locationFields.indexOf(element.field) > -1;
    });    
  },
  
  addShapeToFilter: function(expression) {
    var block_group = 0;

    var firstExistingShapeIndex = -1;
    var lastExistingShapeIndex = -1;
    var lastBlockGroup = 0;

    // Find the first and last shape in the existing set of expressions.
    for (var x = 0; x < this._data.length; x++) {
      if (this._data[x].type == 'shape') {
        if (firstExistingShapeIndex == -1) {
          firstExistingShapeIndex = x;
        }
        lastExistingShapeIndex = x;
      }
      // Get the last block group so, if we need to, we can set the shape block group to a unique number as required.
      lastBlockGroup = (this._data[x].block_group > lastBlockGroup) ? this._data[x].block_group : lastBlockGroup;
    }

    if (lastExistingShapeIndex == -1) {
      // There are no shapes in the existing set of expressions.
      // This shape should be added like this...
      expression.level = 0;
      expression.block_group = 0;
      expression.conjunction = 'And';
      this._data.push(expression);
    } else if (firstExistingShapeIndex == lastExistingShapeIndex) {
      // There is 1 other shape.  Lets create a group for them
      lastBlockGroup++;
      this._data[lastExistingShapeIndex].level = 1;
      this._data[lastExistingShapeIndex].conjunction = "And";
      this._data[lastExistingShapeIndex].block_group = lastBlockGroup;
      expression.level = 1;
      expression.conjunction = 'Or';
      expression.block_group = lastBlockGroup;
      this._data.splice(lastExistingShapeIndex + 1,0, expression);
    } else {
      // There are multiple shapes that already exist.  Use their level and block_group
      expression.level = this._data[lastExistingShapeIndex].level;
      expression.conjunction = 'Or';
      expression.block_group = this._data[lastExistingShapeIndex].block_group;
      this._data.splice(lastExistingShapeIndex + 1,0, expression);
    }
  },

  addPolygon: function(pointsArray, polygonName, polygonColor) {

    const pointsString = mapConverters.getStringFromPoints(pointsArray);

    let expression = {
      block_group: 0,
      level: 0,
      condition: "polygon('" + pointsString + "')",
      conjunction: "And",
      field: 'Location',
      function_name: 'polygon',
      function_parameters: [ pointsString ],
      operator: 'Eq',
      type: 'shape',
      unary_operator: null,
      value: [{
       type: 'LineString',
       coordinates: []
      }]
    }

    let annotation = {
      Type: 'Shape',
      Match: {
        Substring: expression.condition
      },
      Attributes: {
        Label: polygonName,
        Color: polygonColor.replace('#','')
      }
    }

    pointsArray.forEach((latLng) => {
      expression.value[0].coordinates.push([latLng.lat(), latLng.lng()]);
    });

    this.addShapeToFilter(expression);

    this.modified = true;
    return { expression: expression, annotation: annotation};
  },

  addRectangle: function(bounds, rectangleName, rectangleColor) {

    const pointsString = mapConverters.getStringFromPoints([bounds.getNorthEast(), bounds.getSouthWest()]);

    let expression = {
      block_group: 0,
      level: 0,
      condition: "rectangle('" + parseFloat(bounds.getNorthEast().lat().toFixed(6)) + " " + parseFloat(bounds.getNorthEast().lng().toFixed(6)) + "," + parseFloat(bounds.getSouthWest().lat().toFixed(6)) + " " + parseFloat(bounds.getSouthWest().lng().toFixed(6)) +  "')",
      conjunction: "And",
      field: 'Location',
      function_name: 'rectangle',
      function_parameters: [ pointsString ],
      operator: 'Eq',
      type: 'shape',
      unary_operator: null
    }
    
    this.addShapeToFilter(expression);

    this.modified = true;

    let annotation = {
      Type: 'Shape',
      Match: {
        Substring: expression.condition
      },
      Attributes: {
        Label: rectangleName,
        Color: rectangleColor.replace('#','')
      }
    }
    return {expression: expression, annotation: annotation};
  },

  addRadius: function(lat, lon, radius, radiusName, radiusColor) {

    let expression = {
      block_group: 0,
      level: 0,
      condition: "radius('" + parseFloat(lat.toFixed(6)) + " " + parseFloat(lon.toFixed(6)) + "'," + parseFloat(radius.toFixed(2)) + ")",
      function_parameters: [ parseFloat(lat.toFixed(6)) + " " + parseFloat(lon.toFixed(6)), parseFloat(radius.toFixed(2)) ],
      conjunction: "And",
      field: 'Location',
      function_name: 'radius',
      operator: 'Eq',
      type: 'shape',
      unary_operator: null
    }
    this.addShapeToFilter(expression);

    this.modified = true;

    let annotation = {
      Type: 'Shape',
      Match: {
        Substring: expression.condition
      },
      Attributes: {
        Label: radiusName,
        Color: radiusColor.replace('#','')
      }
    }
    return {expression: expression, annotation: annotation};
  },


  toString: function(){
    return filterAssembler(this._data);
  },

  removeShapeByCondition: function(condition) {
    for (var x = 0; x < this._data.length; x++) {
      if (this._data[x].condition == condition) {
        this.removeShape(this._data[x]);
        return;
      }
    }
  },

  removeShape: function(element) {
    var index = this._data.indexOf(element);
    if (index >= 0) {
      this._data.splice(index, 1);
      this.modified = true;
    }

    if (this.modified) {
      for (var x = 0; x < this._data.length; x++) {
        if (this._data[x].type == 'shape') {
          // The first shape should have a conjunction of And.
          // If the first shape was removed, we need to make sure
          // that the new first shape has the right conjunction.
          this._data[x].conjunction = 'And';
          break;
        }
      }
    }
  },

  removeShapes: function() {
    var self = this;

    var shapeRemoved = false;

    this._data.forEach(function(element, index, dataArray){
      if(element.type === 'shape'){
        dataArray.splice(index, 1);
        self.modified = true;
        shapeRemoved = true;
      }
    });

  },

  propertyType: function() {
    var propertyTypes = [];

    this._data.forEach(function(element, index, dataArray){
      if(element.field === 'PropertyType'){
        element.value.forEach((value) => {
          propertyTypes.push(value.replace(/'/g, ''));
        })
      }
    });

    return propertyTypes;
  },

  propertyClass: function() {
    var propertyClasses = [];

    this._data.forEach(function(element, index, dataArray){
      if(element.field === 'PropertyClass'){
        element.value.forEach((value) => {
          propertyClasses.push(value.replace(/'/g, ''));
        })
      }
    });

    return propertyClasses;
  },


  equalToOriginal: function(ignoreRemovedDataSource = false) {
    let removedExpressions = Filter.expressionsRemoved(mapSupport.parsedFilter, this._data)
    let addedExpressions = Filter.expressionsAdded(mapSupport.parsedFilter, this._data)
    let equal = true
    if (removedExpressions.length > 0) {
      if (removedExpressions.length > 1 || !ignoreRemovedDataSource || removedExpressions[0].field !== 'MlsId') {
        console.log('Removed expressions: ', removedExpressions)
        equal = false;
      }
    }
    if (addedExpressions.length > 0) {
      console.log('Added expressions: ', addedExpressions)
      equal = false;
    }
    return equal;
  }
};

export default Filter;
