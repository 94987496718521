import { Controller } from "stimulus";
import {ContactsDropdown} from '../contacts_dropdown';

export default class extends Controller {

  connect() {
    if (this.contactDropdown == null) {
        this.contactDropdown = new ContactsDropdown('#contactForCartAction');

    $('#contactForCartAction').off('select2:select');
    $('#contactForCartAction').on('select2:select', (event) => {
        if (event.params) {
          console.log('contact id: ', event.params.data.id)
          this.contactSelected(event.params.data)
        }
      })
    }
  }

  contactSelected(data) {
    $('#btnConfirmCartAction').attr('data-contact-id', data.id);
    $("#btnConfirmCartAction").prop("disabled", false)
  }

  confirm(event) {
    let contactId = event.target.dataset.contactId
    let payload = {
        contactId: contactId,
        listingId: event.target.dataset.listingId.split(","),
        carts: [ {name: event.target.dataset.cartId, id: event.target.dataset.cartId}]
    }

    $.ajax({
        xhrFields: this.xhrFields(window.listingActionSettings),
        data: JSON.stringify(payload),
        contentType: 'application/json',
        type: 'PUT',
        url: this.url(listingActionSettings.addSelectedToCarts, listingActionSettings.mobileUrl)
    }).done(function() {

      if (event.target.dataset.cartId == 'Recommended') {
        toast.success('Listing recommended successfully.');
      } else if (event.target.dataset.cartId == 'Favorites') {
        toast.success('Listing saved successfully.');
      } else if (event.target.dataset.cartId == 'Removed') {
        toast.success('Listing removed successfully.');
      } else {
        toast.success('Listing added to cart successfully.');
      }

    }).fail(function() {
      toast.error('An unknown problem occurred.', false);
    });
  }

  // Copy of listing-actions.js code.  Cannot see it here
  xhrFields(settings) {
    var ret = {};
    if (settings.crossOrigin) {
      ret.withCredentials = true;
    }
    return ret;
  }

  // Copy of listing-actions.js code.  Cannot see it here
  url(url, domain) {
    var domain = domain || '';
      if (url.startsWith('/')) {
        // chop the trailing slash off the domain, if it exists
        domain = domain.replace(/\/$/, '');
      } else if (!domain.endsWith('/')) {
        // Someone has to have the slash
        domain += '/'
      }
    return domain + url;
  }

}