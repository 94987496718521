import { Controller } from "stimulus"

export default class extends Controller {

  modalSetup(attributes) {
    this.createModal()

    if (attributes.modalHeader) {
      this.setHeaderContent(attributes.modalHeader)
    }

    if (attributes.modalPrimary) {
      this.setPrimaryText(attributes.modalPrimary)
    }

    if (attributes.modalSecondary) {
      this.setSecondaryText(attributes.modalSecondary)
    }

    if(attributes.modalCssClasses){
      this.setModalCssClasses(attributes.modalCssClasses)
    }

    if(attributes.modalContentCssClasses){
      this.setModalContentCssClasses(attributes.modalContentCssClasses)
    }

    if(attributes.modalContent){
      this.setModalContent(attributes.modalContent)
    }

    if(attributes.modalFooterContent){
      this.setModalFooterContent(attributes.modalFooterContent)
    }

    if (attributes.modalDataController) {
      this.setModalDataController(attributes.modalDataController)
    }
  }

  getAttributesFromElement(){
    return {
      modalHeader: $(this.element).data('modal-header'),
      modalPrimary: $(this.element).data('modal-primary'),
      modalSecondary: $(this.element).data('modal-secondary'),
      modalCssClasses: $(this.element).data('modal-css-classes'),
      modalContentCssClasses: $(this.element).data('modal-content-css-classes'),
      modalContent: $(this.element).data('modal-content'),
      modalRemote: $(this.element).data('modal-remote'),
      modalRemoteIncludeCredentials: $(this.element).data('modal-remote-include-credentials'),
      modalFooterContent: $(this.element).data('modal-footer-content'),
      modalDataController: $(this.element).data('modal-data-controller'),
    }
  }

  getAttributesFromEvent(event){
    return {
      modalHeader: event.detail.modalHeader,
      modalPrimary: event.detail.modalPrimary,
      modalSecondary: event.detail.modalSecondary,
      modalCssClasses: event.detail.modalCssClasses,
      modalContentCssClasses: event.detail.modalContentCssClasses,
      modalBackdropCssClasses: event.detail.modalBackdropCssClasses,
      modalContent: event.detail.modalContent,
      modalRemote: event.detail.modalRemote,
      modalRemoteIncludeCredentials: event.detail.modalRemoteIncludeCredentials,
      modalFooterContent: event.detail.modalFooterContent,
      modalDataController: event.detail.modalDataController
    }
  }

  // Stimulus Actions

  loadLocal(event) {
    event.preventDefault()
    var attributes = typeof(event.detail) === 'object' ? this.getAttributesFromEvent(event) : this.getAttributesFromElement()
    this.modalSetup(attributes)
    $('#fastModal').modal()
    if(attributes.modalBackdropCssClasses){
      this.setModalBackdropCssClasses(attributes.modalBackdropCssClasses)
    }
  }

  dismissLocal() {
    $("#fastModal").remove();
    $(".modal-backdrop").remove();
  }

  loadRemote(event) {
    event.preventDefault()
    var attributes = typeof(event.detail) === 'object' ? this.getAttributesFromEvent(event) : this.getAttributesFromElement()
    this.modalSetup(attributes)
    $('#fastModal .c-modal__content').html('<div class="loading-spinner" style="height: 50px; width: 50px;"></div>')
    $('#fastModal').modal()
    if(attributes.modalBackdropCssClasses){
      this.setModalBackdropCssClasses(attributes.modalBackdropCssClasses)
    }

    const request = new Request(attributes.modalRemote, {
      method: 'GET',
      credentials: attributes.modalRemoteIncludeCredentials ? 'include' : 'same-origin'
    })

    fetch(request).then(response => {
      return response.text()
    }).then(html => {
      $('#fastModal .c-modal__content').html(html)
    }).catch(err => {
      console.warn("Error loading modal", err)
    })
  }

  setHeaderContent(headerContent){
    $('#fastModal .c-modal__header').show()
    $('#fastModal .c-modal__header').html(headerContent)
  }

  setPrimaryText(primaryText){
    $('#fastModal .c-modal__footer').show()
    var primary = document.createElement('button')
    primary.innerHTML = primaryText
    primary.className = "btn btn-primary"
    primary.setAttribute('data-dismiss', 'modal')
    $('#fastModal .c-modal__footer').append(primary)
  }

  setSecondaryText(secondaryText){
    $('#fastModal .c-modal__footer').show()
    var secondary = document.createElement('button')
    secondary.innerHTML = secondaryText
    secondary.className = "btn btn-secondary"
    secondary.setAttribute('data-dismiss', 'modal')
    $('#fastModal .c-modal__footer').append(secondary)
  }

  setModalContent(modalConent){
    $('#fastModal .c-modal__content').html(modalConent)
  }

  setModalFooterContent(modalFooterContent)
  {
    $('#fastModal .c-modal__footer').show()
    $('#fastModal .c-modal__footer').html(modalFooterContent)
  }

  setModalDataController(modalDataController) {
    $('#fastModal').attr('data-controller', modalDataController)
  }

  setCssClassesBySelector(selector, cssClasses){
      $(selector).removeClass()
      if (!cssClasses){
          return
      }
      $(selector).addClass(cssClasses)
  }

  addCssClassesBySelector(selector, cssClasses) {
    $(selector).addClass(cssClasses)
  }

  getModalHtml(){
      var html = `
          <div class="modal" id="fastModal" role="dialog" aria-labelledby="modal-title">
              <div class="modal-dialog c-modal">
                  <div class="c-modal__header">
                      <div class="t-title--largest" id="modal-title"></div>
                  </div>
                  <div class="c-modal__content" role="document"></div>
                  <div class="c-modal__footer"></div>
              </div>
          </div>
      `
      return(html)
  }

  createModal(){
      $('body').append($(this.getModalHtml())[0]) 
      $('#fastModal .c-modal__header').hide()
      $('#fastModal .c-modal__footer').hide()
      $('#fastModal').on('hide.bs.modal', () => {
          $('#fastModal').remove()
      })
  }

  setModalCssClasses(modalCssClasses){
      var selector = $('#fastModal').children().first()
      this.setCssClassesBySelector(selector, modalCssClasses)
  }

  setModalContentCssClasses(modalContentCssClasses) {
    var selector =$('#fastModal').find('.c-modal__content').first()
    this.addCssClassesBySelector(selector, modalContentCssClasses)
  }

  setModalBackdropCssClasses(modalBackdropCssClasses) {
    var selector =$('.modal-backdrop.in').first()
    this.addCssClassesBySelector(selector, modalBackdropCssClasses)
  }
}