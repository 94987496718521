export function loadLocalLogicContent(opts) {
  if (opts.api_key) {
    const ll = LLSDKsJS(opts.api_key, {
      locale: "en", // Change to either english or french
      appearance: {
        theme: "day",
        // Add any other appearance changes here
        variables: {
          "--ll-color-primary": "#fd3958",
          "--ll-color-primary-variant1": "#d5405b",
          "--ll-border-radius-small": "8px",
          "--ll-border-radius-medium": "16px",
          "--ll-font-family": "Avenir, sans-serif"
        }
      }
    });
    const container = document.createElement("div");
    container.setAttribute("id", "localContentWidget");
    // Set the styles of the container
    // With these styles the container will fill the height and width of the #widgetMap
    container.style.cssText = `
      height: 100%;
      width: 100%;
      display: flex;
    `;
    // This is the div that will contain the widget
    document.querySelector("#localLogicContentContainer").appendChild(container);
    // Set the lat and lng of the location
    const lc = ll.create("local-content", container, {
      lat: opts.lat,
      lng: opts.lon,
      cooperativeGestures: false,
      marker: {
        lat: opts.lat,
        lng: opts.lon
      }
    });
  }
}

export function loadLocalLogicDemographics(opts) {
  if (opts.api_key) {
    // Your API key or token
    const ll = LLSDKsJS(opts.api_key, {
      locale: "en", // Change to either english "en" or french "fr"
    });
    const container = document.createElement("div");
    container.setAttribute("id", "localDemographicsWidget");
    // Set the styles of the container
    // With these styles the container will fill the height and width of the #widgetMap
    container.style.cssText = `
      height: 100%;
      width: 100%;
      display: flex;
    `;
    // This is the div that will contain the widget
    document.querySelector("#localLogicDemographicsContainer").appendChild(container);
    // Set the lat and lng of the location
    const ld = ll.create("demographics", container, {
      lat: opts.lat,
      lng: opts.lon
    });
  }
}
