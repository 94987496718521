import Overlay from './overlay';
import OverlayShape from 'src/map/overlay_shape';
import mapConverters from './map_converters';

class OverlayManager {

  constructor(googleMap) {
    this.googleMap = googleMap;

    // the keys are overlay ids
    // the values are arrays of google shapes that have been added to the map
    this._overlays = {};
  }

  // overlay argument should be an instance of Overlay
  addOverlay(overlay, opts) {
    let self = this;
    
    if(typeof opts === 'undefined'){
      opts = {};
    }

    overlay.Shapes.forEach(function(overlayShape){
      self.addShape(overlayShape, opts.color);
    });
  }

  // Arguments
  //  overlayShape: (required) an instance of OverlayShape
  //  color: (optional, default: true) If true, the shape's saved color will
  //    be used. If false, it will be shown in black and white.
  addShape(overlayShape, color) {
    let options = {};

    if(typeof color !== 'boolean'){
      color = true;
    }

    if(!color){
      options = {
        strokeColor: '#FFFFFF',
        fillColor: '#000000',
        fillOpacity: 0.15
      };
    }

    let googleShape = this._buildGoogleShape(overlayShape, options);
    googleShape.setMap(this.googleMap);

    let key = this._storageKey(overlayShape.OverlayId, color);

    if(typeof this._overlays[key] === 'undefined' ){
      this._overlays[key] = [];
    }

    this._overlays[key].push(googleShape);
  }

  findAndAddShapes(ids) {
    if(ids.length > 0){
      let self = this;

      OverlayShape.find(ids).done(function(overlayShapes) {
        overlayShapes.forEach(function(overlayShape) {
          self.addShape(overlayShape);
        });
      });
    }
  }


  removeOverlay(overlayId, color) {
    let key = this._storageKey(overlayId, color);

    this._overlays[key].forEach(function(googleShape) {
      googleShape.setMap(null)
    });
    delete this._overlays[key];
  }

  _buildGoogleShape(overlayShape, options) {
    if(typeof options === 'undefined') {
      options = {};
    }
    var points = mapConverters.sparkBoundaryToPoints(overlayShape.Geometry);
    var color = '#' + overlayShape.Color;
    var defaults = {
      path: points,
      strokeColor: color,
      strokeWeight: 1,
      fillColor: color,
      fillOpacity: 0.1,
      clickable: false
    };
    var args = $.extend({}, defaults, options);

    return new google.maps.Polygon(args);
  }

  _storageKey(overlayId, color) {
    return (color) ? overlayId : overlayId + "Grayscale";
  }

}

export default OverlayManager;
