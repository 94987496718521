import {contactSelector} from './contact_selector'
import {translations} from './translations.js.erb'

// 
// These functions will set up the click handler for the portal collection links.
// The listingId argument is optional, but if it's not provided, then the link should
// have a data-listing-id attribute.
// 
export var actions = {
  
  recommendLink: function(linkSelector, listingId) {
    this._initLink(linkSelector, listingId, 'Recommended', translations.listings.actions.recommended_successfully);
  },

  saveLink: function(linkSelector, listingId) {
    this._initLink(linkSelector, listingId, 'Favorites', translations.listings.actions.saved_successfully);
  },

  hideLink: function(linkSelector, listingId) {
    this._initLink(linkSelector, listingId, 'Removed', translations.listings.actions.removed_successfully);
  },

  _initLink: function(linkSelector, listingId, collectionName, successMessage) {
    var self = this;

    $(document).on('click', linkSelector, function(e){
      e.preventDefault();

      if(typeof listingId === 'undefined') {
        listingId = $(this).attr('data-listing-id');
      }

      self._getContact().done(function(contactId) {
        self._addToCollection(collectionName, listingId, {contactId: contactId}).done(function() {
          toast.success(successMessage);
        }).fail(function() {
          toast.error(translations.g.unknown_problem, false);
        });
      });
    });
  },

  _getContact: function() {
    var deferred = $.Deferred();
    var contactsUrl = routes.contacts_select_path();

    contactSelector.init({
      iframeUrl: contactsUrl,
      beforeShow: showPageLoadingMsg,
      beforeHide: function(){
        hidePageLoadingMsg(0);
      },
      done: function(contactId) {
        deferred.resolve(contactId);
      }
    });

    contactSelector.show();

    return deferred;
  },

  _addToCollection: function(collectionId, listingId, options) {
    if(typeof options === 'undefined') {
      options = {};
    }

    var contactId = options.contactId;

    var successCallback = options.success || function() {};

    var payload = {
      contactId: contactId,
      listingId: listingId
    };

    payload.carts = [ { name: collectionId, id: collectionId } ];

    return $.ajax({
      xhrFields: ListingAction.xhrFields(window.listingActionSettings),
      data: JSON.stringify(payload),
      contentType: 'application/json',
      type: 'PUT',
      url: ListingAction.url(listingActionSettings.addSelectedToCarts, listingActionSettings.mobileUrl)
    });

  },

}
