var mapModals = {
  confirmDeleteDrawing: function(translations) {
    var modal;

    modal =   '<div class="modal" id="confirmDeleteDrawingModal" tabindex="-1">';
    modal +=    '<div class="modal-dialog modal-sm c-modal">';
    modal +=      '<div class="c-modal__header">';
    modal +=        '<div class="t-title--large">';
    modal +=          translations.delete_shape;
    modal +=        '</div>';
    modal +=      '</div>'
    modal +=      '<div class="c-modal__content">';
    modal +=          translations.discard_warning_message;
    modal +=      '</div>';
    modal +=      '<div class="c-modal__footer">';
    modal +=        '<button type="button" id="cancelDeleteDrawingBtn" class="btn btn-tertiary" data-dismiss="modal">';
    modal +=          translations.cancel;
    modal +=        '</button>';
    modal +=        '<button type="button" id="deleteDrawingBtn" class="btn btn-primary" data-dismiss="modal">';
    modal +=          translations.yes_im_sure;
    modal +=        '</button>';
    modal +=      '</div>';
    modal +=    '</div>';
    modal +=  '</div>';

    return modal;
  },

  shapeContextMenu: function(translations) {
    var modal;
    modal =   '<div id="shapeContextMenu" class="shape-context-menu context-menu">';
    modal +=    '<ul style="list-style-type: none; padding: 0; margin: 0;">';
    modal +=      '<li class="shape-context-menu-list-item">';
    modal +=        '<a href="#" id="shapeMenuDeleteShape" class="shape-context-menu-item" style="color: #333">';
    modal +=            translations.delete_shape;
    modal +=        '</a>';
    modal +=      '</li>';
    modal +=  '</div>';
    return modal;
  }

}


export default mapModals;
