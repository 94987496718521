import { Controller } from "stimulus";
import {Moarify} from '@programming/moarify'

export default class extends Controller {

  historyDetailModalContent = 
  `<div class="modal-body change-details-modal-body">
    <div>
      Changed by: 
      <span class="change-details-modal-value">CHANGER_GOES_HERE</span>
    </div>
    <div>
      Date: 
      <span class="change-details-modal-value changeDate" data-time="DATETIMEVALUE_GOES_HERE">FORMATTED_DATE_GOES_HERE</span>
      at 
      <span class="change-details-modal-value changeTime" data-time="DATETIMEVALUE_GOES_HERE">FORMATTED_TIME_GOES_HERE</span>
    </div>
    <div class="change-details-modal-description">
      DESCRIPTION_GOES_HERE
    </div> 
  </div>`

  connect() {
    let moarify = new Moarify({
      perPage: 10,
      totalItems: this.data.get('total-items'),
      infiniteScroll: true,
      appendTo: $('#' + this.data.get('append-to')),
      scrollElement: $('#' + this.data.get('scroll-element')),
      ajaxUrl: $(this.element).closest('turbo-frame').attr('src') + '?newRoute=true',
      moarBtn: "#reportDetailsMoarBtn"
    })
    moarify.run()

    // This should probably be in the individual report controller?
    this.localizeDates();
  }

  localizeDates() {
    $('.changeDate').localTime('M/D/YY');
    $('.changeTime').localTime('time');
  }

  showHistoryChangeDetails(evt) {
    evt.preventDefault()
    let $myLinkElement = $(evt.currentTarget);
    window.dispatchEvent(new CustomEvent("display-local-modal", {
      detail: { 
        modalCssClasses: "modal-dialog modal-sm c-modal",
        modalHeader: `<div class="t-title--large">${$myLinkElement.attr('data-display-header')}</div>`,
        modalContent: this.getModalBodyContent($myLinkElement),
        modalFooterContent: `<button class="btn btn-primary" data-dismiss="modal" type="button">OK</button>`
      }
    }));
  }

  getModalBodyContent($myLinkElement) {
    let result = this.historyDetailModalContent
    result = result.replace('CHANGER_GOES_HERE',$myLinkElement.attr('data-display-changer'))
    result = result.replace('FORMATTED_DATE_GOES_HERE',$myLinkElement.attr('data-display-change-date'))
    result = result.replace('FORMATTED_TIME_GOES_HERE',$myLinkElement.attr('data-display-change-time'))
    result = result.replace('DESCRIPTION_GOES_HERE',$myLinkElement.attr('data-display-change-description'))
    return result;
  }

  closeReportDetails(evt) {
    history.go(-1)
  }
}
