import { Controller } from "stimulus";
import { showLDPFrame, getListItem, recordHistoryInSession, stepBack } from "../helpers/ldp_navigation_helpers"
import { hideAllSubmenus, showSubMenu, showLastViewSubMenu } from '../helpers/sub_nav_helpers.js'

export default class extends Controller {

  hideLdp(evt) {
    window.parent.postMessage({command:'ldpFrame:Hide'},'*');
  }

  // TODO: REMOVE WHEN OLD ROUTE IS NO LONGER NEEDED
  showLdpFromTableList(evt) {
    if (evt.target.classList.contains('flexicon-ellipsis') || evt.target.classList.contains('select-listing-control')) return
    if ($(evt.target).closest('.lc-button--container').length >= 1) return

    if(evt.target.type=='checkbox') return

    // if a link within the row was clicked, don't do anything
    if(evt.target.nodeName === 'A') return

    evt.preventDefault()
    sessionStorage.removeItem('mapClickListing');
    let target = $(evt.target)
    if (!target.hasClass('listingsTableRow')) {
      if (target.closest('.listingsTableRow').length == 1) {
        target = target.closest('.listingsTableRow')
      } else {
        console.log("Unknown ldp navigation element.")
        return
      }
    }

    showLDPFrame(target)
  }

  // TODO: REMOVE WHEN OLD ROUTE IS NO LONGER NEEDED
  showLdpFromPhotoList(evt) {
    if (evt.target.classList.contains('flexicon-ellipsis') || evt.target.classList.contains('select-listing-control')) return
    if ($(evt.target).closest('.lc-button--container').length >= 1) return

    sessionStorage.removeItem('mapClickListing')
    let target = $(evt.target)
    if (!target.hasClass('lc-thumbnail--container')) {
      if (target.closest('.lc-thumbnail--container').length === 1) {
        target = target.closest('.lc-thumbnail--container')
      } else {
        console.log("Unknown ldp navigation element.")
      }
    }
    showLDPFrame(target)
  }

  // TODO: REMOVE WHEN OLD ROUTE IS NO LONGER NEEDED
  showLdpFromMap(evt) {
    var target = $(evt.target)

    if (
        (target.hasClass('marker-preview-wrapper-above') || target.hasClass('marker-preview-wrapper-below') ) ||
        (target.closest('.rsArrowLeft').length) ||
        (target.closest('.rsArrowRight').length)||
        (target.closest('.saveListingLink').length) ) {
      // let addToCollection deal with it
    } else {
      evt.preventDefault()
      // The url for the listing detail page is stored in a data attribute on
      // the preview. If the preview is clicked, we need to look up the dom to
      // find it, but if the outermost wrapper was clicked, we need to look
      // inside the clicked element.
      var urlHolder = $(evt.target).closest('[data-listing-show-url]')

      if(urlHolder.length < 1) {
        urlHolder = $(evt.target).find('[data-listing-show-url]')
      }

      var requestUrl = urlHolder.attr('data-listing-show-url') + "&navigation=yes"

      var listingId = requestUrl.substr(requestUrl.indexOf('listing_id=')+11,26)
      var listItem = getListItem(listingId)
      if (listItem !== null && listItem.length > 0) {
        // This item is in the list.  Lets use the list to navigate.
        sessionStorage.removeItem("mapClickListing")
        showLDPFrame(listItem)
        listItem[0].scrollIntoView({behavior: 'auto', block: 'center', inline: 'center'})
      } else {
        requestUrl += "noprev"
        // This item is not in the list.  Going to use it as the first item for navigation.
        // The next item will be the first one in the page's list.
        sessionStorage.setItem("mapClickListing",requestUrl)
        window.postMessage({command:'ldpFrame:Show', url: requestUrl}, '*')
      }
    }
  }

  // TODO: REMOVE WHEN OLD ROUTE IS NO LONGER NEEDED
  displayNextLdp(evt) {
    window.parent.postMessage({command: 'ldpFrame:Next'},'*')
  }

  // TODO: REMOVE WHEN OLD ROUTE IS NO LONGER NEEDED
  displayPreviousLdp(evt) {
    window.parent.postMessage({command: 'ldpFrame:Previous'},'*')
  }

  // Adding 'redundant' methods for now.  Do not want to mess with controller actions
  // currently in use in the live pages.

  showNextLdp(evt) {
    // Request the next LDP from the view that opened the LDP
    if ($(evt.target).hasClass('nomore')) return;
    dispatchEvent(new CustomEvent( $(evt.target).attr('navsource') + ':requestNext', {detail: $(evt.target).parent().find('[data-listing-key]').attr('data-listing-key')}))
  }
  
  showPreviousLdp(evt) {
    // Request the previous LDP from the view that opened the LDP
    if ($(evt.target).hasClass('nomore')) return;
    dispatchEvent(new CustomEvent( $(evt.target).attr('navsource') + ':requestPrevious', {detail: $(evt.target).parent().find('[data-listing-key]').attr('data-listing-key')}))
  }

  hideFlexPanelOverlay(evt) {
    // If we are not clicking on the close button or the fake-modal backdrop, don't hide the overlay.
    if (evt.target !== evt.currentTarget) return
    evt.preventDefault();

    // If the LDP is loaded directly, we want the user to get back to the results when they close the modal.
    let directLoadLdp = $('[data-direct-load-ldp]').first().attr('data-direct-load-ldp');
    if (directLoadLdp === 'none' || stepBack(window.location.pathname, 'listings') != null) {
      // There is some history here.  Use that.
      history.go(-1)
    } else {
      // No history.  Go back to the results page manually.
      $('#priorityOverlay')[0].src = "";
      $('#priorityOverlay').html('');
      $('c-body').removeClass('with-ldp')

      sessionStorage.removeItem('listingsSpaHistory')
      // Now that we have navigated away from the directly loaded page we can handle this page through history.
      $('[data-direct-load-ldp]').attr('data-direct-load-ldp','none')
      // If the listing ID is in the URL, remove it (and anything after it) before we fill in the list view
      let pattern = /\/listings\/\d{26}.*/
      let url = new URL(window.location.href)
      url.pathname = url.pathname.replace(pattern, '/listings')
      history.replaceState({action: 'none'}, '', url.href)
    }

  }

  openLdpReport(evt) {
    evt.preventDefault();

    let turboUrl = evt.currentTarget.href
    let windowUrl = turboUrl.replace('listing_detail','listings')

    // Are either at the LDP level (going to a report) or the report level (going to detail)
    // Copy relevant data from the current state and push the new state
    let currentState = history.state
    let state = {action: 'ldpReport', reportPageUrl: evt.currentTarget.href, itemUrl: currentState.itemUrl, itemId: currentState.itemId, listSrc: currentState.listSrc, priorListings: currentState.priorListings, moreListings: currentState.moreListings};
    window.history.pushState(state, '', windowUrl)
    recordHistoryInSession(state, window.Url)

    $('#priorityOverlay')[0].src = evt.currentTarget.href;
  }

  openRemoteModal(evt) {
    evt.preventDefault();
    let url = new URL(evt.currentTarget.href)
    url.searchParams.set('modal','true');

    window.dispatchEvent(new CustomEvent("display-remote-modal", {
      detail: {
        modalHeader: `<div class="t-title--large">Member Information</div>`,
        modalCssClasses: "modal-dialog c-modal c-modal-gray",
        modalRemote: url.href,
        modalRemoteIncludeCredentials: true,
        modalFooterContent: `<button class="btn btn-primary" data-dismiss="modal" >Ok</button>`
      }
    }));
  }

  closeReport(evt) {
    history.go(-1)
  }

}
