import { Controller } from "stimulus";
import {StarRating} from '../search_results/star_rating'


export default class extends Controller {
  static values = {
    rating: String,
    url: String
  }

  static targets = ['rating']

  setRating(evt) {
    let rating = evt.params.rating;
    if (rating != this.ratingValue) {
      let xhr = {}
      if (window.listingActionSettings.crossOrigin) {
        xhr.withCredentials = true;
      }
      let _this = this;
      $.ajax({
        method: 'PUT',
        xhrFields: xhr,
        headers: {'X-Requested-With': 'XMLHttpRequest'}, /* cross-domain requests don't add this automatically */
        url: _this.urlValue,
        data: { star_rating: rating },
        success: function() {
          _this.ratingValue = rating;
          _this.saveSuccess()
        },
        error: function() { alert("Error setting rating"); }
      });
    }
    evt.stopPropagation();
  }

  saveSuccess() {
    let rating = this.ratingValue;
    let url = this.urlValue;
    // For each element on the page that has the same url value
    // (For when update the star rating in the LDP and want it to be applied to the
    // proper list item's 3-dot menu)
    $('[data-star-rating-url-value="' + url + '"]').each(function () {
      $(this).find('span').each(function (index, span) {
        $(span).toggleClass('active', rating >= index + 1);
        if (rating >= index + 1) {
          $(span).removeClass('flexicon-star--outline').addClass('flexicon-star')
        } else {
          $(span).removeClass('flexicon-star').addClass('flexicon-star--outline')
        }
      });
    });
  }
}