// How to Manage State with Objects
// ================================
// 
// Option 1: Create a class, and save the instance using the state module, either 
//  when the state module is initialized, or using setState. This is how the 
//  Filter is handled.
// 
// Option 2: Create a singleton object that manages it's own state internally.
//  This should only be used when there will only ever be one copy of the object.
//  It doesn't work if you need two copies of the object with different state.
//  the state and markerManager modules are example of this.
// 
// Option 3: Create a class and save an instance as a property on some other 
//  object or as local variable in a module. This should usually only be used 
//  when the instance doesn't need to be shared with other modules. 
//  
//  If other modules need to access the instance, then the module that contains 
//  the instance could export a function for accessing it, or it could pass the
//  instance as an argument, or Option 1 could be used. 
//  
//  Use Option 1 when the object is independent, and accessed in multiple places,
//  like Filter.  Use Option 3 when the object _belongs_ to another object, and 
//  might not make sense as an independent object, like the DrawingManager.



import Url from 'domurl'
import debounce from 'lodash.debounce';
import Cookies from 'js-cookie';
import axios from 'axios';

import {urlUpdater} from './url_updater'
import {saveAndShareModal} from './save_and_share_modal'
import {mapListConnection} from './map_list_connection'
import {ListingsList} from './listings_list'
import Filter from './../filter'
import mapConverters from '../map/map_converters'
import {FlexmlsMap} from '../map/flexmls_map'
import {getState, setState} from './state'
import {SortControl} from './sort_control'
import {SearchTemplateViewControl} from './search_template_view_control'
import {ListingViolationReport} from '../listing_violation_report';
import {actions} from '../actions';
import {shouldUseDesktopView, shouldShowMap} from './helpers';
import {setupSubscriptionToggleLinks} from './subscription_toggle';
import {translations} from '../translations.js.erb'
import { searchForm } from '../listings/search_form';
import {searchFormHelpers} from '../search/search_form_helpers';
import { nativeAppInterface } from '../native_app_interface';

var _map;
var originalFilter; // the filter string from the model, without params
var filterBtn;
var totalCount; // the total number of listings, unrestricted by the map
var mapEnabled;
var _list; // ListingsList instance
let filterWhenFilterModalIsOpened;

// Data moved from the modal (for use on the panel)
var filter;
var count;
var expressions;
var filterUrl;
var filterContainer;
var filterContent;
var viewListingsButton;
var viewResultsLink;
var resetFiltersButton;
var addFilterDiv;

function init() {

  filterBtn   = $('.filterModalLink');
  mapEnabled = (typeof mapSupport.mapEnabled === 'boolean') ? mapSupport.mapEnabled : true;

  originalFilter = mapSupport.originalFilter;

  urlUpdater.init();

  _list = new ListingsList({
    propertyTypeMeta: mapSupport.propertyTypeMeta
  })

  if (mapSupport.isListDriven) {
    initListDrivenView();
  } else if (mapEnabled && shouldUseDesktopView()) {
    initSplitView();
  } else {
    initSingleView();
  }

  // Make sure the annotations are recorded in the save/save as dialog.
  $('.dynamicAnnotationsField').val(JSON.stringify(getState('annotations')));


  // used in the nav when viewing the selected collection
  actions.hideLink('.hideAllLink', window.selectedCollectionListingIds);
  actions.recommendLink('.recommendAllLink', window.selectedCollectionListingIds);

  let sortControl = new SortControl('#listingsSort');
  sortControl.loadCustomSorts();


  let searchTemplateViewControl = new SearchTemplateViewControl('#userViews');
  var urlParams = new URLSearchParams(window.location.search);
  if(urlParams.has('view_id')){
    $("#userViews").find('[data-view-id=' + urlParams.get('view_id') + ']').addClass('selected-view')
    searchTemplateViewControl._tableHeaders();
  }

  searchForm.setFilterChangedCallback(filterChangedCallback);

  filterContainer = $('#filterPanel');
  filterContent = filterContainer.find('#filterPanelContent');
  addFilterDiv = $('#addFilterDiv');
  viewListingsButton = filterContainer.find('#viewListingsButton');
  viewResultsLink = filterContainer.find('#viewResultsLink');
  resetFiltersButton = $('#resetFiltersLink');
  resetFiltersButton.click(function(e) {
    e.preventDefault();
    // Reset the filter to its unmodified state
    if (filterUrl === undefined || filterUrl == null) {
      filterUrl = new Url(filterBtn.attr('data-filter-url'));
    }
    filterUrl.query._filter = window.mapSupport.originalFilter;
    $.ajax({
      url: filterUrl,
      success: function(data) {
        // Completely reload the form from the server
        $('#filterPanelContent').html(data.fields);
        addFilterDiv.html(data.add_field);
        searchForm.initAddFields();
        $('#resetShapesLink:not(.bound)').addClass('bound').on('click', searchForm.handleResetShapesClick);
        filterChangedCallback(data.count, data.expressions);
        // Make sure the form looks like it should.
        searchForm.enlivenateForm();
        $('.c-card-group').flexCardGroup();
        searchForm.bindDateExchange();
        searchForm.bindRelativeTimeAndDirection();

        $('#filterPanel').scrollTop(0);
      },
      error: function (e) {
        alertModal(translations.g.something_went_wrong);
      }
    });
  });
  window.addEventListener('searchForm:fieldsReloaded', function(e) {
    updateData(e.detail);
  })
  window.addEventListener('searchForm:countChanged', function(e) {
    updateData(e.detail);
  })

  filterBtn.click(function(e){
    e.preventDefault();

    hideSearchResults();
    // 'hide' the results column.
    if ($("body").hasClass("map-view")) {
      // If in map view: hide the map and only show the
      // filter panel.
      $("#filterPanel").addClass('return-to-map');
      hideMobileMap();
    } else {
      // If we are in list only mode...
      if ($("#searchResultsListColumn").hasClass("list-only")) {
          // remove that class from the results
          $("#searchResultsListColumn").removeClass("list-only")
          $("#filterPanel").addClass('return-to-list');
          // Show the map...
          showDesktopMap();
          // ...but hide the 'Hide Map' button.
          $(".desktop-hide-map-button").addClass('hidden');
      } else {
        // The map is showing.  But we should hide the 'Hide Map' button.
        $(".desktop-hide-map-button").addClass('hidden');
      }
    }
    $("#filterPanel").removeClass("hidden");
    loadFilterForm($(this).attr('data-filter-url'));
    recordFilterFirstState(true);
  });

  viewListingsButton.click(function(e) {
    e.preventDefault();
    viewListings();
  });
  viewResultsLink.click(function(e) {
    e.preventDefault();
    viewListings();
  });

  saveAndShareModal.init();

  $('.saveSavedSearchLink').click(function(e) {
    e.preventDefault();
    $('#saveSavedSearchForm').submit();    
  });

  bindFilterChanges();

  bindViewChanges();

  bindFilterFirstInitializeEvent();

  $('#searchResultsMapButton').click(function(e) {
    e.preventDefault();
    setState('mobileSearchResultsView', 'map');
  });

  $('#desktopShowMapButton').click(() => setState('desktopSearchResultsView', 'split') );

  if ($(".search-results-body").hasClass('with-main-menu')) {
    $('.c-sticky__header').flexStickyHeader({
      containerSel: '#searchResultsListColumn',
      mainMenuSel: '#mainMenu'
    });
  } else {
    $("#searchResultsListColumn").css('top', '0px');
    $("#filterPanel").css('top', '0px');
    $(".listings-ldp-frame").css('top', '0px');
    $(".listings-ldp-frame").css('min-height', '');
    $(".listings-ldp-frame").css('height', '100%');
  }

  bindTotalCount();
  bindVisibleCount();

  //
  // bind listing card buttons
  // 

  // dropdown menu links
  actions.recommendLink('.recommendLink');
  actions.saveLink('.saveListingLink');
  actions.hideLink('.hideLink');
  ListingViolationReport.init('.reportViolation');
  setupSubscriptionToggleLinks();

  bindWindowResizing();

  $(document).on('click', '.shareLink', (e) => {
    e.preventDefault();
    let url = new Url($(e.target).attr('href'));
    url.query.referrer = window.location.href;
    window.location.href = url;
  })
}

function loadFilterForm(_filterUrl) {
  if (filterUrl == null) {
    filterUrl = new Url(_filterUrl);
  }
  searchForm.filterChangedCallback = filterChangedCallback;
  
  $.ajax({
    url: filterUrl,
    success: function(data) {
      updateData(data);
      searchForm.setFilterChangedCallback(filterChangedCallback);
      $('#resetShapesLink:not(.bound)').addClass('bound').on('click', searchForm.handleResetShapesClick);
    },
    error: function(error) {
      alertModal(translations.g.something_went_wrong);
      console.log(translations.g.something_went_wrong, error);
    }
  });
}

function updateData(data) {
  if(typeof data.filter !== 'undefined') {
    filter = data.filter;
  }
  if(typeof data.message !== 'undefined') {
    viewListingsButton.text(data.message);
    viewResultsLink[0].childNodes[2].textContent = ' ' + data.message + ' ';
  }
  if(typeof data.expressions !== 'undefined') {
    expressions = data.expressions;
  }
  if(typeof data.count !== 'undefined') {
    count = data.count;
  }
}
function bindFilterFirstInitializeEvent() {
  if (searchForm.startWithFilterPanel == null) {
    window.addEventListener('searchForm:filterFirstInitialized', (data) => {
      if (data.detail) {
        initMap();
      }
    });
  } else if (searchForm.startWithFilterPanel) {
    initMap();
  }
}
function bindFilterChanges() {
  window.addEventListener('stateChange:filter', (data) => {

    let newFilterString = data.detail.newValue.toString();
    $('.dynamicFilterField').val(newFilterString);

    tagRecentSearch(newFilterString);

    let oldFilterBtnUrl = filterBtn.attr('data-filter-url');
    filterBtn.attr('data-filter-url', updateFilterInUrl(oldFilterBtnUrl, newFilterString));

    let oldShareHref = $('.shareButton').attr('href');
    $('.shareButton').attr('href', updateFilterInUrl(oldShareHref, newFilterString));

    if ( originalFilter.toLowerCase() === newFilterString.toLowerCase() ) {
      $('#listingsHeader').removeClass('modified-search');
      // remove the filter from the url filter
      urlUpdater.deleteQueryParam('_filter');
      $('.saveSavedSearchLink').addClass('hide');
    } else {
      $('#listingsHeader').addClass('modified-search');
      $('.saveSavedSearchLink').removeClass('hide');
    }

    // Update the shape information on the form here so we dont have to reload the entire form
    var containsShapes = false;
    // Remove whatever shapes are defined in case the new filter has deleted one of the shapes.
    // We will add any that are still defined back to the select here.
    $("#drawingsSelect").find('option').remove().end();
    data.detail.newValue._data.every(function(expression) {
      if (expression.type == 'shape') {
        containsShapes = true;
        $('#drawingsSelect').append($('<option value="' + expression.condition + '" selected="selected">Shape</option>'));
      }
      return true;
    });
    if (containsShapes) {
      $("#drawingsSelectDiv").removeClass('no-shapes');
      $("#drawingsFooter").removeClass('no-shapes');
      $('div.drawn-shapes-prompt').show();
      $('div.drawn-shapes-prompt').prop('disabled',false);
      $('#drawingsSelect').prop('disabled',false);
    } else {
      $("#drawingsSelectDiv").addClass('no-shapes');
      $("#drawingsFooter").addClass('no-shapes');
    }

    filterUrl = new Url(filterBtn.attr('data-filter-url'));
  });
  window.addEventListener('stateChange:annotations', (data) => {
    let newAnnotationsString = JSON.stringify(data.detail.newValue);
    $('.dynamicAnnotationsField').val(newAnnotationsString);
  });
}

function showMobileMap() {

  if( $('.mapOptionsDropdown li a').not('.optionsSelectLink, .hide').length > 0 ) {
    $('.mapOptionsDropdown').show();
  } else {
    $('.mapOptionsDropdown').hide();
  }

  $('body').addClass('map-view');

  if(typeof map() === 'undefined') {
    initMap();
  } else {
    map().unfreeze();
  }
}

function initListDrivenView() {

  $(document).one('listingsList:itemsAdded', function() {
    if(shouldUseDesktopView()){
      setState('mobileSearchResultsView', 'list');
      if (mapEnabled) {
        initMap();
      }
    } else {
      if(shouldShowMap()){
        showMobileMap();
      }
    }

  });

  mapListConnection.connect();

  list().start();
}


function updateFilterInUrl(oldUrl, filter) {
  var url = new Url(oldUrl);
  url.query._filter = filter;
  return url.toString();
}

//function handleFilterModalClose(count, newExpressions) {
function filterChangedCallback(count, newExpressions) {
  setState('listingCountTotal', count);

  let newFilter = new Filter(newExpressions, mapSupport.locationFields);

  let oldExpressions = getState('filter').expressions();

  var added   = new Filter(Filter.expressionsAdded(oldExpressions, newExpressions), mapSupport.locationFields);
  var removed = new Filter(Filter.expressionsRemoved(oldExpressions, newExpressions), mapSupport.locationFields);

  // If there is one shape still here, they are all still here.  Only delete annotations if there are no shapes.
  var deleteAnnotations = true;
  newExpressions.forEach( function(expression) {
    if (expression.type == 'shape') {
      deleteAnnotations = false;
    }
  });
  if (deleteAnnotations) {
    setState('annotations',[]);
  }

  if(getState('mapBounds') === null){

    setState('filter', newFilter);

  } else if(typeof _map === 'undefined'){
    // we have bounds from a previous session, but the map is still not defined
    setState('filter', newFilter);

    // If the locations were changed, clear the bounds that might have been set
    // in a previous session so that if the map is opened, it will be focused
    // on the new locations
    if(added.locations().length > 0 || removed.locations().length > 0) {
      setState('mapBounds', null);
    }

  } else {

    if (added.locations().length === 0 && removed.locations().length === 0) {
      // The locations weren't changed, so we don't need to move the map. Just
      // update the filter, which will cause the map and list to update.
      setState('filter', newFilter);
    } else {
      // The locations were changed. We need to update the state with the new 
      // filter and move the map to the new bounds. Both of those things will 
      // cause the map and list to reload because of the stateChange event
      // listeners. So to prevent extra unnecessary reloads, we can freeze the 
      // map and list, manually make all the necessary changes, and then unfreeze
      // the map and list.
      map().freeze();

      setState('filter', newFilter) 

      if (removed.locations().length > 0 && newFilter.locations() == 0 ) {
        _map.moveMapToMlsDefaultBounds().then((bounds) => {
          setState('mapBounds', bounds);

          map().unfreeze();

          map().updateMap();
        });
      } 
      else {
        // At least one location field was added or removed, and there's still at
        // least one location left. The map should be moved to listing data bounds.

        // updateMap will get the new listings and update the pins, but not move the map
        _map.updateMap({bounds: null}).done(function() {
          // after the pins have been updated, the map should be moved to the new pins
          _map.moveMapToListingDataBoundary().then((bounds) => {
            // Since the map is frozen, the bounds need to be updated in the state 
            // manually.
            setState('mapBounds', bounds);

            map().unfreeze();
          });
        });
      }
    }
    // Shapes are not locations, so we still may need to refresh the shapes even
    // if no locations were changed.
    _map.refreshMapShapes();
  }
}

function initSplitView() {

  setState('mobileSearchResultsView', 'list');

  if(shouldShowMap()) {
    initMap();
    list().start();
  } else {
    // 
    // We are in desktop list only view. The map may have been open at one point
    // in the session, so the results might still needed to filtered by the map
    // bounds. One way this could happen is:
    //  spit view > hide map > listing detail > return to search results
    // In that case, the results still need to be filtered by the map bounds so
    // that they don't change after visiting the listing detail page.
    // 
    initList();
  }
}

function initSingleView(){
  initList();
  if(shouldShowMap()){
    showMobileMap();
  }
}

function initList() {
  list().start();
}

function updateVisibleCount() {
  if(mapSupport.isListDriven) {
    return Promise.resolve();
  }

  var visibleCountParams = { 
    _filter: getState('filter').toString()
  };
  
  return axios.get(mapSupport.listingCountRetrieverUrl, {params: visibleCountParams}).then((response) => {
    setState('listingCountVisible', response.data);
  });  
}

function bindWindowResizing() {
  bindWindowResizeStart();

  window.addEventListener('resize', debounce(function() { 
    if(mapEnabled && shouldUseDesktopView()){
      setState('mobileSearchResultsView', 'list');
      // if the user resized from small to large, we might not have a map yet
      if(typeof _map === 'undefined' && shouldShowMap()){
        initMap();
        mapListConnection.connect();
      }
    }
    if(typeof _map !== 'undefined'){
      _map.unfreeze();
    }

    bindWindowResizeStart();
  }, 500));
}

function bindWindowResizeStart() {
  $(window).one('resize', function() { 
    if(typeof _map !== 'undefined'){
      _map.freeze();
    }
  });
}

function updateList() {
  list().reload();
}

function updateMap() {
  if(typeof _map !== 'undefined'){
    _map.updateMap();
  }
}

function bindVisibleCount(number) {
  window.addEventListener('stateChange:listingCountVisible', (e) => {
    $('#visibleCount').html(e.detail.newValue.toLocaleString());
  });
}

function bindTotalCount() {
  window.addEventListener('stateChange:listingCountTotal', (e) => {
    let count = e.detail.newValue;
    $('.totalCount').html(count.toLocaleString());

    let msg = 'No Results'
    if (count == 1) {
      msg = 'View 1 result';
    } else if (count > 1) {
      msg = 'View ' + count.toLocaleString() + ' Results';
    }
    $('#viewListingsButton').text(msg);
    if ($('#viewResultsLink').length > 0) {
      $('#viewResultsLink')[0].childNodes[2].textContent = ' ' + msg + ' ';
    }

    setState('listingCountVisible', count);
  });
}

function bindViewChanges() {
  window.addEventListener('stateChange:mobileSearchResultsView', (e) => {
    if(e.detail.newValue === 'list') {
      hideMobileMap();
    } else if (e.detail.newValue === 'map') {
      showMobileMap();
    }
  });

  window.addEventListener('stateChange:desktopSearchResultsView', (e) => {
    const view = e.detail.newValue;
    Cookies.set('desktopSearchResultsView', view);
    
    if(view === 'list') {
      hideDesktopMap();
    } else if (view === 'split') {
      showDesktopMap();
    }
  });
}

function hideDesktopMap() {
  $('#searchResultsListColumn').addClass('list-only');
}

function showDesktopMap() {
  $('#searchResultsListColumn').removeClass('list-only');
  initMap();
}

function initMap(options) {

  if(typeof _map !== 'undefined') {
    return;
  }

  if(typeof options === 'undefined'){
    options = {};
  }

  var defaultOptions = {
    contactId: mapSupport.contactId,
    currencySymbol: mapSupport.currencySymbol,
    mlsDefaultBounds: mapSupport.mlsDefaultBounds,
    translations: translations.map,
    loadingMessage: {
      start: showPageLoadingMsg,
      stop: hidePageLoadingMsg
    },
    drawingCompleteCallback: drawingCompleteCallback
  };

  var mapOptions = $.extend({}, defaultOptions, options);

  _map = new FlexmlsMap(document.getElementById('map'), mapOptions);
  _map.init();

  if( !mapSupport.isListDriven ){
    mapListConnection.connect();
  }

  setupMapHeaderBindings();

}

function map() {
  return _map;
}

function list() {
  return _list;
}

function setupMapHeaderBindings() {

  $('#mobileCloseMapButton').click(function(e){
    e.preventDefault();

    setState('mobileSearchResultsView', 'list');
  });

  $('#desktopHideMapButton').click(function(e){
    e.preventDefault();

    setState('desktopSearchResultsView', 'list');
  });

}

function hideMobileMap() {
  $('body').removeClass('map-view');
}

function tagRecentSearch(filter) {
 
  const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}

  axios.put("tag_recent", {filter: filter}, {
    withCredentials: true,
    headers: {
      'X-CSRF-Token': token.content,
      accept: 'application/json'
    },
  }).catch((e) => {
    console.error(`Could not update recent search: ${e}`)
  });

}

function drawingCompleteCallback(filter, newBounds, annotation) {
  map().freeze();
  list().freeze();

    // Make a copy of annotations so the state's actual annotations value
    // doesn't change until we call setState.
    var annotations = JSON.parse(JSON.stringify(getState('annotations')));

  if (annotation) {
    annotations.push(annotation);
    setState('annotations', annotations);
  }

  setState('filter', filter);

  map().moveMapToBoundary(newBounds).then((bounds) => {

    // Since the map is frozen, the bounds need to be updated manually.
    setState('mapBounds', bounds);

    map().unfreeze();
    list().unfreeze();

    map().updateMap().done(() => {
      setState('listingCountTotal', map().mappedListingCount());
    });
    list().reload();
  });
}

function hideSearchResults() {
  list().freeze();
  $("#searchResultsListColumn").css('z-index',0);
  $("#searchResultsListColumn").addClass("hidden-list");
}
function showSearchResults() {
  $("#searchResultsListColumn").removeClass("hidden-list");
  $("#searchResultsListColumn").css('z-index',1);
  list().unfreeze();
  list().reload();
  updateVisibleCount();
}

function viewListings() {
  // Show the results.
  // Hide the filter panel
  $("#filterPanel").addClass('hidden');
  if ($("#filterPanel").hasClass('return-to-map')) {
    // We were in mobile map mode.  Return to that
    $("#filterPanel").removeClass('return-to-map');
    showMobileMap();
  } else {
    // We were not in mobile map mode
    //hideMobileMap();
    if ($("#filterPanel").hasClass('return-to-list')) {
      $("#filterPanel").removeClass('return-to-list');
      // We were in list only mode. Return to that
      $("#searchResultsListColumn").addClass("list-only")
      hideDesktopMap();
    }
    // Make sure, when needed, we can see the 'Hide Map' button.
    $(".desktop-hide-map-button").removeClass('hidden');
  }
  showSearchResults();
  recordFilterFirstState(false);
};

function recordFilterFirstState(filterFirst) {
  var currentUrl = window.location.href;

  if (!currentUrl.includes('display_filter=')){
    var urlAppender = (currentUrl.includes('?')) ? '&' : '?';
    history.replaceState(null,null,currentUrl + urlAppender + "display_filter=" + filterFirst);
  } else if (filterFirst && currentUrl.includes('display_filter=false')) {
    history.replaceState(null,null,currentUrl.replace('display_filter=false','display_filter=true'));
  } else if (!filterFirst && currentUrl.includes('display_filter=true')) {
    history.replaceState(null,null,currentUrl.replace('display_filter=true','display_filter=false'));
  }
}

let searchResults = {
  init: init,
  map: map,
  updateMap: updateMap,
  // for testing only
  _handleFilterModalClose: filterChangedCallback,
  hideSearchResults: hideSearchResults,
  showSearchResults: showSearchResults,
  _destroy: () => { _map = undefined; }
}; 
  
export {searchResults, map};
