import MarkerWithLabel from 'markerwithlabel';

export default function RadiusMarker(circle) {
    var options = {
        position: new google.maps.LatLng(circle.getCenter().lat(), circle.getBounds().getNorthEast().lng()),
        labelClass: 'marker-radius',
        labelContent: (circle.getRadius() / 1609.34).toFixed(2) + "mi",
        labelAnchor: new google.maps.Point(25,8),
        icon: " ", // hide the normal google map pins
        clickable: false
    }
    
    this.shape = circle;
    
    MarkerWithLabel.call(this, options);
}

RadiusMarker.prototype = Object.create(MarkerWithLabel.prototype);

RadiusMarker.prototype.delete = function() {
    this.setMap(null);
}

RadiusMarker.prototype.shape = function() {
    return this.shape;
}