import {searchResults} from './search_results'

var form;

function init() {
  form = $('#saveAndShareForm');

  form.on('submit',        showPageLoadingMsg);
  form.on('ajax:success',  handleAjaxSuccess);
  form.on('ajax:error',    handleAjaxError);
}

function handleAjaxSuccess(event, search, textStatus, xhr) {
  var referrer = window.location.origin + routes.listings_path('saved_searches', search.search.Id);
  var params = { 
    referrer: referrer,
    type: "search", 
    id: search.search.Id,
    view_id: search.view_id
  };
  window.location.href = routes.new_share_path(params);
}

function handleAjaxError(event, xhr, status, message) {
  toast.error(xhr.responseText, false);
  hidePageLoadingMsg();
}


export let saveAndShareModal = {
  init: init
}

