var contactAgent = (function() {

  var translations;
  var $modal;

  function init(options) {

    translations = options.translations
    $modal = $('#contactAgentModal');

    $('#contactAgentForm').submit(showPageLoadingMsg)
                          .on("ajax:success", handleSuccess)
                          .on("ajax:error", handleError)
                          .on("ajax:complete", hidePageLoadingMsg);
  }

  function handleSuccess() {
    $modal.modal('hide');
    $modal.find('#contactAgentMessageBody').val('');
    toast.success(translations.success);
  }

  function handleError(event, xhr, status, message) {
    if( xhr.status === 500) {
      // handle server error
      toast.error(translations.error, false);
    } else {
      // handle validation error
      errors = JSON.parse(xhr.responseText);

      removeErrors();

      $.each(errors, function(field_name, error_message) {
        $field = $('[name="listing_message[' + field_name + ']"]');
        $field.closest('.form-group').addClass('has-error');
        $field.siblings('.errorMessage').text(error_message);
      });
    }
  }

  function removeErrors() {
    $modal.find('.form-group').removeClass('has-error');
    $modal.find('.errorMessage').text('');
  }

  return {
    init: init
  }

})();
