/**
 * AddToCollection
 *
 * Adds/removes a listing from a listing cart. Some options can be set using
 * data attributes on the link. Data attributes will override options.
 *
 * Data attributes on the link:
 *   data-listing-id:
 *   data-listing: A json representation of the listing for track_event
 *   data-added: The initial state of the listing, either true or false
 *   data-contact-id: The contact id
 *   data-collection-id: The collection id
 *   data-collection-name: The collection name
 *
 * Options:
 *   listingId
 *   contactId
 *   collectionId
 *   collectionName
 *   successCallback: A function to be called if the request is successful
 */

import Cookies from "js-cookie";
import Url from "domurl";
import axios from "axios";

export function AddToCollection(element, options) {
  this.$element = $(element);
  if (typeof this.$element.attr("data-listing") !== "undefined") {
    this.listing = JSON.parse(this.$element.attr("data-listing"));
  } else if (window.js_listing) {
    this.listing = window.js_listing;
  }

  this.added = this.$element.data("added") || options.added;

  this.collection = {
    id: this.$element.data("collection-id") || options.collectionId,
    name: this.$element.data("collection-name") || options.collectionName,
  };

  this.successCallback =
    typeof options.successCallback === "function"
      ? options.successCallback
      : function () {};

  this.contactId = this.$element.data("contact-id") || options.contactId;
  this.listingId = this.$element.data("listing-id") || options.listingId;

  this.init();
}

AddToCollection.postLoginCookieName = "postLoginAction";

AddToCollection.prototype = {
  constructor: AddToCollection,

  init: function () {
    this.$element.click(this._handleClick.bind(this));
  },

  add: function () {
    if (this.added !== true) {
      if (
        typeof this.contactId !== "undefined" &&
        /^\d{26}$/.test(this.contactId)
      ) {
        this._addToContactsCollection();
      } else {
        this._addToOwnCollection();
      }
    }
  },

  remove: function () {
    if (this.added === true) {
      if (
        typeof this.contactId !== "undefined" &&
        /^\d{26}$/.test(this.contactId)
      ) {
        this._removeFromContactsCollection();
      } else {
        this._removeFromOwnCollection();
      }
    }
  },

  //
  // Private functions
  //

  _handleClick: function (e) {
    e.preventDefault();

    showPageLoadingMsg();
    this.added ? this.remove() : this.add();
  },

  _addToOwnCollection: function () {
    const urlString = window.ListingAction.url(
      "/listings/" + this.listingId + "/carts/add",
      listingActionSettings.mobileUrl
    );

    let data = { cart_id: this.collection.id };

    if (this.collection.name == "Favorites") {
      data.a = "listing_saved";
    } else if (this.collection.name == "Rejects") {
      data.a = "listing_hidden";
    }

    /*
     * if the user is logged in (portal/private/vow role), we can add the
     * listing to the cart.
     * If not, set the postLoginAction cookie and ship them off to the auth URL
     */

    if (
      Flexmls.currentUser.role === "portal" ||
      Flexmls.currentUser.role === "vow" ||
      Flexmls.currentUser.role === "private"
    ) {
      axios
        .put(urlString, data, { withCredentials: true })
        .then(() => {
          this.added = true;
          this._trackEvents();
          this.successCallback(this);
        })
        .catch((e) => {
          console.error("error adding to collection", e);
        })
        .finally(hidePageLoadingMsg);
    } else {
      Cookies.set(
        AddToCollection.postLoginCookieName,
        [this.collection.name, this.listingId].join(",")
      );
      console.log(Flexmls.currentUser);
      window.location = Flexmls.currentUser.authUri;
    }
  },

  _addToContactsCollection: function () {
    var self = this;

    const urlString = window.ListingAction.url(
      "/listings/carts/add_selected",
      listingActionSettings.mobileUrl
    );

    let data = {
      resource: this.collection.name,
      listingId: self.listingId,
      "contacts[][id]": this.contactId,
    };

    axios
      .put(urlString, data, {
        withCredentials: true,
      })
      .then(() => {
        self.added = true;
        self.successCallback(self);
      })
      .finally(hidePageLoadingMsg);
  },

  _removeFromOwnCollection: function () {
    var self = this;
    var urlString = window.ListingAction.url(
      "/listings/" + self.listingId + "/carts",
      listingActionSettings.mobileUrl
    );
    var url = new Url(urlString);
    url.query.cart_id = this.collection.id;

    axios
      .delete(url.toString(), {
        withCredentials: true,
      })
      .then(() => {
        self.added = false;
        self.successCallback(self);
      })
      .finally(hidePageLoadingMsg);
  },

  _removeFromContactsCollection: function () {
    var self = this;
    var url;
    var urlString;
    var portalCollections = [
      "Favorites",
      "Possibilities",
      "Recommended",
      "Rejects",
      "Removed",
    ];

    if ($.inArray(this.collection.name, portalCollections) >= 0) {
      urlString = window.ListingAction.url(
        "/listings/" + self.listingId + "/vow_special_carts",
        listingActionSettings.mobileUrl
      );
      url = new Url(urlString);
      url.query.name = this.collection.name;
      url.query.contact_id = this.contactId;
    } else {
      urlString = window.ListingAction.url(
        "/listings/" + self.listingId + "/carts",
        listingActionSettings.mobileUrl
      );
      url = new Url(urlString);
      url.query.contact_id = self.contactId;
      url.query.cart_id = this.collection.id;
    }

    axios
      .delete(url.toString(), {
        withCredentials: true,
      })
      .then(() => {
        self.added = false;
        self.successCallback(self);
      })
      .finally(hidePageLoadingMsg);
  },

  _trackEvents: function () {
    if (this.collection.name == "Favorites") {
      EventTracking.Adapters.Rails.track_event(
        "listingLiked",
        this.listing || window.js_listing
      );
    } else if (this.collection.name == "Rejects") {
      EventTracking.Adapters.Rails.track_event(
        "listingHidden",
        this.listing || window.js_listing
      );
    }
  },
};
