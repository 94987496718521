var routeBuilder = (function() {

  var portalSlug = '';

  function getPortalSlug(){
    return portalSlug;
  }
  
  function setPortalSlug(newPS){
    portalSlug = newPS;
  }

  function buildPaths(routes, baseKey) {

    var paths = {};

    for (const [pathName, pathStringOrFunction] of Object.entries(routes)) {
      paths[pathName + "_path"] = function() {
        var params = {};
        var path;
        var base = (baseKey && typeof window.routeBuilderBaseUrls[baseKey] !== 'undefined') ? window.routeBuilderBaseUrls[baseKey] : '';

        // remove undefined arguments
        var args = Array.prototype.slice.call(arguments).filter(function(element) {
          return (typeof element !== 'undefined');
        });

        // If there are query parameters in the base url, move those to the params object
        if(base.indexOf('?') >= 0) {
          var baseParts = base.split('?');
          base = baseParts[0];

          baseParts[1].split('&').forEach(function(paramString) {
            var paramParts = paramString.split('=');
            params[paramParts[0]] = paramParts[1];
          });
        }

        // if the last argument is an object, add it to the params
        if ( typeof args[args.length-1] === 'object') {
          // $.extend(params, args.splice(args.length-1, 1)[0]);
          Object.assign(params, args.splice(args.length-1, 1)[0]);
        }

        if(typeof pathStringOrFunction === 'function') {
          path = pathStringOrFunction.apply(this, args)
        } else {
          path = pathStringOrFunction;
        }

        return base + path + handleParams(params);
      }
    }
    return paths;
  }

  function handleParams(params) {
    var queryString = ''
    if(typeof params !== 'undefined') {
      let strings = [];
      
      for (const [key, value] of Object.entries(params)) {
        if(typeof value !== 'undefined'){
          strings.push( key + '=' + value);
        }
      }
      if(strings.length){
        queryString += '?' + strings.join('&');
      }
    }
    return queryString;
  }

  // This function ensures that the function from which it was called was called
  // with a certain number of arguments. requireArgs accepts a list of numbers 
  // as arguments, specifying the acceptable values for the caller function argument
  // size.
  function requireArgs(parentArgs, ...requiredArgCount) {
    if(requiredArgCount.indexOf(parentArgs.length) < 0) {
      var end;
      if(requiredArgCount.length === 1) {
        end = requiredArgCount[0];
        end += (requiredArgCount[0] > 1) ? ' arguments' : ' argument';
      } else {
        var last = requiredArgCount.splice(requiredArgCount.length-1, 1)[0];
        end = requiredArgCount.join(', ') + ", or " + last +' arguments';
      }
      throw Error('This route requires '+ end + '.')
    }
  }

  return {
    buildPaths: buildPaths,
    getPortalSlug: getPortalSlug,
    setPortalSlug: setPortalSlug,
    requireArgs: requireArgs
  }

})(); 

export default routeBuilder;
