import axios from 'axios';
import {setState} from './state'

export class SortControl {

  constructor(selector) {
    this.control = $(selector);
    $(document).on('click', `${selector} .sortLink`, this._handleSortLinkClick.bind(this));
    this._listenForSortChanges();
  }

  async loadCustomSorts() {
    let self = this;
    
    await axios.get(routes.my_sorts_path(), {
      withCredentials: true,
    })
    .then((response) => {
      let data = response.data;
      if(data.length > 0){
        var selectedId = self.control.find('.selectedSort').attr('data-sort-id');
        var items = [];
        
        // This may be a little dumb. If a custom sort was previously selected,
        // then there should already be an option for it in the select. If we
        // just add the other custom sorts below it, then it could be out of order.
        // So instead, we can delete it, and then add it back in the right order
        // as a selected option. Maybe no one cares about the order, but that's
        // why I said this may be a little dumb.
        if(this.control.find('.customSortLink').length > 0){
          this.control.find('.customSortLink').closest('li').remove();
        }

        data.forEach(function(sort) {

          let selectedClass = (sort.Id === selectedId) ? 'selectedSort' : '';

          let item = '<li>';
              item +=   `<a href="#" data-sort-id="${sort.Id}" class="sort-link sortLink customSortLink ${selectedClass}">`;
              item +=     '<span class="flexicon flexicon-check selected-sort-check"></span>';
              item +=     `<div class="sort-link-label">${sort.Name}</div>`;
              item +=   '</a>';
              item += '</li>';

          items.push(item);
        })

        this.control.find('.customSortLabel').last().after(items);
      } else if (this.control.find('.customSortLink').length === 0){
        this.control.find('.customSortLabel').remove();
      }
    })
    .catch((e) => {
      console.error( "The custom sorts could not be loaded.", e );
      if (this.control.find('.customSortLink').length === 0){
        this.control.find('.customSortLabel').remove();
      }
    })

  }

  _handleSortLinkClick(e) {
    e.preventDefault();
    setState('sortId', $(e.currentTarget).attr('data-sort-id'));
  }

  // This could probably be done in the click handler, but if the sortId were to
  // be changed by some method other than clicking the sort link, then the active
  // link wouldn't get updated.
  _listenForSortChanges() {
    window.addEventListener('stateChange:sortId', (e) => {
      $('.sortLink').removeClass('selectedSort');
      $('.sortLink[data-sort-id="' + e.detail.newValue + '"]').addClass('selectedSort');
    })
  }

}
