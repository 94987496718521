class SelectToButton {

  constructor(element) {
    this._element = $(element);
    this._value = this._element.val();
    this._link = undefined;

    this._init()    
  }

  _init() {
    this._element.hide();

    this._link = $('<a>', {
      href: "#",
      text: this._value,
      'data-value': this._value,
      class: 'select-to-button-link',
      click: this._linkClickHandler.bind(this)
    });

    this._element.parent().append(this._link);
  }

  _linkClickHandler(e) {
    e.preventDefault();

    var newIndex = this._atEndOfList() ? 0 : this._selectedIndex() + 1;
    this._value = this._element.find('option').eq(newIndex).val();

    this._updateSelect();
    this._updateLink();
  }

  _updateSelect() {
    this._element.val(this._value).change();
  }

  _updateLink() {
    this._link.text(this._value);
    this._link.attr('data-value', this._value);
  }

  _selectedIndex() {
    return this._element.prop('selectedIndex');
  }

  _atEndOfList() {
    return this._element.find('option').length === this._selectedIndex() + 1;
  }

}

export let selectToButton = (selector) => {
  $(selector).each(function() {
    if (undefined === $(this).data('selectToButton')) {
      var plugin = new SelectToButton(this);
      $(this).data('selectToButton', plugin);
    }
  });  
}
