import Url from 'domurl';
import {searchFormEvents} from './search_form_events';


function toggleDateRelativity(exchangeIcon) {
  var $group = $(exchangeIcon).closest('.control-group');
  $group.find(".controls").each(function() {
    $(this).toggle();
  });

  $group.find('.controls:visible input').removeAttr('disabled');
  $group.find('.controls:hidden input').attr('disabled', 'disabled');

  // plant a cookie for the default date type for this field going forward
  $.cookie($(exchangeIcon).data('field-id'), ($group.find(".relative-date-ranges:visible").length > 0 ? 'relative' : 'range'), {expires:365});
}

function updateViewResultsHref(newFilter){
  var currentFilter;
  var viewResultsLink = $('a.view-results');

  if(typeof newFilter !== 'undefined') {
    currentFilter = newFilter;
  } else {
    currentFilter = $('form.search').attr('data-filter');
  }
  
  if (typeof currentFilter !== 'undefined'){
    var url = new Url(viewResultsLink.attr('href'));
    url.query._filter = currentFilter;
    viewResultsLink.attr('href', url.toString());
  }
}

function findCount() {
  var viewResultsLink = $('a.view-results');

  viewResultsLink.text(viewResultsLink.data("loading-text") );
  
  $.ajax({
    url: routes.count_path(),
    data: formSerializer(),
    type: 'POST',
    success: function(data) {
      // Even if a timeout or something occurred, we may still have the filter.
      window.dispatchEvent(searchFormEvents.filterChange(data.filter));
      viewResultsLink.text(data.message);
      if ($('#viewResultsLink').length > 0) {
        $('#viewResultsLink')[0].childNodes[2].textContent = ' ' + data.message + ' ';
      }
      window.dispatchEvent(searchFormEvents.countChanged(data));
    },
    error: function(xhr, textStatus, errorThrown) {
      alertModal('Sorry, there was an error updating the form. ');
    }
  });

}

function formSerializer() {
  if ($("#advanced_address:visible").length > 0) {
    return $("#advanced_address, #basics-content").find("input, textarea, select").serialize();
  } else {
    return $("#list_container_form :not(.dont_serialize)").serialize();
  }
}

function updateResultsButton(count) {
  if (count === 0) {
    $("#viewListingsButton").find('span')[0].textContent = ''
    $("#viewListingsButton").find('span')[1].textContent = ''
    $("#viewListingsButton").find('span')[2].textContent = 'No Results'
  } else if (count === 1) {
    $("#viewListingsButton").find('span')[0].textContent = 'View'
    $("#viewListingsButton").find('span')[1].textContent = '1'
    $("#viewListingsButton").find('span')[2].textContent = 'Result'
  } else if (count > 1 && count < 1000) {
    $("#viewListingsButton").find('span')[0].textContent = 'View'
    $("#viewListingsButton").find('span')[1].textContent = count
    $("#viewListingsButton").find('span')[2].textContent = 'Results'
  } else {
    $("#viewListingsButton").find('span')[0].textContent = 'View'
    $("#viewListingsButton").find('span')[1].textContent = (Math.round(count / 1000)).toFixed() + 'K'
    $("#viewListingsButton").find('span')[2].textContent = 'Results'
  }
}

export var searchFormHelpers = {
  toggleDateRelativity: toggleDateRelativity,
  findCount: findCount,
  updateViewResultsHref: updateViewResultsHref,
  formSerializer: formSerializer,
  updateResultsButton: updateResultsButton
}
