import {Basics} from './basics';

var $dropdownParent = $('#filterModal').length === 0 ?  $(document.body) : $('#filterModal');

function ajaxFieldLists() {
  $('.ajaxFieldList').each(function() {

    // don't set up select2 twice
    if( typeof $(this).data('select2') === 'undefined'){
      $(this).flexSelect2({
        unmodified: true,
        dropdownParent: $dropdownParent,
        minimumInputLength: ($(this).hasClass('searchableFieldList') ? 3 : 0),
        ajax: {
          url: Flexmls.portalSlugPrefix + '/search/field_lists',
          dataType: 'json',
          data: function (params) {
            var select = $(this);
            var ajaxData = {
              search: params.term,
              domain: select.attr('data-domain'),
              field_name: select.attr('data-field-name'),
              page: params.page || 1
            }

            if (Basics.proptype().length > 0 && Basics.proptype().val() !== null){
              ajaxData.property_type = Basics.proptype().val();
            } else if(Basics.propertyClass().length > 0 && Basics.propertyClass().val() !== null){
              ajaxData.property_class = Basics.propertyClass().val();
            }

            if (Basics.mls_id().length > 0 && Basics.mls_id().val() !== null) {
              ajaxData.mls_ids = Basics.mls_id().val();
            }

            return ajaxData;
          },
          processResults: function (data) {
            var results = $.map(data.results, function(d) {
              return {id: d.Value ,text: d.Name};
            });
            return {
              results: results,
              pagination: {
                more: data.pagination.current_page < data.pagination.total_pages
              }
            };
          }
        }
      });
    }
  });
}

function multiSelectDropdowns() {
  var excludedDropdowns = '.boolean-group-operator-select, #locationSearch, .form-control, .ajaxFieldList';
  // Setup Select2 on multi-select elements
  $('select').not(excludedDropdowns).each(function() {
    if($(this).prop('multiple')){
      // don't set up select2 twice
      if( typeof $(this).data('select2') === 'undefined'){
        $(this).flexSelect2({
          unmodified: true,
          dropdownParent: $dropdownParent,
        });
      }
    }
  });

}

export var fieldEnhancements = {
  ajaxFieldLists: ajaxFieldLists,
  multiSelectDropdowns: multiSelectDropdowns
};
