import { Controller } from "stimulus";
import {getState, setState} from '../search_results/state'
import Cookies from 'js-cookie';


export default class extends Controller {
  static values = {
    flagship: String
  }

  routePostMessage(e) {
    let method = e.data.split(':')[0];
    switch (method) {
      case "setPrintTitle":
        $('#print_modal #print-modal-title').text(e.data.split(':')[1]);
        break;
      case "resetPrintButtons":
        this.resetPrintModal();
        break;
      case "addPrintButton":
        let data = e.data.split(':');
        let text = data[1];
        let id = data[2];
        let classname = data[3];
        let submit = "document.getElementById('print_iframe').contentWindow.postMessage('" + data[4] + "', '*')";
        $('#print_modal #native_buttons').append('<button id="' + id + '" class="' + classname + '" onclick="' + submit + '">' + text + '</button>');
        break;
    }
  }

  async showPrint () {
    this.resetPrintModal();
    let modal = $('#flagship_print_modal_content');
    let iframe = document.createElement('iframe');
    iframe.id = "print_iframe";
    
    let printUrl = new URL(this.flagshipValue + "/cgi-bin/mainmenu.cgi?cmd=srv+srch_rs/print.html")
    let filter = getState('filter')
    let manuallyCreatedFilter = false
    let currentFilter = getState('filter').toString()

    // If there is no filter we need to build it from either the map markers
    // or the summary/list view elements.
    if (currentFilter === '') {
      var idStrings = [];
      if (Cookies.get('SearchResultsLastViewMode') === 'map') {
        // Ask the map for the ids from its map markers
        idStrings = await this.requestListingIdsFromMap();
      } else {
        // Look at the loaded elements in the list or summary
        idStrings = $('.listingListItem').map(function() {
          return "'" + this.id + "'";
        }).get();
        if (idStrings.length > 50) {
          idStrings = idStrings.slice(0, 50)
        }
      }
      currentFilter = "ListingKey Eq " + idStrings.join(', ')
      manuallyCreatedFilter = true
    }
    printUrl.searchParams.set('_filter',currentFilter);

    if (Flexmls.currentUser.role === 'idx' || Flexmls.currentUser.role === 'vow' || Flexmls.currentUser.role ==='public') {

      printUrl.searchParams.set('ma_tech_id', "x'" + Flexmls.currentUser.mls_id + "'");
      printUrl.searchParams.set('tech_id',  "x'" + Flexmls.currentUser.agent_id + "'");

      let sharedLinkFilter = $('[data-shared-link-filter]')
      if (sharedLinkFilter.length > 0) {
        printUrl.searchParams.set('_filter',sharedLinkFilter.first().attr('data-shared-link-filter'));
      }
    }

    printUrl.searchParams.set('from_mweb','true');
    let selected = $('.selectListingsControl:checked');
    let listings = selected.toArray().map((sel) => sel.id.split('_')[1]);
    printUrl.searchParams.set('selected',listings.join(","));
    printUrl.searchParams.set('results_totallistings',(manuallyCreatedFilter ? idStrings.length : getState('listingCountTotal')));
    printUrl.searchParams.set('cart_totallistings',listings.length);
    printUrl.searchParams.set('report_puborpriv','public');
    printUrl.searchParams.set('view_id', $('.user-view-link.userViewLink.selected-view').data("view-id"));

    iframe.src = printUrl.href;
    $(modal).empty();
    $(modal).append(iframe);
    $('#print_modal').modal('toggle');
  }

  resetPrintModal() {
    $('#print_modal #print-modal-title').text("");
    $('#print_modal #native_buttons').html("");
  }


  requestListingIdsFromMap() {
    return new Promise((resolve) => {
      const handleMapData = (event) => {
        const data = event.detail; // Access the data sent from the map controller
        resolve(data.listingIds);
        window.removeEventListener("print:listingIdsResponse", handleMapData)
      };

      // Ask the map for the listings IDs for each single marker
      window.addEventListener("print:listingIdsResponse", handleMapData)

      // Dispatch the request event
      window.dispatchEvent(new CustomEvent("map:requestListingIds", { detail: { maxListings: 50, returnEvent: "print:listingIdsResponse"}}))
    });
  }
}
