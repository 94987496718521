import Url from 'domurl';
import MarkerWithLabel from 'markerwithlabel';
import ListingMarker from 'src/map/markers/listing_marker';
import LRU from 'lru-cache';
import {AddToCollection} from 'src/add_to_collection';

/*

    Preview Marker

    This marker appears above/below/next to a ListingMarker or PriceMarker, and shows more information about 
    that listing and a slideshow. The PreviewMarker never exists on it's own. It is attached to a ListingMarker 
    or PriceMarker through the `listingMarker` option.

    Options argument:

      listingId: (required)
      lat: (required)
      lng: (required)

      mapWidth(): (required) A function that returns the width of the map in px.
      
      listingMarker: (required) In the UI, Preview markers are "attached" to a regular listing marker. The
        Preview marker will appear when the user hovers over the listing marker. This attribute will be the
        marker that is associated with this preview marker.

      showSlideshow: (optional) defaults to true


 */



export default function PreviewMarker(options) {
  var self = this;

  this.labelWidth = 230;
  this.labelHeight = 196;
  
  this.listingId = options.listingId;
  
  this.mapWidth = options.mapWidth;
  this.listingMarker = options.listingMarker;

  this.showSlideshow = (typeof options.showSlideshow === 'undefined') ? true : options.showSlideshow;

  this.lat = options.lat;
  this.lng = options.lng;

  var labelOptions = {
    labelClass: this._labelClass(),
    labelAnchor: this._calculateLabelAnchor(),
    labelContent: this._initalContent(),
    zIndex: google.maps.Marker.MAX_ZINDEX +1, // always on top
    position: new google.maps.LatLng(this.lat, this.lng),
    icon: " " // hide the normal goggle map pin
  };

  MarkerWithLabel.call(this, labelOptions);

  this.ajaxXhr = this._getPreviewHtml();

}

PreviewMarker.prototype = Object.create(MarkerWithLabel.prototype);


//
// Public functions
// 

PreviewMarker.prototype.delete = function() {
  if(typeof this.ajaxXhr !== 'undefined'){
    this.ajaxXhr.abort();
  }
  if(this.getMap() && typeof this.slider !== 'undefined'){
    this.slider.destroy();
  }
  this.setMap(null);
}



//
// Private functions
// 

PreviewMarker.prototype._labelClass = function() {
  var klass = 'marker-preview-wrapper-' + this._verticalOrientation();
  if (this.listingMarker.constructor.name === 'PriceMarker'){
    klass += '-price-marker';
  }
  return klass;
};

PreviewMarker.prototype._initalContent = function() {
  return $('<div>').addClass('marker-preview').html( window.loadingSpinner() ).prop('outerHTML');
};

PreviewMarker.prototype._calculateLabelAnchor = function() {

  var pinPositionFromLeft = this.listingMarker.viewportPosition().left;

  var x, y;
  
  // If there's room, center the label. If not, offset it to the left or right.
  var requiredWidth = this.labelWidth/2 + 10;
  if (pinPositionFromLeft < requiredWidth) {
    x = pinPositionFromLeft - 10;
  } else if (pinPositionFromLeft > this.mapWidth() - requiredWidth){
    var availableSpace = this.mapWidth() - pinPositionFromLeft;
    x = this.labelWidth - availableSpace + 10;
  } else {
    x = this.labelWidth/2;
  }

  // Put the label below if there isn't enough room above.
  if (this._verticalOrientation() === 'above') {
    y = this._requiredHeight();
  } else {
    // If the preview appears below, position it so that the top edge of the preview
    // is touching the bottom edge of the pin. CSS padding will be used to add
    // the gap. Using padding allows you to move the mouse from the pin across 
    // the gap to the preview without the preview closing.
    y = (this.listingMarker.labelAnchor.y);
  }

  return new google.maps.Point(x, y);
};

PreviewMarker.prototype._requiredHeight = function(){
  var gutter = 8; 
  var highlightWidth = 12;

  // This positions the preview so that it will be {gutter}px above the top
  // edge of the pin.
  var offset = this.labelHeight + this.listingMarker.labelAnchor.y + gutter;
  
  // We need to add a little more space to account for the highlight on ListingMarkers
  if ( this.listingMarker.constructor === ListingMarker ) {
    offset += highlightWidth;
  }

  return offset;
};

// returns 'above' or 'below'
PreviewMarker.prototype._verticalOrientation = function(){
  var pinPosition = this.listingMarker.viewportPosition().top;
  
  return pinPosition < this._requiredHeight() ? 'below' : 'above';
};

// Create a small LRU cache to avoid repeated calls to the server if
// the user is moving their mouse over a screenful of listings. I can
// see ~10 listing cards on my monitor, at a time. Padding that out
// to 50 as a starting point.
PreviewMarker.previewHtmlCache = new LRU(50);

PreviewMarker.prototype._getPreviewHtml = function(){
  var self = this;
  var url = new Url();
  if (url.path.charAt(url.path.length - 1) == '/') {
    url.path = url.path.slice(-1)
  }

  url.path = url.path.replace(/\/listings$/, '/map');
  url.path = url.path + "/listing_preview"

  var data = {};

  if(this.listingId) {
    data.listing_id = this.listingId;
  } else {
    data.lat = this.lat;
    data.lng = this.lng;
  }

  var cacheKey = JSON.stringify(data);
  var cacheData = PreviewMarker.previewHtmlCache.get(cacheKey);
  if(cacheData) {
    // This timeout allows the constructor to finish and whatever
    // background processing is required to be done. Without it, the
    // `.previewSlideshow` element won't be detected and the slideshow
    // in the preview will not be activated.
    setTimeout(function() {
      self._buildPreviewMarker(cacheData);
    }, 75);
    return;
  }

  if (!data.listing_id) return; // Prevents fetching a listing preview on mouseleave event

  return $.ajax({
    url: url,
    data: data,
    success: function(partial) {
      if( partial !== ''){
        PreviewMarker.previewHtmlCache.set(cacheKey, partial);
        self._buildPreviewMarker(partial);
      } else {
        self.delete();
      }
    }
  });

};

PreviewMarker.prototype._clickHandler = function (event) {
  event.preventDefault();
  event.stopPropagation();

  var target = $(event.target);

  if( target.hasClass('marker-preview-wrapper-above') || target.hasClass('marker-preview-wrapper-below') ) {
    // The user clicked an empty spot the the wrapper div. Delete the preview marker 
    // and click whatever is underneath.
    var x;
    var y;

    this.delete();

    if(event.clientX && event.clientY) {
      x = event.clientX;
      y = event.clientY;
    } else if (event.originalEvent.touches && event.originalEvent.touches[0]){
      x = event.originalEvent.touches[0].clientX;
      y = event.originalEvent.touches[0].clientY;
    }

    if(x && y){
      document.elementFromPoint(x, y).click();
    }
  } else {

    if (target.closest('.rsArrowLeft').length) {
      this.slider.prev();
    } else if (target.closest('.rsArrowRight').length) {
      this.slider.next();
    } else if (target.closest('.saveListingLink').length) {
      // let addToCollection deal with it
    } else {
      // let the stimulus controller deal with it.
    }
  }
};

PreviewMarker.prototype._buildPreviewMarker = function (partial) {
  this.set('labelContent', partial);
  if (this.showSlideshow && $(".previewSlideshow").length){
    this.slider = $(".previewSlideshow").royalSlider({
      imageScaleMode: 'fill',
      controlNavigation: 'none',
      controlsInside: 'true',
      transitionType: 'fade',
      transitionSpeed: 0,
      imageScalePadding: 0,
      fadeinLoadedSlide: false,
      arrowsNavAutoHide: false,
      usePreloader: false
    }).data('royalSlider');

    $('.rsArrowIcn').addClass('flexicon flexicon-chevron');
    $('.rsArrowLeft .rsArrowIcn').addClass('flexicon-180');
  }

  // Bind events to eventDiv_, but make UI changes on labelDiv_ 
  new AddToCollection($(this.label.eventDiv_).find('.saveListingLink'), {
    successCallback: function (data) {
      $(this.label.labelDiv_).find('.saveListingLink').toggleClass('active');
    }
  });

  // The normal way to bind events to markers is to do something like: 
  // 
  //    this.addListener('click', this.clickHandler) 
  //    
  // On touch devices, the click is first triggered on the map underneath
  // the marker before it's triggered on the marker. That's a problem because
  // clicking the map should close the marker. Using jQuery to bind the marker
  // div on touchstart events allows the click to be intercepted before it
  // gets to the map. 'touchstart' isn't a valid marker event type, so we 
  // can't use this.addListener('touchstart').

  var eventName = Modernizr.touch ? 'touchstart' : 'click';
  $(this.label.eventDiv_).on(eventName, this._clickHandler.bind(this));
};
