function LocationSearch(element, options) {
  this.$element = $(element);
  this.$hiddenInputsContainer = $(".control-group.location.select-locations");

  // required options
  this.searchUrl = options.searchUrl;
  
  // optional options
  this.portalSlug = options.portalSlug;
  this.existingLocations = options.existingLocations || [];
  this.placeholderText = options.placeholderText;

  // If useHiddenInputs is true, the selected values will be stored in hidden
  // input elements.
  this.useHiddenInputs = (typeof options.useHiddenInputs === 'boolean') ? options.useHiddenInputs : true
  this.suppressChangeEvents = options.suppressChangeEvents || false;

  this.init();
  return this;
}

LocationSearch.prototype = {
  constructor: LocationSearch,

  init: function() {
    var self = this;
    this.changed = false;
    var $dropdownParent = $('#filterModal').length === 0 ?  $(document.body) : $('#filterModal'); 

    if (!$(this.$element).hasClass('select2-hidden-accessible')) {
      this.s2 = $(this.$element).flexSelect2({
        unmodified: true,
        dropdownParent:  $dropdownParent,
        ajax: {
          url: self.searchUrl,
          dataType: 'jsonp',
          quietMillis: 500,
          data: self.ajaxData.bind(self),
          processResults: self.processAjaxResults.bind(self),
          cache: true
        },
        minimumInputLength: 3,
        placeholder: self.placeholderText
      });
    }
    this.loadInitialData();

    this.$element.off('select2:selecting select2:unselecting');
    this.$element.on('select2:selecting select2:unselecting', this.handleListChange.bind(this));
    if (!this.suppressChangeEvents) {
        this.$element.off('select2:close');
        this.$element.on('select2:close', function() {
        if (self.changed) {
          Fields.reload();
          self.changed = false;
        }
      });
    }

  },


  loadInitialData: function(element, callback) {
    var self = this;

    this.existingLocations.forEach(function(item) {
      var fieldName = item.name;
      var value = self.cleanValue(item.value);
      var sparkQlValue = self.cleanValue(item.sparkQlValue || item.value);
      var displayName = item.label;
      var sparkQl = fieldName + " Eq '"+ sparkQlValue + "'";
      var id = fieldName + "_" + value;
      var text = self.getDisplayText(fieldName, value, displayName);

      var data = {          
        id: id,
        text: text,
        fieldName: fieldName,
        value: value,
        sparkQlValue: sparkQlValue,
        sparkQl: sparkQl
      };

      // create the option and append to Select2
      var option = new Option(text, id, true, true);
      self.s2.append(option).trigger('change');

      // manually trigger the `select2:select` event
      self.s2.trigger({
        type: 'select2:select',
        params: {data: data}
      });

      self.addHiddenInputs(fieldName, sparkQlValue, sparkQl);
    });
  },

  handleListChange: function(args) {
    var selectedItem = args.params.args.data;
    var fieldName = selectedItem.fieldName || selectedItem.id.split('_')[0];
    var value = this.cleanValue(selectedItem.value || selectedItem.id.split('_')[1]);
    var sparkQlValue = selectedItem.sparkQlValue || value;
    var sparkQl = selectedItem.sparkQl;
    this.changed = true;
    
    if(args.type === 'select2:selecting'){
      this.addHiddenInputs(fieldName, sparkQlValue, sparkQl);
      this.$element.trigger('locationSearch:selecting', [fieldName, sparkQlValue, sparkQl]);
    } else if (args.type === 'select2:unselecting'){
      this.removeHiddenInputs(fieldName, sparkQlValue);
      this.$element.trigger('locationSearch:unselecting', [fieldName, sparkQlValue, sparkQl]);
    }
  },


  cleanValue: function(val) {
    // remove quotes from val
    var value = (val.match(/^'.+'$/)) ? val.substr(1, val.length-2) : val;
    if (/\\'/.test(value)) {
      value = value.replace(/\\'/g,"'");
    }
    return value;
  },

  addHiddenInputs: function(fieldName, value, sparkQl) {
    if (!this.useHiddenInputs) {
      return;
    }
    $("<input>", {
      type: 'hidden',
      name: 'search_fields[' + fieldName + '][value][]',
      value: value,
      'data-sparkQl': sparkQl
    }).appendTo(this.$hiddenInputsContainer);
    
    // This will add a hidden input for the operator. Unlike the hidden input above that stores the value, this
    // input name is not an array. It's easier just to allow multiple inputs to be added because duplicates
    // will be ignored, and it makes it easier to remove items later on.
    $("<input>", {
      type: 'hidden',
      name: 'search_fields[' + fieldName + '][operator]',
      value: 'or'
    }).appendTo(this.$hiddenInputsContainer);
  },

  removeHiddenInputs: function(fieldName, value) {
    value = value.replace(/'/g, "\\'");
    $("input[name='search_fields[" + fieldName + "][value][]'][value='" + value + "']").remove();
    // Only remove one operator input, in case there are multiple "City" fields for example.
    $("input[name='search_fields[" + fieldName + "][operator]'][value='or']").first().remove();
  },

  ajaxData: function(params) {
    var data = {
      _q: params.term,
      _lo: true
    };

    if($('#search_fields_MlsId_value').val()) {
      data._mls = $('#search_fields_MlsId_value').val().join(',');
    }

    if (typeof this.portalSlug !== 'undefined'){
      data.portal_slug = this.portalSlug.replace(/\//g, '');
    }
    return data;
  },

  processAjaxResults: function(data, params) {
    // parse the results into the format expected by Select2  
    // Ex: {results:[{id:1, text:'Red'},{id:2, text:'Blue'}], more:true}
    var self = this;
    var results = [];

    data.D.Results.forEach(function(result) {
      var fieldName = result.Field.Id;
      var displayName = result.Field.Name;
      var value = result.Field.Value;
      var sparkQlValue = result.Field.SparkQlValue;
      var sparkQl = result.Field.SparkQl;

      if(fieldName !== 'MapOverlay'){
        results.push({
          id: fieldName + "_" + value,
          text: self.getDisplayText(fieldName, value, displayName),
          fieldName: fieldName,
          value: value,
          sparkQlValue: sparkQlValue,
          sparkQl: sparkQl
        });
      }
    });

    return { results: results };
  },

  getDisplayText: function(fieldName, value, displayName) {
    var label;
    if(fieldName === "ListingId"){
      label = value + " (MLS #)";
    } else if(value.substr(0, 7) === "polygon" || value.substr(0, 6) === "radius") {
      label = "Drawn Shape"
    } else {
      label = value + " (" + displayName +")";
    }

    return label;
  },

  selectValue: function(newValue, label) {
    var currentValues = this.$element.val();
    if(currentValues === null){
      currentValues = [];
    }
    if(typeof label === 'undefined') {
      label = newValue;
    }

    // Create <option>s for all the selected values
    if (this.$element.find("option[value='" + newValue + "']").length === 0) {
      // Create a DOM Option and pre-select by default
      var newOption = new Option(label, newValue, true, true);
      this.$element.append(newOption);
    }

    currentValues.push(newValue);
    this.$element.val(currentValues).trigger('change');
  },

  removeValue: function(valueToRemove) {
    var currentValues = this.$element.val();
    if(currentValues !== null){
      currentValues.splice(currentValues.indexOf(valueToRemove), 1);
      this.$element.val(currentValues).trigger('change');
    }
  },

  resetPlaceholder: function() {
    var width = this.$element.parent().find('.select2-selection__rendered').width();
    this.$element.parent().find('.select2-search__field').attr('placeholder', this.placeholderText).width(width);    
  }

}

export default LocationSearch;
