function OverlayMapType(type) {
  this.type = type;
  this.tileSize = new google.maps.Size(256, 256);
}

OverlayMapType.prototype.getTile = function(coord, zoom, ownerDocument) {
  var img = ownerDocument.createElement('img');
  img.src = "https://flexmaps-cdn.flexmls.com/" + this.type + "/" + coord.y + "/" + coord.x + "/" + zoom + "/" + this.type + ".png";
  return img;
};

export default OverlayMapType;
