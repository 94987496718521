import { Controller } from "stimulus";
import { getCartCount } from "../helpers/select_listings_helpers";

export default class extends Controller {
  static targets = [ "app", "header" ]
  static values = {
    route: String
  }

  connect() {
    if (!this.hasAppTarget) {
      $(this.headerTarget).hide();
    }
    this.checkButtonsEnabled();
  }

  checkButtonsEnabled() {
    this.appTargets.forEach((button) => {
      this.toggleButton(button, getCartCount() > 0)
    });
  }

  toggleButton(button, enabled) {
    if(enabled) {
      $(button).removeClass("disabled");
      $(button).attr("title", "")
    } else {
      $(button).addClass("disabled");
      $(button).attr("title", "Select one or more listings")
    }
  }

  launchCartApp(evt) {
    if ($(evt.currentTarget).hasClass("disabled")) return;

    let selected = $('.selectListingsControl:checked');
    let listings = selected.toArray().map((sel) => sel.id.split('_')[1]);
    let rs = "&listing_ids=" + listings.join(",");

    const request = new Request("/search/cart_app?id=" + evt.params.id + rs, { method: 'GET' });
    fetch(request).then(response => {
      return response.json();
    }).then(json => {
      if (json["success"]) {
        open(json["url"], '_blank');
      } else {
        toast.error(json["message"], false);
      }
    });
  }

}