import {getState} from '../search_results/state';


export function reasonableNumberShown(listingData, bounds) {
  const totalListingCount = getState('listingCountTotal');
  // For searches with =< 50 results, at least half are visible.
  // For searches with > 50 results, at least one listing is visible.
  if(totalListingCount <= 50) {
    return boundsContainsListings(totalListingCount, bounds, listingData.Clusters, 0.5)
  } else {
    return visibleListings(bounds, listingData.Clusters) > 0
  }
}

function visibleListings(bounds, clusters) {
  var visible = 0;
  var points = extractPointsFromClusters(clusters);

  clusters.forEach(function(cluster){
    var point = cluster.Centroid.match(/-*\d+\.\d+/g);
    var latLng = {lat: parseFloat(point[0]), lng: parseFloat(point[1])};
    var count = cluster.Count;

    if (bounds.contains(latLng) ) {
      visible += count;
    }
  });
  return visible;
}

function boundsContainsListings(total, bounds, clusters, threshold) {
  // don't divide by 0 (it actually works in the console, but it makes me nervous)
  return (total < 1) ? false : (visibleListings(bounds, clusters)/total) >= threshold;
}


function extractPointsFromClusters(clusters) {
  return $.map(clusters, function(cluster){
    var point = cluster.Centroid.match(/-*\d+\.\d+/g);

    var latLng = {lat: parseFloat(point[0]), lng: parseFloat(point[1])};

    return {
      count: cluster.Count,
      position: latLng
    };
  });
}
