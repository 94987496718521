import Cookies from 'js-cookie';
import {nativeAppInterface} from '../native_app_interface';

// This code supports selecting listings that are on a list results page,
// as well as selecting a list from its show page.

var sessionName = 'selectedCart';
var sessionCurrentUser = 'selectedCartCurrentUser';

var hostNameArray = window.location.hostname.split('.');
// This will produce the base domain name, like: 'flexmls.com' or 'fbsdata.com'.
var cookieDomain = hostNameArray[hostNameArray.length - 2] + '.' + hostNameArray[hostNameArray.length - 1];
var cookieOptions = { domain: cookieDomain, path: '/' };

export function clearCart() {
  // This is to be used to communicate to Android native
  // that user has deselected all selected listings.
  nativeAppInterface.updateSelectedCart(0);

  localStorage.removeItem(sessionName);
  _setSelectedCartCountCookie(0);
}

export function getCartCount() {
  var cart = _getCart();

  if (cart) {
    var count = Object.keys(cart).length;
    _setSelectedCartCountCookie(count);
    // Include an alternative for Object.keys to support older browsers, if needed.
    return count;
  }
  return 0;
}

export function checkCurrentUser() {
  var currentUser = Flexmls.currentUser;
  var sessionId = localStorage.getItem(sessionCurrentUser);
  var name = currentUser.role === 'vow' ? currentUser.contact.DisplayName : currentUser.account.Name;

  if (sessionId !== name) {
    clearCart();
    localStorage.setItem(sessionCurrentUser, name);
  }
}

export function updateCartCount() {
  var count = getCartCount();

  $('.buttonCarts').each(function(){

    var $badge = $(this).find('.selectListingsBadge');

    if (count > 0) {
      if ($(this).hasClass('search-vs-selected-toggle-btn')) {
        $('#selectedListingsCount').removeClass('hidden');
      } else if ($badge.length === 0) {
        $badge = $('<div>').addClass('selectListingsBadge badge');
        $(this).append($badge);
      }
      $badge.text(count);
    } else {
      if ($badge.length > 0) {
        if ($(this).hasClass('search-vs-selected-toggle-btn')) {
            $('#selectedListingsCount').addClass('hidden');
          $badge.text(count);
        } else {
          $badge.remove();
        }
      }
    }
  });
}

export function toggleCartItem(listingKey) {
  var cart = _getCart();
  var added;

  if(listingKey) {
    if (cart[listingKey]) {
      delete cart[listingKey];
      added = false;
    } else {
      cart[listingKey] = 1;
      added = true;
    }

    // This logic could become very convoluted if we tried to unwind the logic just
    // above if the _saveCart call failed.  So just keeping it simple for now.
    if (!_saveCart(cart)) {
      added = false;
      $(`.select-listing-control[data-listing-key="${listingKey}"]`).prop("checked", false);
    } else {
      updateCartCount();
    }

    // This is to be used to communicate to Android native
    // that user has selected/deselected listings.
    nativeAppInterface.updateSelectedCart(Object.keys(cart).length);
  }
  return added;
}

export function selectAllCartAction(listingKeys) {
  var cart = _getCart();

  listingKeys.forEach(listingKey=>{
    cart[listingKey] = 1;
  });

  _saveCartForSelectAll(cart);
  updateCartCount();
}

export function deSelectAllCartAction(listingKeys) {
  var cart = _getCart();

  listingKeys.forEach(listingKey=>{
    if (cart[listingKey]) {
      delete cart[listingKey];
    } 
  });

  _saveCartForSelectAll(cart);
  updateCartCount();
}

function _saveCartForSelectAll(cart) {
  var maxCartItemsAllowed = 200;
  const validatedListings = getValidatedListings(cart, maxCartItemsAllowed);

  // Remove any potential undefined keys from the cart
  delete validatedListings[undefined];
  localStorage.setItem(sessionName, JSON.stringify(validatedListings));
}

function getValidatedListings(cart, maxCartItemsAllowed){
  const listings = Object.keys(cart);
  if (listings.length > maxCartItemsAllowed) {
      const excessListings = listings.slice(maxCartItemsAllowed);
      excessListings.forEach(key => {
          delete cart[key];
          $(`.select-listing-control[data-listing-key="${key}"]`).prop("checked", false);
      });
      alert(
        'You have reached the maximum number of selected listings (' + maxCartItemsAllowed + ').\n\n' +
        'You will need to remove some listings in order to add more.');
  }
  
  return cart;
}


function _getCart() {
  var cart = localStorage.getItem(sessionName);

  try{
    var parsedCart = JSON.parse(cart);
    // Remove any potential undefined keys from the cart
    delete parsedCart[undefined];
    return parsedCart;
  } catch (e) {
    cart = {};
    _saveCart(cart, true);
    return cart;
  }
}

function _saveCart(cart, force) {
  // 'force' allows us to bypass validation which would enter an infinite loop
  // in some cases.
  // This code is a bit "strained" (forced?), so a refactor may be in order.
  if (force || _validateCart(cart)) {
    // Remove any potential undefined keys from the cart
    delete cart[undefined];
    localStorage.setItem(sessionName, JSON.stringify(cart));
    _setSelectedCartCountCookie(Object.keys(cart).length)
    return true;
  }
  return false;
}

function _validateCart(cart) {
  // A cookie can store 4k of information.  We're being conservative and only allowing
  // a certain number of listings to be added to the cart.  When we go to a different
  // storage strategy for the cart, this limitation will be relaxed.
  var cartItemsAllowed = 200;

  if (Object.keys(cart).length <= cartItemsAllowed) {
    return true;
  }

  alert(
    'You have reached the maximum number of selected listings (' + cartItemsAllowed + ').\n\n' +
    'You will need to remove some listings in order to add more.');

  return false;
}

function _setSelectedCartCountCookie(count) {
  var currentUser = Flexmls.currentUser;
  var tech_id = currentUser.role === 'vow' ? currentUser.contact.Id : currentUser.agent_id;
  var cookieName = `selectedCartCount_${currentUser.role}_${tech_id}`;
  
  Cookies.set(cookieName, count, cookieOptions);
}

export function setupNewListings() {
  // on the listing detail page, check the box if this listing is selected
  var cart = _getCart();

  $('.selectListingsControl').each(function (index, el) {
    var checkbox = $(el);
    var listingId = $(checkbox).attr('data-listing-key');
    var checked = !!cart[listingId] ? 'checked' : false;
    $(checkbox).prop('checked', checked);
  });
}
