// !!!!!!!
// NOTE:  This code is no longer in use.  It is here to preserve the code history.
// The functionality for this is now defined in the STIMULUS select_listings_controller.js
// and select_listings_helpers.js.
// All references to the selectListing object have been removed from search.
// !!!!!!!


import Cookies from 'js-cookie';
import { nativeAppInterface } from './native_app_interface';

// This code supports selecting listings that are on a list results page,
// as well as selecting a list from its show page.

var selectListingsSelector = '.selectListingsControl',
    dataKey = 'data-listing-key',
    hostNameArray = window.location.hostname.split('.'),
    // This will produce the base domain name, like:  'flexmls.com' or 'fbsdata.com'.
    cookieDomain = hostNameArray[hostNameArray.length - 2] + '.' + hostNameArray[hostNameArray.length - 1],
    cookieName = 'selectedCart',
    cookieCurrentUser = 'selectedCartCurrentUser',
    cookieExpireDays = 365,
    cookieOptions = { expires: cookieExpireDays, domain: cookieDomain, path: '/' },

    clearCart = function () {
      // This is to be used to communicate to Android native
      // that user has deselected all selected listings.
      nativeAppInterface.updateSelectedCart(0);

      Cookies.remove(cookieName, cookieOptions);
      updateCartCount();
    },

    getCartCount = function () {
      var cart = _getCart();

      if (cart) {
        // Include an alternative for Object.keys to support older browsers, if needed.
        return Object.keys(cart).length;
      }
      return 0;
    },

    checkCurrentUser = function () {
      var currentUser = Flexmls.currentUser;
      var cookie = Cookies.get(cookieCurrentUser),
          name = currentUser.role === 'vow' ? currentUser.contact.DisplayName : currentUser.account.Name;

      if (cookie !== name) {
        clearCart();
        Cookies.set(cookieCurrentUser, name, cookieOptions);
      }
    },

    updateCartCount = function () {
      var count = getCartCount();

      $('.buttonCarts').each(function(){

        var $badge = $(this).find('.selectListingsBadge');

        if (count > 0) {
          if ($badge.length === 0) {
            $badge = $('<div>').addClass('selectListingsBadge badge');
            $(this).append($badge);
          }
          $badge.text(count);
        } else {
          if ($badge.length > 0) {
            $badge.remove();
          }
        }
      });
    },

    toggleCartItem = function (listingKey) {
      var cart = _getCart();
      var added;
      
      if(listingKey) {
        if (cart[listingKey]) {
          delete cart[listingKey];
          added = false;
        } else {
          cart[listingKey] = 1;
          added = true;
        }
        
        // This logic could become very convoluted if we tried to unwind the logic just
        // above if the _saveCart call failed.  So just keeping it simple for now.
        if (!_saveCart(cart)) {
          added = false;
        } else {
          updateCartCount();
        }

        // This is to be used to communicate to Android native
        // that user has selected/deselected listings.
        nativeAppInterface.updateSelectedCart(Object.keys(cart).length);
      }

      return added;
    },

    _getCart = function () {
      var cart = Cookies.get(cookieName);

      try{
        var parsedCart = JSON.parse(cart);
        // Remove any potential undefined keys from the cart
        delete parsedCart[undefined];
        return parsedCart;
      } catch (e) {
        cart = {};
        _saveCart(cart, true);
        return cart;
      }
    },

    _saveCart = function (cart, force) {
      // 'force' allows us to bypass validation which would enter an infinite loop
      // in some cases.
      // This code is a bit "strained" (forced?), so a refactor may be in order.
      if (force || _validateCart(cart)) {
        // Remove any potential undefined keys from the cart
        delete cart[undefined];
        Cookies.set(cookieName, JSON.stringify(cart), cookieOptions);
        return true;
      }
      return false;
    },

    _validateCart = function (cart) {
      // A cookie can store 4k of information.  We're being conservative and only allowing
      // a certain number of listings to be added to the cart.  When we go to a different
      // storage strategy for the cart, this limitation will be relaxed.
      var cartItemsAllowed = 100;

      if (Object.keys(cart).length <= cartItemsAllowed) {
        return true;
      }

      alert(
        'You have reached the maximum number of selected listings (100).\n\n' +
        'You will need to remove some listings in order to add more.');

      return false;
    },

    setupNewListings = function () {
      var cart = _getCart();

      $(selectListingsSelector).each(function (index, el) {
        var checkbox = $(el);
        var listingId = $(checkbox).attr(dataKey);
        var checked = !!cart[listingId] ? 'checked' : false;
        $(checkbox).prop('checked', checked);
      });
    };

function init() {


  // on the listing detail page, check the box if this listing is selected
  setupNewListings();

}

export let selectListings = {
  init: init,
  clearCart: clearCart,
  checkCurrentUser: checkCurrentUser,
  toggleCartItem: toggleCartItem,
  setupNewListings: setupNewListings,
  updateCartCount: updateCartCount
};
