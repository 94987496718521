import { Controller } from "stimulus";

export default class extends Controller {
  reportError(evt) {
    this.listingId = evt.target.dataset.listingId;
    showPageLoadingMsg();
    this.routeIntegrations(this.listingId);
  }

  routeIntegrations(listingId) {
    $.ajax({
      url: routes.integrations_report_violations_path(listingId),
      method: "GET",
      success: (response) => {
        var integration = response.integration;
        if (integration) {
          hidePageLoadingMsg();
          window.location.href = integration.url;
        } else {
          this.showViolationModal(listingId);
        }
      },
      error: function (err) {
        hidePageLoadingMsg();
        console.log(err);
      },
    });
  }

  showViolationModal(listingId) {
    $.ajax({
      url: routes.new_report_violation_path(listingId),
      method: "GET",
      success: function (data) {
        $("#reportViolationModal").replaceWith(data.html);
        $("#reportViolationModal").modal("show");
      },
      complete: hidePageLoadingMsg,
    });
  }
}
