import {addFields} from '../search/add_fields';
import {Basics} from '../search/basics';
import {searchFormEvents} from '../search/search_form_events';
import {searchFormHelpers} from '../search/search_form_helpers';
import {fieldEnhancements} from '../search/field_enhancements';
import {selectToButton} from '../search/select_to_button';
import {Field} from '../search/field';
import {Fields} from '../search/fields';
import LocationSearch from '../search/location_search';
import {SearchItemContainer} from '../search/search_item_container'

var locationFields = []; // hold a list of field names that should be grouped in the location section
var existingLocations; // holds locations that were part of the search criteria when the form first loads
var locationSearch;
var firstLoad = true; // some things (like event binding) only need to be done once, the on the first load
var filterChangedCallback;
var startWithFilterPanel = null;

function init(_existingLocations, _startWithFilterPanel = false, _filterChangedCallback) {

  locationFields = mapSupport.locationFields;
  existingLocations = _existingLocations;
  startWithFilterPanel = _startWithFilterPanel;
  window.dispatchEvent(searchFormEvents.filterFirstInitialized(startWithFilterPanel));

  Basics.previous_mls_ids = Basics.mls_id().val();

  enlivenateForm();
  addFields.init();

  
  // Moved this call out of bindEvents so, if bindEvents is called
  // again, this event isnt fired multiple times.
  // TODO:  Move this into the search_filters_controller as an event
  window.addEventListener('searchForm:fieldsReloaded', function(e) {
    if (e.detail)
      enlivenateForm(e.detail.existing_locations);
    else
      enlivenateForm();
  });


  handleiPhoneMultiselectBug();

  firstLoad = false;
}



function getAndAddFieldsToForm(fields) {

  var fieldStrings = fields.map(function(f) {
    return f.fieldStr;
  });

  var data = {
    _fields: fieldStrings.join(","),
    _mls_ids: (Basics.mls_id().val() || []).join(',')
  };

  var propertyData = (Basics.propertyTypeValues() !== null) ? Basics.propertyTypeValues() : Basics.propertyClassValues();

  data._property_types = propertyData.join(',');

  return $.ajax({
    url: routes.form_fields_path(),
    data: data,
    dataType: "html",
    success: function(html) {
      insertFieldIntoForm(html, fields);
      applyJsFieldEnhancements();
      
      //Check the checkbox to ensure its enabled
      var checkboxFieldId = $(html).find('[type=checkbox].filter_item_container').first().attr('id');
      if($('#' + checkboxFieldId).prop('checked') == false)
      {
        $('#' + checkboxFieldId).trigger('click');
      }
      
      //scroll to container
      var collapsibleElement = $('#' + checkboxFieldId).closest('.c-card');

      SearchItemContainer.expandContainer(collapsibleElement);

      var scrollTarget = collapsibleElement.outerHeight() + collapsibleElement.position().top;
      if (collapsibleElement.outerHeight() > $('#filterPanel').innerHeight()) {
        scrollTarget = collapsibleElement.position().top;
      }
      
      // TODO:  Test date exchange and relative time/direction functionality.  Remove these comments when done.
      //bindDateExchange();
      //bindRelativeTimeAndDirection();

      if (fields.length == 1) {
        $("input[type=checkbox][name='search_fields[" + fields[0].groupName + "." + fields[0].fieldStr.replaceAll('"','').replaceAll(' ','_') + "][value]']").trigger('click');
      }

      // There is no "scroll height reset" event.  So even though we added a new
      // Item to the content panel, the scroll bar doesnt know about it.  It's
      // current scrollHeight is the same as it was before we added this.
      // But if we wait a skosh, it will know, we can scroll and everybody is happy.
      setTimeout(function () {
        var scrollTarget = $('#filterPanel')[0].scrollHeight - collapsibleElement.outerHeight();
        $('#filterPanel').scrollTop(scrollTarget);
        SearchItemContainer.setContainerFocus(collapsibleElement,true);
      }, 500);
    }
  });
}

function insertFieldIntoForm(html, fields) {
  var field = fields[0];
  var $html = $(html);
  var isLocationField = (locationFields.indexOf(field.id) >= 0)

  var addTo = (isLocationField) ? $('#locationFields') : $('#added-fields');

  // If the field is a part of a boolean group, and part of that group has 
  // already been added to the form, then we need extract just the checkboxes
  // from the html and insert them into the right section.
  if (field.domain === "CustomFields") {
    var container = groupContainer(field.groupName);
    if (field.type === 'Boolean' && container !== null) {
      addTo = container.find('.controls');
      $html = $(html).find('.checkboxen');
    }
  }

  // TODO MWEB-2659: When the form is reloaded after changing the property type or mls, we
  // need to know which fields were manually added so they can be restored to the
  // form if they still apply. This currently works for regular fields but not for
  // boolean group fields. Those will be dropped.
  $html = $($html).addClass('userAddedField');

  if (isLocationField) {
    // Make sure this is above the drawing stuff
    $html.insertBefore("#drawingsSelectDiv");
  }
  else {
    if (addTo.find('#OpenHouses_filter_item_container'))
      $html.insertBefore('#OpenHouses_filter_item_container');
    else if (addTo.find('#TourOfHomes_filter_item_container'))
      $html.insertBefore('#TourOfHomes_filter_item_container');
    else
      addTo.append($html);
  }
}

function groupContainer(name) {
  var group;
  group = $('#added-fields').find("[data-group='" + name + "']");
  if (group.length > 0) {
    return group;
  } else {
    return null;
  }
}

function bindEvents() {
  if(!firstLoad){
    return;
  }

  $(document).off('change', '#added-fields');
  $(document).on('change', "#added-fields type:not(select), #added-fields input[type=checkbox]", function(e) {
    debugger;
    console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - change added-fields")
    fieldCheckboxChanged(e.target);
  });

  $(document).on('focus', '#locationFields input[type=text]', function(args) {
    debugger;
    if($(this).hasClass('select2-search__field')) { return; }
    var oldValue = $(this).val().trim();
    $(this).one('blur', function(args) {
      var newValue = $(this).val().trim();

      if(oldValue === newValue) {
        return;
      } else {
        $(this).closest('.c-card').find('.c-card-enable-summary').text(newValue);
        var fieldName = $(this).attr("id").replace(/search_fields_|_value/g, '');
        var newValuesArray = newValue.split(/\s*,\s*/);

        var placeholderText = $('#locationSearch').attr('data-placeholder-text');
        locationSearch = new LocationSearch($('#locationSearch'), {
          searchUrl: window.omniSearchUrl,
          changeCallback: searchFormHelpers.findCount,
          existingLocations: existingLocations,
          placeholderText: placeholderText,
          portalSlug: window.portal_slug,
          useHiddenInputs: false,
          suppressChangeEvents: true
        });

        if (oldValue.length > 0) {
          var oldValuesArray = oldValue.split(/\s*,\s*/);
          oldValuesArray.forEach(function(oldValue) {
            locationSearch.removeValue(fieldName + '_' + oldValue);
          });
        }

        newValuesArray.forEach(function(newValue) {
          locationSearch.selectValue(fieldName + '_' + newValue);
        });
        setTimeout(clearLocationSearch, 0);
        setTimeout(function() { locationSearch.resetPlaceholder(); }, 0);
      }
      var serializedForm = searchFormHelpers.formSerializer();
      reloadData(serializedForm);

    });
  });
  
  $(document).off('change', "#locationFields select, #locationFields input, #drawingsSelect");
  $(document).on('change', "#locationFields select, #locationFields input, #drawingsSelect", function() {

    debugger;
    if ($(this).hasClass('filter_item_container') || this.type == "text" || this.type == 'single-select') {
      return;
    }
    if ($(".select2-container--open").length > 0) {
      // Any time a select2 is open, we want to let the user
      // finish what they are doing.
      return;
    }
    var serializedForm = searchFormHelpers.formSerializer();
    reloadData(serializedForm);
  });

  $(document).on('keyup',  '.filter_item_container input[type=text]:not(.select-range-field input)', $.flexmlsUi.debounce(function(){
    console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - keyup")
    debugger;
    if ($($(this).closest('.c-card').find('input[type=checkbox].filter_item_container')[0]).prop('checked') == false) {
      SearchItemContainer.checkContainer($($(this).closest('.c-card')), true);
    }
  },200));

  $(document).off('keyup',  '.select-range-field input');
  $(document).on('keyup',  '.select-range-field input',  function() {
    debugger;
    console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - keyup select-range-field input")
    Fields.selectFromRange(this);
    var serializedForm = searchFormHelpers.formSerializer();
    reloadData(serializedForm);
  });

  $(document).off('change', '.select-range-field select');
  $(document).on('change', '.select-range-field select', function() {
    debugger;
    console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - change select-range-field select")
    Fields.rangeFromSelect(this); 
    updateListings()
  });

  // CONVERTED: This has been moved to search_filters stimulus controller
  $(document).off('change', 'select:not(#locationSearch,#drawingSelect,#templateSelect)');
  $(document).on('change', 'select:not(#locationSearch,#drawingsSelect,#templateSelect)', function(e) {
    debugger;
    console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - change templateSelect")

    selectChanged(e.target);
  });

  $(document).off('select2:close');
  $(document).on('select2:close', function(e) {
    debugger;
    console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - select2closed")

    select2Changed(e.target);
  });

  $(document).off('addFields:add');
  $(document).on('addFields:add', function(e, fields) {
    console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - addFields:add")

    getAndAddFieldsToForm(fields);
  });

  // Setup  links under gear dropdown
  $(document).off('click', '.removeFieldLink');
  $(document).on('click', '.removeFieldLink', function(e) {
    debugger;
    console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - click removeFieldLink")

    e.preventDefault();
    removeField(e.target);
  });

  $(document).off('click', 'a.search-operator');
  $(document).on('click', 'a.search-operator', function(e) {
    debugger;
    console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - click a.serch-operator")
    searchOperatorChanged(e);
  });

  $(document).off('click', 'a.remove-group');
  $(document).on('click', 'a.remove-group', Fields.removeGroup);  // for boolean groups


  $('#filterPanelContent').one('DOMNodeInserted', function(event){
    debugger;
    console.log("WHY IS THIS HERE!  DO WE NEED THIS? filterPanelContent DOMNodeInserted")

      // The date exchange icon and the relative time and direction are handled
      // differently and cannot be bound at the document level.  When we load the
      // panel and insert the html from the server, those items are not recognized
      // yet.  We have to wait for the DOMNodeInserted event to fire.  At this
      // point, jquery can see them and we can bind them.  We only have to do this
      // 1 time.  If new dates are added later, these functions will get called
      // from the add event.
      bindDateExchange();
      bindRelativeTimeAndDirection();
  });

  $(document).on('shown.bs.collapse', function(e) {
    debugger;
    console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - container collapse/uncollapse")
    // The collapsible container has fired the 'uncollapse' event
    var collapsibleElement = $($(e.target).closest('.c-card')[0]);
    // Make sure the content is visible
    SearchItemContainer.scrollToContainer($(collapsibleElement));
    // Focus on the first element in the container
    SearchItemContainer.setContainerFocus($(collapsibleElement,false));
  });

  $(document).off('change', ':input[type=checkbox].filter_item_container');
  $(document).on('change', ':input[type=checkbox].filter_item_container', function(e) {
    console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - filter checkbox changed")

    debugger;
    filterCheckboxChanged(e.target);
  });
}

function fieldCheckboxChanged(target) {
    var changedItem = $(target);
    if (changedItem.hasClass('filter_item_container')) {
      return;
    }

    // If the item's container was not checked, check it now
    SearchItemContainer.checkContainer(changedItem.closest('.c-card'), true);

    if ($(".select2-container--open").length > 0) {
      // Any time a select2 is open, we want to let the user
      // finish what they are doing.
      return;
    }

    if (changedItem.is("input[type=checkbox]")) {
      SearchItemContainer.updateBooleanGroupSummary(changedItem);
    } else if (changedItem.is("input[type=select]") || changedItem.is["select"]) {
      SearchItemContainer.updateSelectSummary(e.target);
    } else {
      console.log("Summary needed for item type: " + changedItem.prop('nodeName'));
    }
    var serializedForm = searchFormHelpers.formSerializer();
    reloadData(serializedForm);
}

function bindDateExchange() {
  $('.exchange_icon:not(.bound)').addClass('bound').click(function() {
    
    console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - date exchange")


    // If the item's container was not checked, check it now
    SearchItemContainer.checkContainer($($(this).closest('.c-card')), true);

    searchFormHelpers.toggleDateRelativity($(this));
    //searchFormHelpers.findCount();
    var isChangedToDateRange = ($('#'+ $(this).attr('data-field-id').replace(/\"|\./g, '').replace(' ','_') + "_relative_container").css('display') == 'none')

    if (isChangedToDateRange) {
      SearchItemContainer.updateSplitControlsSummary($($(this).parent().find(".controls.split")[0]));
    } else {
      SearchItemContainer.updateRelativeDateSummary($('#'+ $(this).attr('data-field-id').replace(/\"|\./g, '').replace(' ','_') + "_relative_container"));
    }

    updateListings(isChangedToDateRange);
  });

}
function bindRelativeTimeAndDirection() {
  $('.relative_time:not(.bound), .relative_direction:not(.bound)').addClass('bound').click(function() {

    console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - relative time and direction for dates")

    // If the item's container was not checked, check it now
    SearchItemContainer.checkContainer($($(this).closest('.c-card')), true);

    var vals = $(this).hasClass("relative_time") ? ['days','months','years'] : ['back','ahead'] ;
    var currentVal = $(this).text();
    for (var x=0; x < vals.length; x++) {
      if (vals[x] == currentVal) {
        var new_val = x == vals.length-1 ? vals[0] : vals[x+1] ;
        $(this).text(new_val);
        $( "input[name='" + $(this).data("for") + "']").val(new_val);
      }
    }
    SearchItemContainer.updateRelativeDateSummary($($(this).closest('.contains-date')[0]));

    updateListings();
  });
}

function updateStatusDates(myStatuses) {
  var statusDateFound = [];
  var statusDateNotFound = [];
  if (myStatuses == null)
    myStatuses = [];
  for(var name in status_map) {
    if (myStatuses.includes(name)) {
      // A date has been mapped to this name.
      if (!statusDateFound.includes(status_map[name])) {
        // We don't have this date yet.
        // If it was excluded because another name used the same date
        // make sure we remove it from the 'not found' list.
        var index = statusDateNotFound.indexOf(status_map[name]);
        if (index >= 0) { statusDateNotFound.splice(index, 1); }
        // Add it to our found list.
        statusDateFound.push(status_map[name]);
      }
    } else {
      // The date (by this name) was not found.
      // Check to see if if was found by another name.
      var index = statusDateFound.indexOf(status_map[name]);
      if (index < 0) {
        // Not found.  Do we already have in in the not found list?
        index = statusDateNotFound.indexOf(status_map[name]);
        if (index < 0) {statusDateNotFound.push(status_map[name])}
      }
    }
  }

  // Now that we know what we want to show and what we dont...
  statusDateNotFound.forEach(dateField => {
    // Hide this date field and disable all its inputs so they are not included
    // when the form is serialized.
    $("#" + dateField + "_container").css("display","none");
    $("#" + dateField + "_container").closest(".c-card").css("display","none");
    // Tell the form not to serialize these elementss
    $("#" + dateField + "_container :input").addClass("dont_serialize");
  });

  statusDateFound.forEach(dateField => {
    // Show the date if it is not already visible
    if ($("#" + dateField + "_container").closest(".c-card").css("display") == "none") {
      // Remove the 'display: none' from the css
      $("#" + dateField + "_container").removeAttr("style");
      $("#" + dateField + "_container").closest(".c-card").removeAttr("style");
      $("#" + dateField + "_container :input").removeClass("dont_serialize");
      // Tell the form to serialize these elements
      $("#" + dateField + "-checkbox").prop("checked","checked");
      // If the relative date field is visible...
      if ($("#" + dateField + "_relative_container").css("display") != "none") {
        // Enable the relative date input
        $("#search_fields_" + dateField + "_relative_value").removeAttr("disabled");
        $("#search_fields_" + dateField + "_relative_time").removeAttr("disabled");
        $("#search_fields_" + dateField + "_relative_direction").removeAttr("disabled");
      } else {
        // Else enable the "between" input fields
        $("#search_fields_" + dateField + "_left").removeAttr("disabled");
        $("#search_fields_" + dateField + "_right").removeAttr("disabled");
      }
    }
  });
}



function updateListings(isChangedToDateRange = false, isBasicsChange = false) {
  var serializedForm = searchFormHelpers.formSerializer();
  if (isChangedToDateRange == true) {
    // The false tells reload data not to dispatch the form change event.
    reloadData(searializedForm, isBasicsChanging, false);
    // We dont have date information filled in yet for the date range.
    // We need to get it from the server now that the form has the
    // date fields we want then resend it so the filter reflects the
    // dates filled in by the server.
    updateListings();
  } else {
    reloadData(searializedForm, isBasicsChanging);
  }
}

// This runs when the form is initially loaded and after all the fields are reloaded
function enlivenateForm(_existing_locations) {
  setupLocationSearch(_existing_locations);
  
  applyJsFieldEnhancements();

  fixMobileDropdowns();

  Basics.update_status_date_fields();

  // FLEX-3837 - Only show the status selector if there is more than one status
  // available to select.
  if (Basics.status_select().find('option').length === 1) {
    Basics.status_select().parents('.fieldWrapper').hide();
  } 
}

function enlivenateBasics() {
  fieldEnhancements.multiSelectDropdowns();
  Basics.update_status_date_fields();
  fixMobileDropdowns();
  // FLEX-3837 - Only show the status selector if there is more than one status
  // available to select.
  if (Basics.status_select().find('option').length === 1) {
    Basics.status_select().parents('.fieldWrapper').hide();
  } 
}

function setupLocationSearch(_existing_locations) {
  if (_existing_locations) {
    existingLocations = _existing_locations;
  }
  var placeholderText = $('#locationSearch').attr('data-placeholder-text');
  locationSearch = new LocationSearch($('#locationSearch'), {
    searchUrl: window.omniSearchUrl,
    changeCallback: searchFormHelpers.findCount,
    existingLocations: existingLocations,
    placeholderText: placeholderText,
    portalSlug: window.portal_slug,
    useHiddenInputs: false,
    suppressChangeEvents: true
  });

  // Now that locationSearch has loaded the existing locations, clear the 
  // pills and show the placeholder text.
  setTimeout(clearLocationSearch, 0);

  $('#locationSearch').on('locationSearch:selecting',   handleLocationSearchSelecting  );
  
  $('#locationSearch').on('select2:select', function(){
    setTimeout(clearLocationSearch, 0);
  });

  $('#locationSearch').on('locationSearch:unselecting', handleLocationSearchUnselecting);

  syncIndividualLocationFieldsToMainLocationField()
}

function syncIndividualLocationFieldsToMainLocationField() {
  
  $(document).on('select2:select', '#locationFields select', function(args) {
    if(typeof args.params !== 'undefined'){
      var fieldName = args.target.dataset.fieldName;
      var label = args.params.data.id;
      var value = fieldName + '_' + label;
      locationSearch.selectValue( value, label );
      setTimeout(clearLocationSearch, 0);
    }
  });
  
  $(document).on('select2:unselect', '#locationFields select', function(args) {
    var fieldName = args.target.dataset.fieldName;
    var label = args.params.data.id;
    var value = fieldName + '_' + label;
    locationSearch.removeValue( value );

    setTimeout(function() { locationSearch.resetPlaceholder(); }, 0);      
  }); 
  
  $(document).on('focus', '#locationFields input', function(args) {
    if($(this).hasClass('select2-search__field')) { return; }
    var oldValue = $(this).val().trim();
    $(this).one('blur', function(args) {
      var newValue = $(this).val().trim();

      if(oldValue === newValue) {
        return;
      } else {
        $(this).closest('.c-card').find('.c-card-enable-summary').text(newValue);
        var fieldName = $(this).attr("id").replace(/search_fields_|_value/g, '');
        var oldValuesArray = oldValue.split(/\s*,\s*/);
        var newValuesArray = newValue.split(/\s*,\s*/);

        oldValuesArray.forEach(function(oldValue) {
          locationSearch.removeValue(fieldName + '_' + oldValue);
        });

        newValuesArray.forEach(function(newValue) {
          locationSearch.selectValue(fieldName + '_' + newValue);
        });
        setTimeout(clearLocationSearch, 0);
        setTimeout(function() { locationSearch.resetPlaceholder(); }, 0);      
      }

    });
  });
}

function clearLocationSearch() {
  // Remove the little selection pills. Even though they are hidden, you
  // can still delete them with the backspace key.
  $('.filters-location-search .select2-selection__choice').remove();

  // Select2 still thinks that there are values selected, so it won't show
  // the placeholder. We need to manually add it back in.
  locationSearch.resetPlaceholder();    
}

function handleLocationSearchSelecting(e, fieldName, addedValue, sparkQl) {
  console.log("WHY IS THIS HERE!  SHOULD BE HANDLED ALREADY IN STIMULS - location search selecting")

  getOrAddField(fieldName).done(function(input) {

    var existingValue = input.val();
    if(input.is('select')){
      let newValues = (existingValue === null) ? [addedValue] : existingValue.concat([addedValue]);
      selectDropdownValues(input, newValues);
      SearchItemContainer.updateSelectSummary(input);
    } else if (input.is('input')) {
      let newValues = '';
      if (existingValue === '') {
        newValues = addedValue;
      } else {
        var valuesArray = existingValue.split(/\s*,\s*/);
        valuesArray.push(addedValue);
        newValues = valuesArray.join(', ');
      }
      input.val(newValues).trigger('change');
      SearchItemContainer.checkContainer(input.closest('.c-card'),true);
      input.closest('.c-card').find('.c-card-enable-summary').text(input.val());
    }
  });
}

function selectDropdownValues(dropdown, values) {
  // Create <option>s for all the selected values
  values.forEach(function(value){
    if (dropdown.find("option[value='" + value + "']").length === 0) {
      // Create a DOM Option and pre-select by default
      var newOption = new Option(value, value, true, true);
      dropdown.append(newOption);
    }
  });

  dropdown.val(values).trigger('change');
}

function getOrAddField(originalFieldName) {
  var deferred = $.Deferred();

  var fieldName = originalFieldName.replace(/"/g, '').replace(/\s/g, '_');

  var inputName = "search_fields[" + fieldName + "][value][]";
  var fieldSelector = "#search_fields_" + fieldName.replace(/\./g, '\\.') + "_value";
  var input = $(fieldSelector);

  if( input.length ) {
    deferred.resolve(input);
  } else {
    var data = {};

    if(originalFieldName.indexOf('.') > 0) {
      data.domain = 'CustomFields';
      data.groupName = originalFieldName.split('.')[0].replace(/"/g, '');
      data.id = originalFieldName.split('.')[1].replace(/"/g, '');
    } else {
      data.id = fieldName;
    }

    var field = new Field(data);
    getAndAddFieldsToForm([field]).done(function() {
      deferred.resolve( $(fieldSelector) );
    });
  }
  return deferred;
}

function handleLocationSearchUnselecting(e, field, removedValue) {
  var input = $("#search_fields_" + field + "_value");
  var oldValue = input.val();

  if(input.is('select')){
    oldValue.splice(oldValue.indexOf(removedValue), 1);
    input.val(oldValue);
    SearchItemContainer.updateSelectSummary(input);
    if (input.find('option:selected').length == 0) {
      SearchItemContainer.checkContainer(input.closest('.c-card'), false);
    }
  } else if (input.is('input')) {
    var valuesArray = oldValue.split(/\s*,\s*/);
    valuesArray.splice(valuesArray.indexOf(removedValue), 1);
    input.val(valuesArray.join(', '));
    input.closest('.c-card').find('.c-card-enable-summary').text(input.val());
    if (valuesArray.length == 0) {
      SearchItemContainer.checkContainer(input.closest('.c-card'), false);
    }
  }
  input.trigger('change');
}

// This is called by by enlivenateForm and when a single field or single group is added
function applyJsFieldEnhancements() {

  fieldEnhancements.multiSelectDropdowns();
  fieldEnhancements.ajaxFieldLists();

  // Setup Boolean Groups
  selectToButton('.boolean-group-operator-select');

  var isIOS = /(iPod|iPhone|iPad)/.test(navigator.userAgent)

  // Setup Date fields
  if (Modernizr.touch && Modernizr.inputtypes.date && isIOS) {
    $('.date_field').each(function() {
      var $this = $(this)
      var newVal = $this.val();
      if (/\d{2}\/\d{2}\/\d{4}/.test(newVal)) {
        var splits = newVal.split('/') // [month, day, year]
        newVal = splits[2]+'-'+splits[0]+'-'+splits[1];
      }
      $this.replaceWith($this.clone().attr('type','date').val(newVal));
    });
  } else {
    $(".date_field").datepicker({format:"mm/dd/yyyy"}).on('changeDate', function(ev){$(this).blur();$(this).datepicker('hide');});
    if (Modernizr.touch)
      $(".date_field").attr("readonly", true);
  }

  // Setup field options dropdown (gear icon)
  $('.dropdown-toggle').dropdown();

}

function handleiPhoneMultiselectBug() {
  // MOBILE-642: hack for iPhone 7 multiselect bug
  // http://stackoverflow.com/questions/20039194/multiple-select-in-safari-ios-7
  // When we leave our select options, select the ones we want and rewrite our options
  if (navigator.userAgent.match(/(iPhone);.*CPU.*OS 7_\d/i)){
    $('select[multiple]').each(function(){
      var select = $(this).on({
        "focusout": function(){
          var values = select.val() || [];
          setTimeout(function(){
            select.val(values.length ? values : ['']).change();
          }, 800);
        }
      });

      var firstOption = '<option value="" disabled="disabled"';
      firstOption += (select.val() || []).length > 0 ? '' : ' selected="selected"';
      firstOption += '>&laquo; Select ' + (select.attr('title') || 'Options') + ' &raquo;';
      firstOption += '</option>';
      select.prepend(firstOption);
    });
  }

}

function fixMobileDropdowns() {
  // Fix for dropdowns on mobile devices
  // https://github.com/twitter/bootstrap/issues/4550#issuecomment-10653502
  $('body').on('touchstart.dropdown', '.dropdown-menu', function (e) {
      e.stopPropagation();
  });

  if (Modernizr.touch) {
    var $body = $('body');
    var $inputs = $('#list_container_form').find(':input');

    $inputs.on('focus', function(e) {
      $body.addClass('fixed-fix');
    });

    $inputs.on('blur', function(e) {
      $body.removeClass('fixed-fix');
      setTimeout(function(){
        window.scrollTo(0, window.scrollY)
      }, 0);
    });

    setTimeout(function(){
      window.scrollTo(0,1);
    }, 20)
  }    
}

function reloadData(serializedForm = null, isBasicsChange = false, dispatchTheEvent = true) {
  var post_data = "";
  var user_added_fields = [];

  // Close all dropdowns so they don't get orphaned when the selects are replaced.
  setTimeout(function() {
    $('.select2-hidden-accessible').select2('close');
  }, 1);

  $('a.view-results').text($('a.view-results').attr('data-loading-text'));
  
  $('.userAddedField').each(function() {
    user_added_fields.push($(this).attr('data-field'));
  });

  if (user_added_fields.length) {
    post_data += "_user_added_fields=" + user_added_fields + "&";
  }

  var previousMlsIds = (Basics.previous_mls_ids != null) ? Basics.previous_mls_ids.join(',') : '';
  post_data += "_previous_mls_ids=" + previousMlsIds + "&";
 
  post_data += (serializedForm != null) ? serializedForm : searchFormHelpers.formSerializer();
  
  return $.ajax({
    url: $('.filterModalLink').attr('data-filter-url'),
    type: 'POST', // POST is needed here because of the amount of data that is being sent. A GET can result in a 414 URI Too Long Error.
    data: post_data,
    success: function(data) {
      if (isBasicsChange) {
        // If the basics fields caused the update, we need make sure that
        // the basics fields need to be updated.  Pull them from the form.
        // We dont want to replace the entire form!  Just the Basics.
        Basics.previous_mls_ids = Basics.mls_id().val();
        var formString = data.fields;
        var basicsContent = formString.substring(formString.indexOf('<div id="basics-content"'));
        basicsContent = basicsContent.substring(0, basicsContent.indexOf('<div id="added-fields"'));
        $("#basics-content").replaceWith(basicsContent);
        enlivenateBasics();
      }

      searchFormHelpers.updateResultsButton(data.count)

      if ($('#viewResultsLink').length > 0) {
        $('#viewResultsLink')[0].childNodes[2].textContent = ' ' + data.message + ' ';
      }
      window.dispatchEvent(searchFormEvents.filterChange(data.filter));
      delete(data.fields);
      if (dispatchTheEvent) {
        dispatchEvent(new CustomEvent('filterForm:reloaded', {detail: { count: data.count, expressions: data.expressions }}));
      }
    },
    error: function() {
      return alertModal('Sorry, there was an error updating the form.');
    }
  });
}

function initAddFields() {
  addFields.init();
}

function setFilterChangedCallback(_filterChangedCallback) {
  filterChangedCallback = _filterChangedCallback;
  // Bind the events using this as the callback function
  bindEvents();
}

function summarizeBooleanOperator(item) {
  let $parent = item.parents('.control-group');
  if ($parent.hasClass('op-not')) {
    return "NOT: ";
  } else if ($parent.hasClass('op-or')) {
    return "OR: ";
  }
  return "";
}

function handleResetShapesClick(e) {
  e.preventDefault();
  $("#drawingsSelect").empty().trigger("change");
  $('div.drawn-shapes-prompt').hide();
}

function selectChanged(target) {
    // If the item's container was not checked, check it now
    SearchItemContainer.checkContainer($($(target).closest('.c-card')), ($(target).find('option:selected').length > 0));

    if ($(".select2-container--open").length > 0) {
      // Any time a select2 is open, we want to let the user
      // finish what they are doing.
      return;
    }
    if (!$(target).hasClass('boolean-group-operator-select')) {
      SearchItemContainer.updateSelectSummary(target);
    }
    if (Basics.ids.includes(target.id)) {
      if (target.id == 'search_fields_MlsStatus_value' || target.id == 'search_fields_StandardStatus_value') {
        var myStatuses = $(target).val();
        updateStatusDates(myStatuses);
      } else if (target.id == 'search_fields_MlsId_value') {
        // This is the special case where we really do want to reload the entire form.
        Fields.reload(function (data) {
          filterChangedCallback(data.count, data.expressions);
          $('.c-card-group').flexCardGroup();
          bindDateExchange();
          bindRelativeTimeAndDirection();  
        });
        return;
      }

    }
    var serializedForm = searchFormHelpers.formSerializer();
    reloadData(serializedForm);
}

function select2Changed(target) {
    if ($(".select2-container--open").length > 0) {
      // Any time a select2 is open, we want to let the user
      // finish what they are doing.
      return;
    }
    SearchItemContainer.updateSelectSummary(target);
    if (Basics.ids.includes(target.id)) {
      if (target.id == 'search_fields_MlsStatus_value' || target.id == 'search_fields_StandardStatus_value') {
        var myStatuses = $(target).val();
        updateStatusDates(myStatuses);
      } else if (target.id == 'search_fields_MlsId_value') {
        // This is the special case where we really do want to reload the entire form.
        Fields.reload(function (data) {
          if (filterChangedCallback)
            filterChangedCallback(data.count, data.expressions);
          $('.c-card-group').flexCardGroup();
          bindDateExchange();
          bindRelativeTimeAndDirection();  
        });
        if (filterChangedCallback)
          return;
      }
    }
    var serializedForm = searchFormHelpers.formSerializer();
    reloadData(serializedForm);
}

function removeField(target) {
    
    var id = $(target).attr('id'); 
    var checkboxSelected = $(Fields.getFieldCheckboxSelector(id))[0].checked;

    Fields.remove(id);
    if( checkboxSelected )
    {
      var serializedForm = searchFormHelpers.formSerializer();
      reloadData(serializedForm);
    }
}

function searchOperatorChanged(e) {
    var $target = $(e.target);
    var $container = $(e.target).closest('.c-card');
    // If the item's container was not checked, check it now
    SearchItemContainer.checkContainer($container, true);

    let summary = $container.find('.c-card-enable-summary').html();

    let $parent = $target.parents('.control-group');
    if ($parent.hasClass('op-not') && summary.startsWith('NOT: ')) {
      summary = summary.substring(5);
    } else if ($parent.hasClass('op-or') && summary.startsWith('OR: ')) {
      summary = summary.substring(4);
    }
    if (summary.length > 0) {
      let newValue = $target.attr('data-operator')
      if (newValue != 'and') {
        summary = newValue.toUpperCase() + ': ' + summary
      }
    }
    $container.find('.c-card-enable-summary').html(summary);

    Fields.setOperator(e);
    var serializedForm = searchFormHelpers.formSerializer();
    reloadData(serializedForm);
}

function filterCheckboxChanged(target) {
    var collapsibleElement = $($(target).closest('.c-card')[0]);
    
    if ($(target).prop("checked") == true) {
      // Remove class telling form not to serialize these elements
      collapsibleElement.find('.c-collapsible__content.filter_item_container').find('*').removeClass('dont_serialize');
      // Make sure the summary text is not grayed out
      collapsibleElement.find('.c-card-enable-summary').css('color', '#0077d9');
      SearchItemContainer.expandContainer(collapsibleElement);
      // Need to give the browser some time to realize the scroll height has changed.
      setTimeout(function () {
        // Now we can scroll to the target if we need to
        SearchItemContainer.scrollToContainer(collapsibleElement);
        // And set the focus to on the first element
        SearchItemContainer.setContainerFocus(collapsibleElement,false);
      }, 200);
    } else {
      // Tell the form not to serilize elements in this container
      collapsibleElement.find('.c-collapsible__content.filter_item_container').find('*').addClass('dont_serialize');
      // Gray out the summary text
      collapsibleElement.find('.c-card-enable-summary').css('color', '#a6a6a6');
    }

    var serializedForm = searchFormHelpers.formSerializer();
    reloadData(serializedForm);
}

export var searchFormFlex = {
  init: init,
  enlivenateForm: enlivenateForm,
  initAddFields: initAddFields,
  setFilterChangedCallback: setFilterChangedCallback,
  bindDateExchange: bindDateExchange,
  bindRelativeTimeAndDirection: bindRelativeTimeAndDirection,
  handleResetShapesClick: handleResetShapesClick,
  startWithFilterPanel: startWithFilterPanel,
  selectChanged: selectChanged,
  select2Changed: select2Changed,
  removeField: removeField,
  searchOperatorChanged: searchOperatorChanged,
  filterCheckboxChanged: filterCheckboxChanged,
  updateStatusDates: updateStatusDates,
  enlivenateBasics: enlivenateBasics,
  getAndAddFieldsToForm: getAndAddFieldsToForm,
  fieldCheckboxChanged: fieldCheckboxChanged,
  reloadData: reloadData
};
