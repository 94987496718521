import { Controller } from "stimulus";

export default class extends Controller {

  changeTemplate(evt) {
    const selectedOption = evt.target.options[evt.target.selectedIndex];
    if (selectedOption) {
        const newParamValue = $(selectedOption).val();
        const currentPath = window.location.pathname;

        // Look for the id in the current path...
        const regex = /\/quick_searches\/\d{26}/g;

        // ...and replace it with the id associated with the selected template
        const newPath = currentPath.replace(regex,'/quick_searches/' + newParamValue);

        window.location.href = newPath;
    }
  }
}