import OverlayShape from './overlay_shape';

function Overlay(data) {
  var self = this;

  this.Id = data.Id;
  this.Shapes = [];

  data.Geometries.forEach(function(shape) {
    shape.OverlayId = self.Id;
    self.Shapes.push(new OverlayShape(shape));
  }); 
}

Overlay.prototype = {
  constructor: Overlay

};

export default Overlay;
