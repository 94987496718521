/*

  Adds a clickable ellipsis to a multi-line block of text. 

  Usage:
    1. With css, set a fixed height on the text container and set overflow: hidden.
    2. Pass a css selector to ellipsizeTextBox()

 */

export function ellipsizeTextBox(selector) {
  var $element = $(selector);

  var originalText = $element.text();
  var wordArray = $element.text().split(' ');
  var numberOfWords = wordArray.length;

  var clampedHeight = $element.height();
  var fullHeight = $element.css({'height': 'auto', 'max-height': 'none'}).height();

  // A while loop would work here, but if something goes wrong we might get stuck.
  for (var i = 0; i < numberOfWords; i++) {
    if($element.height() > clampedHeight) {
      var ellipsis = '... <a href="#" class="textBoxEllipsis">See More</a>';
      wordArray.pop();
      $element.html(wordArray.join(' ') + ellipsis);
    } else {
      break;
    }
  }

  // In case the clampedHeight doesn't exactly match to new auto height,
  // set the height back to the fixed clampedHeight to prevent small
  // jumps in the height of the text block.
  $element.css('height', clampedHeight);

  $('.textBoxEllipsis').click(function(e){
    e.preventDefault();
    $(this).hide();

    $element.css({height: $element.height(), overflow: 'hidden'} );
    $element.text(originalText);
    $element.animate({height: fullHeight}, 300);
  });

}
