import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $('#sharableLinkText').select();
  }

  shareByEmail(event) {
    event.preventDefault();
    window.location.href = $(event.target).attr('href');
    $('#fastModal').modal('hide');
  }
}