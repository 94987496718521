import { Controller } from "stimulus";
import axios from 'axios';
import {setState} from '../search_results/state'

export default class extends Controller {

  isConnected = false;
  async connect() {
    if (this.isConnected) {
        return;
    }
    this.isConnected = true;

    console.log("loading sorts")
    await axios.get(routes.my_sorts_path(), {
      withCredentials: true,
    })
    .then((response) => {
      let data = response.data;
      let mySortElement = $(this.element);

      if(data.length > 0){
        var selectedId = mySortElement.find('.selectedSort').attr('data-sort-id');
        var items = [];

        // This may be a little dumb. If a custom sort was previously selected,
        // then there should already be an option for it in the select. If we
        // just add the other custom sorts below it, then it could be out of order.
        // So instead, we can delete it, and then add it back in the right order
        // as a selected option. Maybe no one cares about the order, but that's
        // why I said this may be a little dumb.
        if(mySortElement.find('.customSortLink').length > 0){
          mySortElement.find('.customSortLink').closest('li').remove();
        }

        data.forEach(function(sort) {
          let selectedClass = (sort.Id === selectedId) ? 'selectedSort' : '';
          let item = '<li>';
            item +=   `<a href="#" data-sort-id="${sort.Id}" class="sort-link sortLink customSortLink ${selectedClass}"               <a href="#" data-sort-id="<%= s.Id %>" class="sort-link sortLink <%= 'selectedSort' if s.Id == current_sort.id %>" data-action="click->sort#sortSelected">`;
            item +=     '<span class="flexicon flexicon-check selected-sort-check"></span>';
            item +=     `<div class="sort-link-label">${sort.Name}</div>`;
            item +=   '</a>';
            item += '</li>';

          items.push(item);
        })

        mySortElement.find('.customSortLabel').last().after(items);
      } else if (mySortElement.find('.customSortLink').length === 0){
        mySortElement.find('.customSortLabel').remove();
      }
    })
    .catch((e) => {
      console.error( "The custom sorts could not be loaded.", e );
      if (mySortElement.find('.customSortLink').length === 0){
        mySortElement.find('.customSortLabel').remove();
      }
    })
  }

  sortSelected(evt) {
    evt.preventDefault();
    // This will fire the sortChanged action through stateChanged:sortId event
    setState('sortId', $(evt.currentTarget).attr('data-sort-id'));
  }

  sortChanged(data) {
    $('.sortLink').removeClass('selectedSort');
    $('.sortLink[data-sort-id="' + data.detail.newValue + '"]').addClass('selectedSort');
  }
}