import { Controller } from "stimulus"
import {ContactsDropdown} from '../contacts_dropdown'
import {goBackOneLevel, stepBack} from '../helpers/ldp_navigation_helpers'
import {getState} from '../search_results/state'
import Filter from '../filter'

export default class extends Controller {

  connect() {
    window.addEventListener('popstate', this.handlePopState.bind(this))
    let sessionData = JSON.parse(sessionStorage.getItem('listingsSpaHistory'))
    if (sessionData === null) {
      history.pushState({ action: 'none', currentUrl: window.location.href }, '', window.location.href)
    } else {
      if (history.state.turbo) {
        // We reloaded but turbo overwrote our last state.
        history.replaceState(sessionData,'', window.location.href)
      }
      // Use the session data to reload the page.
      switch(sessionData.action) {
        case 'none': {
          break
        }
        case 'ldpReport': {
          $('#priorityOverlay')[0].src = sessionData.reportPageUrl
          $('c-body').addClass('with-ldp')
          break
        }
        case 'mainLdp': {
          $('#priorityOverlay')[0].src = sessionData.itemUrl
          $('c-body').addClass('with-ldp')
          break
        }
      }
    }
  }


  disconnect() {
    window.removeEventListener('popstate', this.handlePopState)
  }

  handlePopState(event) {
    const state = history.state;
    // If we are in a real modal, close it.
    $('#fastModal').modal('hide')
  
    if (state) {
      if (state.turbo) {
        // Our states are all used up.  This is a state pushed onto the stack by turbo.
        // It causes us to have to hit the back button twice.  Unless...
        sessionStorage.removeItem('listingsSpaHistory')
        history.go(-1)
      } else {
        if (history.state.hasOwnProperty('action')) {
          switch(history.state.action) {
            case 'none': {
              sessionStorage.removeItem('listingsSpaHistory')
              // we are back at the main page.  Just make sure the ldp modal is closed.
              $('#priorityOverlay')[0].src = "";
              $('#priorityOverlay').html('');
              $('c-body').removeClass('with-ldp')
              break;
            }
            case 'ldpReport': {
              // We came back to an LDP report
              // Dont push the state again though
              $('#priorityOverlay')[0].src = history.state.reportPageUrl;
              sessionStorage.setItem('listingsSpaHistory', JSON.stringify(history.state))
              $('c-body').addClass('with-ldp')
              break;
            }
            case 'mainLdp': {
              // We came back to an LDP report
              // Dont push the state again though
              $('#priorityOverlay')[0].src = history.state.itemUrl;
              sessionStorage.setItem('listingsSpaHistory', JSON.stringify(history.state))
              break;
            }
            default: {
              console.log("Unhandled action in history.state: ", history.state)
            }
          }
        } else {
          // Check the overlay to see how many layers we have to go.
          let results = stepBack(new URL($('#priorityOverlay')[0].src).pathname, 'listing_detail')
          goBackOneLevel($('#priorityOverlay'))
        }
      }
    } else {
      // If we are here something happened to our history.  Odds are we are at our starting point.
      // Lets make that assumption and make sure we are in that state.  You cant go wrong by starting fresh.
      sessionStorage.removeItem('listingsSpaHistory')
      $('#priorityOverlay')[0].src = "";
      $('#priorityOverlay').html('');
      $('c-body').removeClass('with-ldp')
    }
  }

  // From url_updater.  Adjusted to adjust our history settings
  // TODO: Pretty sure we dont want to adjust the URL when we are
  // in flagship.  Will need to handle that.  The list/summary/map
  // views look at the current URL to decide what to load.  Those
  // will need special attention when in flagship too.
  filterChanged(data) {
    let currentFilter = getState('filter')
    if (currentFilter.equalToOriginal($('#datasource-content').length === 0)) {
      this.deleteQueryParam('_filter')
    } else {
      this.setQueryParam('_filter', currentFilter);
    }
  }

  sortIdChanged(data) {
    this.setQueryParam('sort_id', data.detail.newValue);
  }

  userViewIdChanged(data){
    this.setQueryParam('view_id', data.detail.newValue);
  }

  setQueryParam(name, value) {
    var newUrl = new URL(window.location.href);
    newUrl.searchParams.set(name,value);
    this.updateUrl(newUrl.href);
  }

  updateUrl(newUrl) {
    // We are making a broad assumption that the Url will only be updated here
    // when we are at the main page.  Not when the LDP is open.  You should not
    // be able to change the filter, view or sort while the LDP modal is open.
    let myState = history.state
    myState.currentUrl = newUrl
    history.replaceState(myState, "", newUrl);
  }

  deleteQueryParam(name) {
    var newUrl = new URL(window.location.href);
    newUrl.searchParams.delete(name);
    this.updateUrl(newUrl.href);
  }

}