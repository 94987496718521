import {translations} from '../translations.js.erb'

let enableLink;
let disableLink;

export function setupSubscriptionToggleLinks() {

  enableLink = $('.enableNotificationsLink');
  disableLink = $('.disableNotificationsLink');

  enableLink.click(handleEnableClick);
  disableLink.click(handleDisableClick);
}


function handleEnableClick(e) {
  e.preventDefault();
  const newsfeedId = $(this).attr('data-newsfeed-id');

  if (typeof newsfeedId !== 'undefined' && newsfeedId !== '') {
    toggleNewsfeed(true, newsfeedId);
  } else {
    createNewsfeed($(this).attr('data-saved-search-id'));
  }  
}

function handleDisableClick(e) {
  e.preventDefault();
  toggleNewsfeed(false, $(this).attr('data-newsfeed-id'));
}

function createNewsfeed(savedSearchId) {
  doAjaxRequest(routes.newsfeeds_path(), "POST", {"search_id": savedSearchId });
}

function toggleNewsfeed(newState, newsfeedId) {
  var url = routes.newsfeeds_path() + '/' + newsfeedId;
  var data = {"newsfeed": { "Active": newState, "NotificationsActive": newState } };

  doAjaxRequest(url, "PUT", data);
}

function doAjaxRequest(url, method, data) {
  showPageLoadingMsg();
  $.ajax({
    url: url,
    method: method,
    xhrFields: window.ListingAction.xhrFields(window.listingActionSettings),
    data: data,
    dataType: 'json',
    success: ajaxSuccess,
    error: ajaxError,
    complete: ajaxComplete
  });
}

function ajaxSuccess(newsfeed, status, xhr) {
  const active = (newsfeed !== null && (newsfeed.Active === true || newsfeed.Active === 'true'));

  enableLink.toggle(!active);
  disableLink.toggle(active);

  if(newsfeed !== null){
    enableLink.attr('data-newsfeed-id', newsfeed.Id);
    disableLink.attr('data-newsfeed-id', newsfeed.Id);
  }
}

function ajaxError(event, xhr, status, error) {
  alertModal(translations.subscription_toggle.error_message);
}

function ajaxComplete() {
  hidePageLoadingMsg();
}
