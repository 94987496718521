import { Controller } from "stimulus";

export default class extends Controller {
  connect() {

    let mapSupportData = $('#mapSupportData').html();
    window.mapSupport = JSON.parse(mapSupportData);

    moveContainer();
    window.addEventListener("resize", moveContainer);
    this.setDynamicHeightFix();
    window.addEventListener("resize", this.setDynamicHeightFix);

    // This allows the tpv to handle the active button duties.
    tpvInitializeFlexSubNav({"subnav":{}});

    // We only connect to this controller when the full page is loaded.
    // We dont want the content initialization to be kicked off more than once.
    // So here we send an event to the subnav who will do the work.
    // We don't do it in the subnav connect because that would get called many times throughout
    // the 'life' of the page.
    dispatchEvent(new CustomEvent("flexpanel:initializeContent"))
  }

  disconnect() {
    window.removeEventListener("resize", moveContainer);
    window.removeEventListener("resize", this.setDynamicHeightFix);
  }

  setDynamicHeightFix() {
    var contentBlock = document.getElementById("content-block");
    contentBlock.style.height = window.innerHeight - contentBlock.offsetTop + "px"
  }

}
