import { Controller } from "stimulus";

export default class extends Controller {
  ldpCopyLinkToClipboard() {
    const textToCopy = $("#ldp_share_link").val();
    this.copyToClipboard(textToCopy);
    toast.success("Link Copied to Clipboard!");
  }

  copyToClipboard(value) {
    const textArea = document.createElement("textarea");

    textArea.value = value;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");

    document.body.removeChild(textArea);
  }
}
