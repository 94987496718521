export function tableHeaders() {
    var propType = $("#propertyTypeSelector option:selected").val();
    if (!propType) {
      propType = 'A'
    }

    var selectedView = $('.selected-view').attr('data-view-id');
    if(propType && selectedView){
      $.ajax({
        url: 'views/' + selectedView + '?property_type=' + propType,
        dataType: 'json',
        success: function(d){
          var tableHeader = $("#listingsDataTable thead tr");
          tableHeader.find('th:not(.preserve)').remove(); // MLS #, Status, and Price are always preserved in the table
          $(d).each(function(i,tableTitle){
            tableHeader.append('<th>' + tableTitle + '</th>')
          });
        },
        error: function(e){
          console.log('error' + JSON.stringify(e))
        }
      })
    } 
  }
