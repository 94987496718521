import MarkerWithLabel from 'markerwithlabel';
import mapConverters from 'src/map/map_converters';

/*
    Cluster Marker

    Used to represent a cluster of listings on the map.

    Options argument:

      lat: (required)
      lng: (required)
      count: (required)
      location: (required) - a sparkql polygon string. ex: polygon('46.861292 -96.79295,...

 */


export default function ClusterMarker(opts) {

  this.location = opts.location;

  var options = {
    icon: " ", // hide the normal goggle map pins
    position: new google.maps.LatLng(opts.lat, opts.lng),
    labelContent: opts.count.toString(),
    labelClass: 'marker-cluster digits-' + opts.count.toString().length,
    labelAnchor: new google.maps.Point(15, 25),
  }

  MarkerWithLabel.call(this, options);
}

ClusterMarker.prototype = Object.create(MarkerWithLabel.prototype);

// 
// Public functions
// 

ClusterMarker.prototype.bindEvents = function (map) {
  var self = this;
  google.maps.event.addListener(self, 'click', function(event){
    var newBounds = mapConverters.sparkShapeToGoogleBounds(self.location)
    map.moveMapToBoundary(newBounds);
  });
}

ClusterMarker.prototype.delete = function() {
  this.setMap(null);
}


// 
// Private functions
// 

