import MarkerWithLabel from 'markerwithlabel';
import {markerManager} from '../marker_manager';
/*

    Listing Marker

    A small dot that represents a single listing.

    Options argument:

      listingId: (required)
      lat: (required)
      lng: (required)

      listingStatus: (optional) Used for the css class

      dependant: this marker exists to support another marker (e.g. a
                 PreviewMarker)

 */


export default function ListingMarker(opts) {
  this.width = 16;
  this.height = 16;

  this.listingId = opts.listingId;
  this.listingStatus = opts.listingStatus;
  this.lat = opts.lat;
  this.lng = opts.lng;

  this.labelAnchor = new google.maps.Point(8, 8);

  this.dependant = opts.dependant || false;

  var labelOptions = {
    labelClass: 'marker-dot',
    labelAnchor: this.labelAnchor,
    position: new google.maps.LatLng(this.lat, this.lng),
    icon: " " // hide the normal goggle map pins
  }

  if(typeof this.listingStatus === 'string'){
    labelOptions.labelClass += ' marker-status-' + this.listingStatus.toLowerCase().replace(/\s/g, '-');
  }

  MarkerWithLabel.call(this, labelOptions);
}

ListingMarker.prototype = Object.create(MarkerWithLabel.prototype);

ListingMarker.prototype.constructor = ListingMarker;


//
// Public functions
// 

ListingMarker.prototype.delete = function() {
  this.setMap(null);
}

ListingMarker.prototype.viewportPosition = function() {
  if(typeof this.map === 'undefined') {
    return;
  }
  // copied from https://stackoverflow.com/a/20361798/4249632
  var numTiles = 1 << this.map.getZoom();
  var projection = this.map.getProjection();
  var latLng = new google.maps.LatLng(this.lat, this.lng)
  var worldCoordinate = projection.fromLatLngToPoint(latLng);
  var pixelCoordinate = new google.maps.Point(
          worldCoordinate.x * numTiles,
          worldCoordinate.y * numTiles);

  var topLeft = new google.maps.LatLng(
      this.map.getBounds().getNorthEast().lat(),
      this.map.getBounds().getSouthWest().lng()
  );

  var topLeftWorldCoordinate = projection.fromLatLngToPoint(topLeft);
  var topLeftPixelCoordinate = new google.maps.Point(
          topLeftWorldCoordinate.x * numTiles,
          topLeftWorldCoordinate.y * numTiles);

  return {
    left: pixelCoordinate.x - topLeftPixelCoordinate.x,
    top: pixelCoordinate.y - topLeftPixelCoordinate.y
  };

}

ListingMarker.prototype.bindEvents = function (map) {
  var self = this;

  if(Modernizr.touch) {
    $(self.label.eventDiv_).on('click', function(event) {
      self._showPreviewMarker(map);
    });    
  } else {

    $(self.label.eventDiv_).on('mouseenter', function(event) {
      self.addClass('has-preview');

      self.previewMarkerTimeout = setTimeout(function() {
        // If the timeout wasn't canceled, show the marker
        self._showPreviewMarker(map);
      }, 400)
    });

    $(self.label.eventDiv_).on('mouseleave', function(event) {
      self._handleMouseleave(event, map)
    });

  }
}

ListingMarker.prototype._handleMouseleave = function(event, map) {
  // The mouseleave event fires on the listing marker fairly inconsistently.
  // Sometimes it fires when the preview appears. Sometimes it fires multiple
  // times. To get this to work like you'd expect, we need to compare the coordinates
  // from the event to the bounding box of the listing pin. If the event coordinates
  // are inside the listing pin bounding box, then we can ignore the event. If
  // they are outside, then it was a "real" mouseleave, and we can delete the
  // preview marker.
  var x = event.clientX;
  var y = event.clientY;

  if( !this.containsPoint(x, y) ) {
    clearTimeout(this.previewMarkerTimeout);
    this.removeClass('has-preview');

    markerManager.deletePreviewMarker();
  }    
}

ListingMarker.prototype.containsPoint = function(x, y) {
    var listingMarkerRect = this.label.labelDiv_.getBoundingClientRect();

    if( x <= Math.ceil(listingMarkerRect.left) ||
        x >= Math.floor(listingMarkerRect.right) ||
        y <= Math.ceil(listingMarkerRect.top) ||
        y >= Math.floor(listingMarkerRect.bottom)
      ) {
      return false;
    } else {
      return true;
    }
}

ListingMarker.prototype._showPreviewMarker = function(map) {
  var data = {
    listingId: this.listingId,
    listingStatus: this.listingStatus,
    lat: this.lat,
    lng: this.lng
  };

  map.deleteParcelOutlines();
  markerManager.addPreviewMarker(data, {showSlideshow: true});
}

ListingMarker.prototype.addClass = function(className) {
  var classes = this.labelClass.split(' ');
  if(classes.indexOf(className) < 0){
    classes.push(className);
    this.set('labelClass', classes.join(' '));
  }
}

ListingMarker.prototype.removeClass = function(className) {
  var classes = this.labelClass.split(' ');
  var index = classes.indexOf(className);
  if( index >= 0) {
    classes.splice(index, 1);
  }
  this.set('labelClass', classes.join(' '));
}

//
// Private functions
// 

