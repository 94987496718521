import axios from 'axios';

let listing;

function getLink(_listing) {
  listing = _listing;

  const url = routes.shared_links_path();
  const data = { 
    shared_link: { 
      ListingIds: [listing.ListingKey], 
      Type: "Indirect" 
    } 
  }

  axios.post(url, data, {
    dataType: "json",
  }).then((response) => {
    const isNativeShareSupported = (navigator.share || navigator.userAgent.indexOf('GoNativeAndroid') > -1);
    if (isNativeShareSupported) {
      nativeWebShare(response.data);
    } else {
      useShareModalDialog(response.data);
    }
  })
  .catch((jqXHR, textStatus, errorThrown) => {
    toast.error('Server Error! Please refresh the page and try again.', false);
  });
}

function nativeWebShare(data) {
  if (navigator.share) {
    var agentName = $('.contact-agent-form-agent-name').text();
    var consumerName = 'FlexMLS user';
    if (Flexmls.currentUser.contact) {
      consumerName = Flexmls.currentUser.contact.DisplayName;
    }
    var addressLine = listing.StreetAddress + ', ' + listing.City + ', ' + listing.StateOrProvince + ', ' + listing.PostalCode;
    var shareTitle = consumerName + ' sent you ' + addressLine;
    var shareBody = shareTitle + ', courtesy of ' + agentName;
    navigator.share({
      title: shareTitle,
      text: shareBody,
      url: data.SharedUri
    })
    .then(function () {
      // Successful share
    })
    .catch(function (error) {
      // Failed or Canceled share
      if (error.message.indexOf('canceled') === -1) {
        useShareModalDialog(data);
      }
    });
  } else if (navigator.userAgent.indexOf('GoNativeAndroid') > -1) {
    window.location.href='gonative://share/sharePage?url=' + data.SharedUri;
  }
  EventTracking.Adapters.Rails.track_event('listingSharedViaPermalink', listing);
}

function useShareModalDialog(data) {
  var shareLinkModal = $('#share_link_modal');
  shareLinkModal.modal('show');
  shareLinkModal.on("shown.bs.modal", updateModal(data));
}

function updateModal(data) {
  var subject =  "Take a look at this: "+ listing.StreetAddress;
  var body = "I’d like to share this with you: " + data.SharedUri;
  var email_href = "mailto:?subject=" + subject + "&body=" + body;

  $('#share_link_modal').find('.hide').removeClass('hide');
  $('#emailListingLink').attr('href', email_href);
  $('#shareLinkLoading').hide();
  EventTracking.Adapters.Rails.track_event('listingSharedViaPermalink', listing);
}

export var shareListing = {
  getLink: getLink
};
