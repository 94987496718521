import {Basics} from './basics';
import {searchFormHelpers} from './search_form_helpers';
import {searchFormEvents} from './search_form_events';

export let Fields = {

  reload: function(successCallback, serializedForm = null) {

    var post_data = "";
    var user_added_fields = [];

    if (typeof successCallback === 'undefined') {
      successCallback = function() {};
    }

    window.dispatchEvent(searchFormEvents.fieldsReloading());

    // Close all dropdowns so they don't get orphaned when the selects are replaced.
    setTimeout(function() {
      $('.select2-hidden-accessible').select2('close');
    }, 1);

    $('a.view-results').text($('a.view-results').attr('data-loading-text'));
    
    $('.userAddedField').each(function() {
      user_added_fields.push($(this).attr('data-field'));
    });

    if (user_added_fields.length) {
      post_data += "_user_added_fields=" + user_added_fields + "&";
    }

    var previousMlsIds = (Basics.previous_mls_ids != null) ? Basics.previous_mls_ids.join(',') : '';
    post_data += "_previous_mls_ids=" + previousMlsIds + "&";

    post_data += (serializedForm != null) ? serializedForm : searchFormHelpers.formSerializer();
    
    return $.ajax({
      url: $('.filterModalLink').attr('data-filter-url'),
      type: 'POST', // POST is needed here because of the amount of data that is being sent. A GET can result in a 414 URI Too Long Error.
      data: post_data,
      success: function(data) {
        
        Basics.previous_mls_ids = Basics.mls_id().val();

        $('form.search').replaceWith(data.fields);

        user_added_fields.forEach(function(field_name) {
          $('[data-field="' + field_name + '"]').addClass('userAddedField');
        });

        window.dispatchEvent(searchFormEvents.filterChange(data.filter));

        // Tell everyone what happened. 
        //  - Fire a custom event
        //  - call the success callback
        //  - post to the parent iframe
        //
        // They don't need the fields html though.
        delete(data.fields);

        window.dispatchEvent(searchFormEvents.fieldsReloaded(data));

        successCallback(data);

        // Reloading erases the event callback for the shapes link.
        // This will make sure (1)the link still triggers the hidden select2's change callback
        // and (2) the link is hidden once the shapes are removed from the filter
        $('#resetShapesLink:not(.bound)').addClass('bound').on('click', function (e) {
          e.preventDefault();
          $("#drawingsSelect").empty().trigger("change");
          $('div.drawn-shapes-prompt').hide();
        });
      },

      error: function() {
        return alertModal('Sorry, there was an error updating the form.');
      }
    });
  },

  /*
  checks a field by standard or custom name
  and returns a jQuery wrapped object if the
  field exists, or null if it doesn't
   */
  exists: function(name) {
    var selector;
    
    //Replace invalid naming convention
    if (/^"([^"]+)+"\."([^"]+)+"$/.test(name)) {
      name = name.replace(/"/g, '').replace(/\s/g, '_').replace(/\#/g, '_');
    }
    
    if ($("#search_fields_" + name + "_value").length === 1)
    {
      return $("#search_fields_" + name + "_value");
    }
    else if  ($("#search_fields_" + name + "_left").length === 1)
    {
      return $("#search_fields_" + name + "_left");
    }
    else
    {
      return null;
    }
  },

  /*
  Removes a field from the form
   */
  remove: function(name) {    
    var fieldSelectors = Fields.getFieldSelectors(name);
    var containerSelector = $(fieldSelectors[0]).closest('.c-card');
    containerSelector.remove(); 
    searchFormHelpers.findCount();
  },
  
  // Tests against standard or custom named fields, and returns the selector
  getFieldSelectors: function(name){
    var id, standardName, splits;
    id = this.getFieldId(name);
    if (/^([^"]+)+\.([^"]+)+$/.test(id)) 
    {
      splits = id.split('.'); 
      var baseFieldname = '#search_fields_' + splits[0] +  '\\.' + splits[1];
      var valueField = baseFieldname + '_value';
      var leftField = baseFieldname + '_left'
      if($(valueField).length === 1){
        return [$(valueField)];
      }
      if($(leftField).length === 1){
        return [$(leftField)];
      }    
    } 
    else if ($("#search_fields_" + id + "_value").length === 1)
    {
      return [$("#search_fields_" + id + "_value")];
    } 
    else if ($("#search_fields_" + id + "_left").length === 1)
    {
      return [$("#search_fields_" + id + "_left"), $("#search_fields_" + id + "_right")];
    }
    else 
    {
      return []; 
    }    
  },
  
  // Gets the fields id
  getFieldId: function(name)
  {
    return name.replace(/^.+\[/, '').replace(/\].*$/, '').replace(/#/, '_');
  },
  
  // Gets the checkbox selector of the container for a given field name
  getFieldCheckboxSelector: function(name){
    var id = this.getFieldId(name);
    var fieldSelectors = Fields.getFieldSelectors(name);
    var checkboxSelector = $(fieldSelectors[0]).closest('.c-card').find('[type=checkbox]');
    
    if(checkboxSelector.length === 1)
      return checkboxSelector; 
    else 
      return null;  
  },

  removeGroup: function(e) {
    e.preventDefault();

    var fields = [];
    var $target = $(e.target);
    var $parent = $target.parents('.control-group');
    var $lbl = $parent.find('> label').text();
    var $inputs = $parent.find('input[type="checkbox"]');
    
    $.each($inputs, function(i, el) {
      return fields.push(Fields.processed_field_id(el.name.replace(/\[value\]/, "")));
    });
    $parent.hide();
    $parent.remove();
    searchFormHelpers.findCount();
    return $('#addFieldDropdown').find("[data-group-label=\"" + $lbl + "\"]").closest('li').removeClass('included');
  },

  processed_field_id: function(name) {
    var scrubbed_id, splits;
    scrubbed_id = name.replace(/^.+\[/, '').replace(/\].*$/, '');
    if (/^([^"]+)+\.([^"]+)+$/.test(scrubbed_id)) {
      splits = scrubbed_id.split('.');
      return '#search_fields_' + splits[0] +  '\\.' + splits[1] + '_value';
    } else {
      return scrubbed_id;
    }
  },

  row_for: function(name) {
    return Fields.exists(name).closest('.c-card');
  },

  setOperator: function(e) {
    e.preventDefault();

    var $target = $(e.target);
    var op = $target.data('operator');
    var $parent = $target.parents('.control-group');

    $parent.find('input:hidden').val(op);
    $parent.removeClass('op-not op-and op-or').addClass("op-" + op);
    searchFormHelpers.findCount();
    try {
      return _gaq.push(['_trackEvent', 'Field', 'Operator', op]);
    } catch (undefined) {}
  },

  fields_present: function() {
    var ignore, items;
    ignore = [];
    items = ['MlsStatus', 'PropertyType'];
    $('#list_container_form input').each(function(idx, elem) {
      var api_param_name, bracketed_name_components, current_input_name, dot_index;
      current_input_name = elem.name || '';
      if (current_input_name !== '') {
        bracketed_name_components = current_input_name.match(/\[([^\]]+)\]/);
        if (bracketed_name_components !== null) {
          api_param_name = bracketed_name_components[1];
          dot_index = api_param_name.indexOf('.');
          if (dot_index > -1) {
            api_param_name = api_param_name.substring(dot_index + 1);
          }
          if (current_input_name.match(/(field_type|relative_value|relative_time|relative_direction)/) === null && items.indexOf(api_param_name) === -1) {
            return items.push(api_param_name);
          }
        }
      }
    });
    return items;
  },

  rangeFromSelect: function(ele) {
    var $input, $option, $options, $select, i, j, number_label, number_value, ranges, ref, running_range;
    $select = $(ele);
    $options = $select.find('option');
    $input = $select.closest('.select-range-field').find('input');
    ranges = [];
    running_range = [-1, -1];
    for (i = j = 0, ref = $options.length; j <= ref; i = j += 1) {
      $option = $options.eq(i);
      number_label = $option.text().match(/^(\d+) -/);
      number_value = number_label ? number_label[1] : false;
      if (number_value) {
        if ($option.is(':selected')) {
          if (running_range[0] === -1) {
            running_range[0] = parseInt(number_value, 10);
          }
          running_range[1] = parseInt(number_value, 10);
        } else if (running_range[0] !== -1 || running_range[1] !== -1) {
          if (running_range[0] === running_range[1]) {
            ranges.push(running_range[0]);
          } else if (running_range[0] !== running_range[1]) {
            ranges.push(running_range.join("-"));
          }
          running_range = [-1, -1];
        }
      } else if ($option.is(':selected')) {
        ranges.push($option.text());
      }
    }
    return $input.val(ranges.join(","));
  },

  selectFromRange: function(ele) {
    var $input, $option, $options, $select, high, i, j, k, l, len, low, matches, n, option_number, options_to_select, range, ranges, ref, ref1, ref2, results, selected, word, words_to_live_by;
    $input = $(ele);
    $select = $(ele).closest('.select-range-field').find('select');
    $options = $select.find('option');
    ranges = $input.val().split(',');
    words_to_live_by = {};
    options_to_select = {};
    for (j = 0, len = ranges.length; j < len; j++) {
      range = ranges[j];
      matches = null;
      if (matches = range.match(/^\d+$/)) {
        options_to_select[matches] = true;
      } else if (matches = range.match(/^(\d+)-(\d+)$/)) {
        low = parseInt(matches[1], 10);
        high = parseInt(matches[2], 10);
        for (n = k = ref = low, ref1 = high; k <= ref1; n = k += 1) {
          options_to_select[n] = true;
        }
      } else if (matches = range.match(/^([a-z ]+)$/i)) {
        words_to_live_by[matches[1]] = true;
      }
    }
    results = [];
    for (i = l = 0, ref2 = $options.length; l <= ref2; i = l += 1) {
      selected = false;
      $option = $options.eq(i);
      option_number = $option.text().match(/^(\d+) -/);
      if (option_number && options_to_select[option_number[1]]) {
        selected = true;
      }
      for (word in words_to_live_by) {
        word = word.toLowerCase();
        if ($option.text().toLowerCase().match(word)) {
          selected = true;
        }
      }
      results.push($option.attr('selected', selected));
    }
    return results;
  }
};
