import Cookies from 'js-cookie';

export function hideAllSubMenus() {
  $('.subnav-menu').addClass('hidden')
}
  
export function showSubMenu(menu_type) {
  $('.subnav-menu-' + menu_type).removeClass('hidden');
}

export function showLastViewSubMenu() {
  hideAllSubMenus();
  showSubMenu(Cookies.get('SearchResultsLastViewMode'))
}