// CustomEvent constructor polyfill for IE 9, 10, 11.
// See https://stackoverflow.com/a/26596324/3071131
(function () {
  if ( typeof window.CustomEvent === "function" ) return false; //If not IE

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();


export var searchFormEvents = {

  filterChange: function(filter) {
    return new CustomEvent('searchForm:filterChanged', {detail: filter});
  },

  countChanged: function(data) {
    return new CustomEvent('searchForm:countChanged', {detail: data});
  },

  fieldsReloading: function(data) {
    return new CustomEvent('searchForm:fieldsReloading');
  },

  fieldsReloaded: function(data) {
    return new CustomEvent('searchForm:fieldsReloaded', {detail: data});
  },

  filterFirstInitialized: function(data) {
    return new CustomEvent('searchForm:filterFirstInitialized', {detail: data});
  }

};
