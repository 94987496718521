import { Controller } from "stimulus";
import {getState} from '../search_results/state'
import {Moarify} from '@programming/moarify'
import {SearchTemplateViewControl} from '../search_results/search_template_view_control'
import { showLdp } from "../helpers/ldp_navigation_helpers"
import { tableHeaders } from '../helpers/view_helpers'
import { consumerListingCardActions } from "../search_results/consumer_listing_card_actions";

// This controller will handle moarify and listen for events that will cause the list to update.
// It will not handle the specific events that happen when interacting with the list.
// In the html I will have a separate empty div that defines the controller and all of the events
// that it is interested in.
export default class extends Controller {

  static targets = [ "body" ]
  static values = {
    scroll: String,
    view: String
  }

  // list specific version of moarify
  moarify = null;
  frozen = false;

  async connect() {
    consumerListingCardActions.init();
    $(this.bodyTarget).on('moarify:itemsAdded',this.moarifyItemsAdded);
    let options = {
      perPage: 10,
      currentPage: 0,
      infiniteScroll: true,
      totalItems: getState('listingCountTotal'),
      scrollElement: $('#' + this.scrollValue),
      params: { list_view: this.viewValue},
      appendTo: $(this.bodyTarget),
      moarBtn: '#listingsTableMoarBtn',
      loadingSpinner: '#tableListingsListLoadingSpinner'
    }
    this.moarify = new Moarify(options);
    this.moarify.run();
  }

  disconnect() {
    $(this.bodyTarget).off('moarify:itemsAdded',this.moarifyItemsAdded);
  }
  
  filterChanged(data) {
    this.setMoarifyParam('_filter', data.detail.newValue.toString())
    if (!this.frozen) {
      this.reload()
      tableHeaders();
    }
  }

  mapBoundsChanged(data) {

    // I am keeping this function in case there are things that do need to happen that
    // we missed when moving to stimulus actions.
    // However, we are getting rid of the option to filter by map bounds.  So that does
    // not need to be accounted for here.    
    
    // TODO:  Remove this method when we go live if it is determined we have nothing to do here.
  }

  sortIdChanged(data) {
    this.moarify.setParam('sort_id', data.detail.newValue)
    this.reload()
  }

  userViewIdChanged(data) {
    this.moarify.setParam('view_id', data.detail.newValue);
    this.addViewIdToForms(data.detail.newValue);
    this.reload();
  }

  mobileSearchResultsViewChanged(data) {
    debugger;
    // Keeping the debugger for now.  Not sure we will need this anymore.
  }

  saveListUpdatedChanged(data) {
    debugger;
    // Keeping the debugger for now.  This code has been moved
    // from listings_list.  But I have not run into this event yet.
    this.reload()
  }
  
  listItemsAdded(data) {
    var tableHeaderPropType = $("#propertyTypeSelector option:selected").val();
    var currListingsPropType = $(".tableListingsListBody tr:first").attr('data-property-type');
    if(tableHeaderPropType != currListingsPropType){
      $("#propertyTypeSelector").val(currListingsPropType);
      let searchTemplateViewControl = new SearchTemplateViewControl('#userViews');
      searchTemplateViewControl._tableHeaders();
    }

    $('.showingInfoLink').each(function() {
      if( !$.data(this, 'showingInfoLink') ){
        $.data(this, 'showingInfoLink', new ShowingInfo(this));
      }
    });
  }

  moarifyItemsAdded(data) {
    const highlightedListing = sessionStorage.getItem("highlightedListingId");
    $(`#${highlightedListing}`).addClass("current-ldp");
    dispatchEvent(new CustomEvent('listingItemsUpdated'))
  }

  requestNextListing(data) {
    let currentListing = $('.current-ldp')
    if (currentListing.next().length == 0) {

      // See if the current item is in our list.
      currentListing = $('#' + data.detail)
      if (currentListing.length == 0) {
        // Shouldnt get here unless we are trying to see where we are
        // in the list.  If we are here, the current ldp is not in
        // the list table.
        // TODO: We should load enough items in the list to never get here.
        currentListing = $('.listingListItem:first');
      }
      currentListing = $(currentListing[0]);
    }
    let nextListing = currentListing.next()
    currentListing.removeClass('current-ldp')
    nextListing.addClass('current-ldp')
    let browserUrl = nextListing.attr('data-href');

    // Since we are already in the search page, we just want to load the detail into the turbo frame.
    let detailUrl = browserUrl.replace('/listings/', '/listing_detail/')

    showLdp({
      listingId: nextListing.attr('id'),
      listingUrl: detailUrl,
      src: 'list',
      priorListings: true,
      moreListings: (nextListing.next().length > 0 ? true : false),
      browserUrl: browserUrl,
      pushState: false
    });
  }

  requestPreviousListing(data) {
    let currentListing = $('.current-ldp')
    if (currentListing.prev().length == 0) {
      console.log('No more listings.');
      return;
    }
    let prevListing = currentListing.prev()
    currentListing.removeClass('current-ldp')
    prevListing.addClass('current-ldp')
    let detailUrl = prevListing.attr('data-href');
    history.replaceState('','',detailUrl)
    // Since we are already in the search page, we just want to load the detail into the turbo frame.
    detailUrl = detailUrl.replace("/listings/", "/listing_detail/")

    showLdp({
      listingId: prevListing.attr('id'),
      listingUrl: detailUrl,
      src: 'list',
      priorListings: (prevListing.prev().length > 0 ? true : false),
      moreListings: true,
      pushState: false
    });
  }

  reload() {
    this.moarify.emptyCache();
    this.moarify.reload();
  }

  setMoarifyParam(key, value) {
    this.moarify.setParam(key, value)
  }

  addViewIdToForms(value) {
    if(value){
      $('.favoritesToggleLink').attr('href', this.replaceViewIdInUrl($('.favoritesToggleLink').attr('href'), value));
      $('#saveSavedSearchForm').attr('action', this.replaceViewIdInUrl($('#saveSavedSearchForm').attr('action'), value));
      $('#new_saved_search_modal form').attr('action', this.replaceViewIdInUrl($('#new_saved_search_modal form').attr('action'), value));
      $('#saveAndShareForm').attr('action', this.replaceViewIdInUrl($('#saveAndShareForm').attr('action'), value));
    }
  }

  replaceViewIdInUrl(link, view_id){
    if(link){
      if(link.includes('view_id')){
        var newLink = link.replace( /view_id=\d+/, 'view_id='+view_id)
      } else if(link.includes('?')) {
        var newLink = link + '&view_id=' + view_id;
      } else {
        var newLink = link + '?view_id=' + view_id;
      }
    }
    return newLink;
  }

  listingCountTotalChanged(evt) {
    let number = getState('listingCountTotal');
    this.moarify.setTotalItems(number)
    if (!this.frozen) {
      tableHeaders();
      this.checkForNoResults(number);
    }
  }

  checkForNoResults(count) {
    if(count > 0) {
      $('.searchResultsContent').removeClass('no-listings');
    } else {
      $('.searchResultsContent').addClass('no-listings');
      // Moarify doesnt do this if totalItems is 0 for some reason
      $(this.bodyTarget).html('')
    }
  }

}