import MarkerWithLabel from 'markerwithlabel';

export default function MyLocationMarker(point) {

  var options = {
    position: point,
    labelClass: 'marker-my-location',
    labelAnchor: new google.maps.Point(9, 9),
    icon: " " // hide the normal goggle map pins
  }

  MarkerWithLabel.call(this, options);
}

MyLocationMarker.prototype = Object.create(MarkerWithLabel.prototype);

MyLocationMarker.prototype.delete = function() {
  this.setMap(null);
}
