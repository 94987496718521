import Cookies from 'js-cookie'
import debounce from 'lodash.debounce';
import axios from 'axios';

import {ellipsizeTextBox} from './ellipsize_text_box';
import {ListingViolationReport} from './listing_violation_report';
import {markerManager} from './map/marker_manager';
import customMapControls from './map/custom_map_controls';
import {ListingGallery} from './listing_gallery';
import {AddToCollection} from './add_to_collection';
import {actions} from './actions';
import {selectListings} from './select_listings';
import {shareListing} from './share_listing';
import {ShowingInfo} from './showing_info';
import {translations} from './translations.js.erb'
import {loadLocalLogicContent} from './vendors/local_logic';
import {loadLocalLogicDemographics} from './vendors/local_logic';
import PriceMarker from './map/markers/price_marker';

const MINIMUM_MAP_WIDTH = 768;
const SCROLL_WAIT = 500;
const SMALL_MOUSE_MOVEMENT = 3;

var listing;
var currencySymbol;
var mapEnabled;
var map;
var ldpMap;
var loadedFragments = [];
var foreignCurrency = [];
let saveLink;
let hideLink;
var ldpMap;

function init(listingArg, options, useTheMap = true) {

  // If we are reinitializing, we need to clear out the fragments.
  loadedFragments = [];

  listing = listingArg;
  currencySymbol = options.currencySymbol;
  foreignCurrency = options.foreignCurrency;
  mapEnabled = options.mapEnabled;

  new ShowingInfo('.showingInfoLink');

  ListingGallery.init({height: 5, js_listing: window.js_listing, bindEvents: true});

  handlePageLayout();
  initLdpMap()
  $(window).resize( debounce(handlePageLayout, 500) );


  loadInitialFragments();
  $('.c-card-group').flexCardGroup();

  $('#content').on('scroll', debounce(function() {

    // If there is a clicked div, we are here because of
    // a menu click.  Ignore the scroll event
    let clickedMenuItem = $('.scrollMenu div.clicked');
    if (clickedMenuItem.length > 0) {
      return;
    }

    //Check to see if the active menu item's target is visible.
    let activeMenuItem = $('.scrollMenu div.active a');
    if (activeMenuItem.length > 0) {
      //There is an active menu item.  Lets check its positioning.
      let currentTarget = activeMenuItem[0]
      let relativeTop = $(currentTarget).position().top - $('#content').position().top
      if (relativeTop >= 0) {
        let relativeBottom = relativeTop + $(currentTarget).height;
        if (relativeBottom < $('#content').position().top + $('#content').height) {
          // Active menu item is fully visible in the scroll content.
          // Dont change the active menu item.
          return;
        }
      }
    }
    // The active menu item's target is out of the scroll view.  Pick the first item whose target is visible in the top of the screen.
    $('.scrollMenu div').removeClass('active');

    let jumpTargetLeft = 0;
    // Loop through the scroll menu items
    $('.scrollMenu div a').each( (index) => {
      // Find the section that this item refers to
      let jumpTarget = $($('.scrollMenu div a')[index]).attr("href");
      // Get the position of that target relative to the content panel
      let relativeTop = $(jumpTarget).position().top - $('#content').position().top;
      let relativeBottom = relativeTop + $(jumpTarget).outerHeight();
      // Is the top of this item in in the scroll view
      let useThisTarget = relativeTop >= 0;

      if (!useThisTarget) {
        // The top is above the scroll view.  Is the bottom below the scroll view?
        useThisTarget = (relativeBottom > $('#content').position().top + $('#content').height())
        // If so, use this target as it takes up the entire scroll view.
      }

      if ( useThisTarget) {
        // This item is the first item we found whose top is visible in
        // the scrollable area.  Or this item takes up the entire scrolling area.
        // This is the droid we are looking for!
        let targetMenuItem = $($('.scrollMenu div a')[index]).parent();
        let scrollMenu = $('.scrollMenu');

        // Set the menu item as active
        targetMenuItem.addClass('active');

        // Make sure the menu item is totally visible in the nav ribbon
        if (targetMenuItem[0].offsetLeft < $('.scrollMenu').scrollLeft()) {
          scrollMenu.scrollLeft(jumpTargetLeft);
        } else {

          let targetDivRight = targetMenuItem[0].offsetLeft + targetMenuItem.outerWidth();
          let scrollMenuRight = scrollMenu.scrollLeft() + scrollMenu.outerWidth();
          if ( targetDivRight > scrollMenuRight) {
            scrollMenu.scrollLeft(scrollMenu.scrollLeft() + (targetDivRight - scrollMenuRight))
          }
        }
        // We processed the first visible item.  No need to process any more.
        return false;
      }
      // If the jump target is partially hidden (in the left side of the scroll)
      // we want to make sure the entire div of this item gets displayed.
      // The easiest way to do that is to add up all of the widths of the divs
      // to the left of the target.  Otherwise we get margin/padding errors.
      jumpTargetLeft += $($('.scrollMenu div')[index]).outerWidth();
      // If we got here, we didnt find the visible section yet.  Keep going.
    });
  }, 100));

  setTimeout(function(){
    // this shouldn't need a delay, but moving it one tick seems to sort out a
    // bug with height calculation that doesn't make much sense otherwise
    ellipsizeTextBox('.remarks');
  }, 0);

  $('#listingDetailMapButton').click(showMap);

  $('#card-collapsible-local-logic-content').on('shown.bs.collapse', function() {
    if ($('#localLogicContentContainer').html() === "" && listing.Latitude && listing.Longitude)
      loadLocalLogicContent({ lat: listing.Latitude, lon: listing.Longitude, api_key: options.localLogicKey});
  });

  $('#card-collapsible-local-logic-demographics').on('shown.bs.collapse', function() {
    if ($('#localLogicDemographicsContainer').html() === "" && listing.Latitude && listing.Longitude)
      loadLocalLogicDemographics({ lat: listing.Latitude, lon: listing.Longitude, api_key: options.localLogicKey})
  });

  document.querySelector('.scrollMenu').addEventListener('touchstart', e => {
    const touch = e.targetTouches[0];
    updateNavMenuScrollPosition(touch);
  });

  document.querySelector('.scrollMenu').addEventListener('mousedown', e => {
    updateNavMenuScrollPosition(e);
  });

  document.querySelector('.scrollMenu').addEventListener('mouseup', () => {
    // Use timeout so other events will see the active/startX/scrollLeft info
    // and act accordingly before this event clears them.
    setTimeout(function () {
      clearNavMenuFlags();
    }, SCROLL_WAIT);
  });

  document.querySelector('.scrollMenu').addEventListener('touchend', () => {
    // Use timeout so other events will see the active/startX/scrollLeft info
    // and act accordingly before this event clears them.
    setTimeout(function () {
      clearNavMenuFlags();
    }, SCROLL_WAIT);
  });

  document.querySelector('.scrollMenu').addEventListener('mousemove', e => {

    if (e.buttons == 0 || e.sourceCapabilities.firesTouchEvents) {
      // We are NOT dragging the mouse
      // OR
      // We are on a touch device.  Dont process the mousemove event.  It is being fired
      // for reasons that do not concern us.
      // Obi-Wan: This is not the event you are looking for.
      // Stormtrooper: This isnt the event I am looking for.
      // Obi-Wan: Move along
      // Stormtrooper: Move along
      return;
    }
    e.preventDefault();

    const x = e.pageX - $('.scrollMenu')[0].offsetLeft;
    const walk = x - $('.scrollMenu').attr("startX");
    if (!$('.scrollMenu').hasClass('active')) {
      if (Math.abs(walk) < SMALL_MOUSE_MOVEMENT) {
        // Dont start the drag until we have reached a small threshold.
        // This prevents clicks that had a slight mouse movement from
        // being seen as the end of a drag.
        return;
      }
    }

    // The scroll menu is actively scrolling
    $('.scrollMenu').addClass('active');

    // Scroll
    $('.scrollMenu')[0].scrollLeft = $('.scrollMenu').attr("scrollLeft") - walk;
  });

  document.querySelector('.scrollMenu').addEventListener('touchmove', e => {
    // Are we currently dragging the mouse?  No? return!
    let attr = $('.scrollMenu').attr('startX');
    if (typeof attr === 'undefined' || attr === false) return;
    e.preventDefault();

    // The scroll menu is actively scrolling
    $('.scrollMenu').addClass('active');

    // Calculate how far we want to scroll based off of this event
    const touch = e.targetTouches[0];
    const x = touch.pageX - $('.scrollMenu')[0].offsetLeft;
    const walk = x - $('.scrollMenu').attr("startX");
    // Scroll
    $('.scrollMenu')[0].scrollLeft = $('.scrollMenu').attr("scrollLeft") - walk;
  });

  $('.scrollMenu div a').on('click', e => {
    if ($('.scrollMenu').hasClass("active")) {
      // We appear to be in a drag event.  Dont process a click.
      e.preventDefault();
      return;
    }

    $('.scrollMenu div').removeClass('active');
    $('.scrollMenu button').removeClass('active'); // New LDP
    let targetDiv = $(e.target).parent();
    let scrollMenu = $('.scrollMenu');
    targetDiv.addClass('active clicked');
    if (targetDiv[0].offsetLeft < $('.scrollMenu').scrollLeft()) {
      scrollMenu.scrollLeft(targetDiv.position().left);
    }
    else {
      let targetDivRight = targetDiv[0].offsetLeft + targetDiv.outerWidth();
      let scrollMenuRight = scrollMenu.scrollLeft() + scrollMenu.outerWidth();
      if ( targetDivRight > scrollMenuRight) {
        scrollMenu.scrollLeft(scrollMenu.scrollLeft() + (targetDivRight - scrollMenuRight))
      }
    }
  });


  // agent dropdown menu links
  actions.recommendLink('.recommendLink', listing.ListingKey);
  actions.saveLink('.saveListingLink');
  actions.hideLink('.hideLink');
  
  ListingViolationReport.init('.reportViolation');

  new AddToCollection('.removeFromCollection', {successCallback: function(plugin){
    toast.success(translations.listings.actions.removed_successfully);
    plugin.$element.slideUp();
  }});

  $('#share-listing-action').click(function () {
    shareListing.getLink(listing);
  });

  loadEditButton();

  setUpSaveAndHideLinks();
  handlePostLoginCookieIfPresent();

  $('.shareLink').on('click', function(e){
    e.preventDefault();
    let url = new Url($(e.target).attr('href'));
    url.query.referrer = window.location.href;
    window.location.href = url;
  })

}

function updateNavMenuScrollPosition(event) {
  $('.scrollMenu').attr('startX', event.pageX - $('.scrollMenu')[0].offsetLeft);
  $('.scrollMenu').attr('scrollLeft', $('.scrollMenu')[0].scrollLeft);
}

function clearNavMenuFlags() {
  $('.scrollMenu').removeClass("active").removeAttr("startX").removeAttr("scrollLeft");
  $('.scrollMenu div').removeClass("clicked");
  $('.scrollMenu button').removeClass("clicked"); // New LDP
}

function setUpSaveAndHideLinks() {
  // set up consumer listing actions bar save and hide links
  saveLink = new AddToCollection('#save-listing-action', {
    successCallback: saveLinkSuccessCallback
  });
  // remove the listing from the hidden collection if necessary
  $('#save-listing-action').click(() => hideLink.remove());

  hideLink = new AddToCollection('#hide-listing-action', {
    successCallback: hideLinkSuccessCallback
  });  
  // remove the listing from the saved collection if necessary
  $('#hide-listing-action').click(() => saveLink.remove());
}

function saveLinkSuccessCallback(data) {
  const text = data.added ? translations.g.saved : translations.g.save;
  $('#save-listing-action').text(text);
}

function hideLinkSuccessCallback(data) {
  const text = data.added ? translations.g.hidden : translations.g.hide;
  $('#hide-listing-action').text(text);
}

function handlePostLoginCookieIfPresent() {
  let cookieVal = Cookies.get(AddToCollection.postLoginCookieName);

  if(typeof cookieVal !== 'undefined'){
    /* do this first so we don't get into a redirect loop */
    Cookies.remove(AddToCollection.postLoginCookieName);

    var collectionName = cookieVal.split(',')[0];
    /*
     * If the role is now vow/portal, the user logged in so we can add the
     * listing to the collection
     * If the role isn't one of those, they must have hit cancel on the login
     * screen, so we'll no-op here
     */
    if (Flexmls.currentUser.role === "vow" || Flexmls.currentUser.role === "portal") {

      // Since the user wasn't logged in, the buttons would have always shown
      // "Save" or "Hide". If the listing is already saved or hidden, we don't
      // need to do anything. The user's intention probably wasn't to unsave/unhide.
      if(collectionName === 'Favorites' && !saveLink.added){
        saveLink.$element.click();
      } else if(collectionName === 'Rejects' && !hideLink.added){
        hideLink.$element.click();
      }
    }
  }
}


function loadEditButton() {
  if( $('.editListingBtn').length > 0) {
    axios.get(routes.listing_permissions_path(listing.ListingKey)).then((response) => {
      if(response.data.Editable === true) {
        $('.editListingBtn').show();
      }
    });
  }
}

function loadInitialFragments() {
  // Fragments load in the order of 1)General, 2)tax/price history, 3)Rooms/Units
  // But, general contains both Main and Detail fields, and we want Rooms displayed between those two.
  // So, on callback for rooms, we use insertAfter to shift up Rooms, placing the Details card below it.
  loadReportFragments($('#report_general'));
  loadReportFragments($('#report_tax, #report_price_history'));
  loadReportFragments($('#report_room, #report_unit'), function() {
    $("#rooms_card").insertBefore('#report_general > div:last-child');
  });

}

function handlePageLayout() {
  if(window.innerWidth >= MINIMUM_MAP_WIDTH){
    initMap();
  }
}

function loadReportFragments(selector, successCallback) {
  if(typeof successCallback !== 'function') {
    successCallback = function() {};
  }
  $(selector).each(function(index, element) {
    var $elem = $(element);
    var id = $elem.attr('id');

    if(loadedFragments.indexOf(id) < 0){

      let url = $elem.data('fragment-path')
      if ($elem.closest('turbo-frame').length > 0) {
        url += '?newRoute=true';
      }

      $elem.show().load(url, function(response, status, xhr) {
        if (status == "error") {
          // let Bugsnag pick this up
          throw xhr.status + ' '+ xhr.statusText;
        } else {
          if (response === '') {
            // If there is no data for this item
            let menuItemLink = $('.scrollMenu div a[href*="#' + selector[index].id + '"]');
            if (menuItemLink.length === 1) {
              menuItemLink.parent().remove();
            }
          }
          // TODO: This tells the new LDP to open the link in a new tab.
          // We may want to open this in the LDP modal.  But this round
          // wont do that.
          if ($elem.closest('turbo-frame').length > 0) {
            // If it does not have a data action yet, open in a new new tab!
            // If it does have a data action, it will be handled in the modal LDP.
            $elem.find('a[href!="#"]:not([data-action])').attr('target', '_blank');
          }
          loadedFragments.push(id);
          successCallback();
        }
      });

    }

  });
}

function initMap() {
  if(typeof map !== 'undefined' || !mapEnabled || typeof listing.Latitude === 'undefined' || typeof listing.Longitude === 'undefined' || listing.Latitude === null || listing.Longitude === null) {
    return;
  }

  if ($('#listingDetailMap').length == 0) {
    return;
  }

  var mapOptions = {
    disableDefaultUI: true,
    zoom: 15,
    center: { 
      lat: listing.Latitude, 
      lng: listing.Longitude
    },
    gestureHandling: 'greedy'
  }
  map = new google.maps.Map(document.getElementById('listingDetailMap'), mapOptions);

  markerManager.init({map: {googleMap: map}});

  markerManager.addPriceMarker(listing, currencySymbol, {bindEvents: false}, foreignCurrency);

  customMapControls.init({map: map});
  
  $('#closeMapButton').click(function(e) {
    e.preventDefault();
    $('body').removeClass('map-view');
  });
}

function initLdpMap(htmlClass="listingDetailPanelLocationMap") {
  if(typeof listing.Latitude === 'undefined' || typeof listing.Longitude === 'undefined' || listing.Latitude === null || listing.Longitude === null) {
    return;
  }

  var mapOptions = {
    disableDefaultUI: true,
    zoom: 12,
    center: {
      lat: listing.Latitude,
      lng: listing.Longitude
    },
    gestureHandling: 'cooperative',
  }

  ldpMap = new google.maps.Map(document.getElementById(htmlClass), mapOptions);

  addPriceMarker(listing, currencySymbol, {bindEvents: false}, foreignCurrency, ldpMap)

  $('#ldpMapZoomIn').click(function() {
    ldpMap.setZoom(ldpMap.getZoom() + 1);
  });

  $('#ldpMapZoomOut').click(function() {
    ldpMap.setZoom(ldpMap.getZoom() - 1);
  });

  // Handle fullscreen
  ldpMap.addListener("click", () => {
    handleFullscreenForLdpMap()
  });
}

function handleFullscreenForLdpMap() {
  $("#ldp-map-fs-wrapper").show();

  initLdpMap('ldp-map-fullscreen');

  $('#fsLdpMapZoomIn').click(function() {
    ldpMap.setZoom(ldpMap.getZoom() + 1);
  });

  $('#fsLdpMapZoomOut').click(function() {
    ldpMap.setZoom(ldpMap.getZoom() - 1);
  });

  $("#closeLdpMapButton").show()
  $("#drivingDirectionsLdpMapButton").show()

  // Remove listener once in fullscreen
  google.maps.event.clearListeners(ldpMap, 'click');

  $('#closeLdpMapButton').click(function() {
    $("#ldp-map-fs-wrapper").hide()
    initLdpMap()
  });
}

function showMap() {
  $('body').addClass('map-view');
  initMap();
}

// TODO: Move this to a common location?  It is 99% the same
// code as used in flexmls_map to add a marker.  Needed to
// move this function.  It was the only flexmls_map function
// needed.  Using it in the LDP was breaking the map turbo-frame.
// Basically, marker manager was now using the ldp map.
function addPriceMarker(listing, currencySymbol, options, foreignCurrency){

  if(typeof options !== 'object') {
    options = {};
  }

  var opts = {
    listingId: listing.Id,
    lat: listing.StandardFields.Latitude,
    lng: listing.StandardFields.Longitude,
    price: listing.StandardFields.CurrentPrice,
    low_price: listing.StandardFields.ListPriceLow,
    high_price: listing.StandardFields.ListPriceHigh,
    mls_id: (listing.StandardFields.MlsId || listing.MlsId),
    property_type: listing.StandardFields.PropertyType,
    currencySymbol: currencySymbol,
    foreignCurrency: listing.foreignCurrency ? listing.foreignCurrency : foreignCurrency,
    listingStatus: listing.StandardFields.StandardStatus
  };

  let propertyTypeMeta;
  if(typeof window.mapSupport !== 'undefined'){
   propertyTypeMeta = window.mapSupport.propertyTypeMeta;
  }

  if(listing.StandardFields.PropertyClass) {
    opts.propertyClass = listing.StandardFields.PropertyClass;
  } else if (typeof propertyTypeMeta !== 'undefined') {
    var match = propertyTypeMeta.find(function(propertyType) {
      return listing.StandardFields.PropertyType === propertyType.MlsCode;
    });
    if(typeof match !== 'undefined') {
      opts.propertyClass = match.PropertyClass;
    }
  }

  var marker = new PriceMarker(opts);
  marker.setMap(ldpMap);
}

export let ListingDetail = {
  init: init,
  // these functions are exposed for testing only
  _saveLinkSuccessCallback: saveLinkSuccessCallback,
  _hideLinkSuccessCallback: hideLinkSuccessCallback
};
