import { Controller } from "stimulus";
import {getState, setState} from '../search_results/state'
import { replaceLastPathSegment } from '../helpers/ldp_navigation_helpers'


export default class extends Controller {

  filterChanged() {
    if ($('#fullContent').css('display') === 'none') {
      // We dont want to show the full content.
      // And since the filter changed we can unload whatever is currently loaded.
      $('#fullContent')[0].src = ""
      $('#fullContent').html('')
      return;
    }
    let currentUrl = window.location.href
    let url = new URL(currentUrl);
    // If the listing ID is in the URL, remove it (and anything after it) before we fill in the list view
    let pattern = /\/listings\/\d{26}.*/
    url.pathname = url.pathname.replace(pattern, '/listings')
    url.pathname = replaceLastPathSegment(url,'stats_view')
    url.searchParams.set('_filter', getState('filter'))
    showFullContent(url.href)
  }
}