export function saveSearchAs(filter, annotations, quicksearch_id, name, recoveryName, share) {
  var formData = {
    authenticity_token: $('meta[name="csrf-token"]').attr('content'),
    utf8: "✓",
    saved_search: {
      Filter: filter,
      Annotations: annotations,
      QuickSearchId: quicksearch_id,
      Name: name
    },
    commit: 'Save'
  }

  if (quicksearch_id !== null && quicksearch_id !== '') {
    formData["QuickSearchId"] = quicksearch_id;
  }

  let url = new URL($('body').attr('data-sub-nav-root-url')).pathname
  fetch(url + '/saved_searches', {
    method: 'POST',
    body: JSON.stringify(formData),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
  })
  .then(response => response.json())
  .then(data => {
    if (data.success) {
      // We are saving a saved search.  Ignore the filter changed check.
      $('body').attr('data-save-search-complete','true')
      window.location.href = routes.saved_searches_path() + '/' + data.search.Id + '/listings' + (share ? '?share=true' : '');
    } else {
      toast.error(data.error);
      $('#searchName').val(recoveryName)
    }
  })
}

export async function saveSearchChanges(filter, annotations, savedSearchId) {
  var formData = {
    authenticity_token: $('meta[name="csrf-token"]').attr('content'),
    utf8: "✓",
    saved_search: {
      Filter: filter,
      Annotations: annotations
    }
  }

  let url = new URL($('body').attr('data-sub-nav-root-url')).pathname
  let response = await fetch(url + '/saved_searches/' + savedSearchId, {
    method: 'PUT',
    body: JSON.stringify(formData),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
  })

  if (response.ok) {
    toast.success("Search saved successfully!")
    return true;
  }

  let errorMessage = await response.text()
  toast.error(errorMessage)
  return false;
}

export async function renameSearch(name) {
  let url = window.location.href
  let modifiedUrl = url.substring(0, url.indexOf('/listings'));

  var searchTypeData = getSearchTypeData(modifiedUrl, name)

  let response = await fetch(modifiedUrl, {
    method: searchTypeData.requestMethod,
    body: JSON.stringify(searchTypeData.formData),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
  })

  if (response.ok) {
    toast.success("Search renamed successfully.");
    return true
  } else {
    toast.error("Error renaming the search.")
    return false
  }
}

// Different searches need different formData to handle a name change.  This is a helper for that.
// There may need to be more types added.
function getSearchTypeData(url,name) {
  let parts = url.split('/search/');
  let searchType = null;
  let searchTypeData = null;
  if (parts.length > 1) {
    // If there is a substring after '/myapp/'
    searchType = parts[1].split('/')[0]; // Get the substring before the next '/'
  }
  if (searchType == null) {
    return null;
  }
  switch(searchType) {
    case 'collections':
      searchTypeData = {
        requestMethod: 'PUT',
        formData: {
          authenticity_token: $('meta[name="csrf-token"]').attr('content'),
          utf8: "✓",
          collection: {
            Name: name
          }
        }
      }
      break;
    case 'saved_searches':
      searchTypeData = {
        requestMethod: 'PUT',
        formData: {
          authenticity_token: $('meta[name="csrf-token"]').attr('content'),
          utf8: "✓",
          saved_search: {
            Name: name
          }
        }
      }
      break;
  }
  return searchTypeData;
}