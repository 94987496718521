import {translations} from '../translations.js.erb'

export function defaultModal(id, title, content, options) {

  if(typeof options === 'undefined'){
    options = {};
  }

  const classes = options.class || '';

  let html  = `<div class="modal ${classes}" id="${id}" role="dialog">`;
      html +=   '<div class="modal-dialog c-modal">';

      html +=     '<div class="c-modal__header">';
      html +=       `<div class="t-title--largest">${title}</div>`;
      html +=     '</div>';

      html +=     '<div class="c-modal__content" role="document">';
      html +=       content;
      html +=     '</div>';

      html +=     '<div class="c-modal__footer">';
      html +=       '<button class="btn btn-primary" data-dismiss="modal" type="button">';
      html +=         translations.g.close;
      html +=       '</button>';
      html +=     '</div>';

      html +=   '</div>';
      html += '</div>';

  return html;
}
