import { Controller } from "stimulus";
import {saveSearchChanges, saveSearchAs, renameSearch} from '../helpers/search_helpers'
import {getState, setState} from '../search_results/state'
import Filter from '../filter'
import Cookies from 'js-cookie'

export default class extends Controller {

  saveSearchModalContent = `<div>
    <div class="form-group max-width">
      <input id="nameForSearch" class="form-control contact-select" data-placeholder="Enter a name for this search" value="CURRENT_SEARCH_NAME">
    </div>
  </div>`

  saveSearchModalButtons = `<button class="btn btn-secondary" data-dismiss="modal" type="button">Cancel</button><button id="btnSaveSearchAction" data-action="click->save-search#saveSearchWithName"  data-template-id="SEARCH_TEMPLATE_HERE" class="btn btn-primary" data-dismiss="modal" type="button">Save</button>`

  // Set this when the user enters the textbox.  Clear it when done
  previousSearchName = ""

  connect() {

    // We nay have left the page to register before doing the save operation
    // Lets check and see if we should do that save now
    const cookieValue = Cookies.get('saveSearchAfterLogin');
    Cookies.remove('saveSearchAfterLogin')
    if (Flexmls.currentUser.role === 'vow') {
      switch (cookieValue) {
        case 'saveAs': {
          const saveSearchAsBtn = $('#saveSearchAsBtn')[0]
          saveSearchAsBtn.dispatchEvent(new Event('click', { bubbles: true }))
          break
        }
        case 'save': {
          const saveSearchBtn = $('#saveSearchBtn')[0]
          saveSearchAsBtn.dispatchEvent(new Event('click', { bubbles: true }))
          break
        }
      }
    }
  }

  saveAs(evt) {
    evt.preventDefault()

    if (this.needsRegistration('saveAs')) {
      evt.stopImmediatePropagation()
      return
    }

    let suggestedName = $('#searchName').val()
    if (suggestedName.length > 0) {
      suggestedName += "_COPY"
    }

    let buttons = this.saveSearchModalButtons;
    if (evt.params.share) {
        buttons = `<button class="btn btn-secondary" data-dismiss="modal" type="button">Cancel</button><button id="btnSaveSearchAction" data-action="click->save-search#saveSearchWithName" data-save-search-share-param="true" data-template-id="SEARCH_TEMPLATE_HERE" class="btn btn-primary" data-dismiss="modal" type="button">Save and Share</button>`
    }
    window.dispatchEvent(new CustomEvent("display-local-modal", {
      detail: {
        modalCssClasses: "modal-dialog modal-sm c-modal cart-modal",
        modalHeader: `<div class="t-title--large">Name This Search</div>`,
        modalContent: this.saveSearchModalContent.replace("CURRENT_SEARCH_NAME", suggestedName),
        modalFooterContent: buttons.replace("SEARCH_TEMPLATE_HERE",this.element.dataset.searchResultsQuicksearchId || this.element.dataset.saveSearchTemplateId),
        modalDataController: "save-search"
      }
    }));
  }

  saveSearchWithName(evt) {
    let newSearchName = $('#nameForSearch').val()
    let oldSearchName = $('#searchName').val()

    if (newSearchName.length == 0) {
      toast.warning('No name provided.  Search not saved.')
      return
    }
    if (newSearchName == oldSearchName) {
      toast.warning('Name did not change.  Search not saved.')
      return
    }

    let quicksearch_id = (evt.target.dataset.templateId === "default" ? null : evt.target.dataset.templateId)
    let filter = getState('filter').toString();
    let annotations = getState('annotations');
    let share = evt.params.share;
    saveSearchAs(filter, annotations, quicksearch_id, newSearchName, oldSearchName, share )
  }

  filterChanged(data) {
    let currentFilter = getState('filter')
    let saveBtn = $('#saveSearchBtn')
    if (saveBtn.length > 0) {
      if (!currentFilter.equalToOriginal($('#datasource-content').length === 0)) {
        $('#saveSearchBtn').removeClass('disabled')
      } else {
        $('#saveSearchBtn').addClass('disabled')
      }
    }
  }

  changingSearchName(evt) {
    // The user has entered the text box
    this.previousSearchName = evt.currentTarget.value
  }

  async searchNameChanged(evt) {
    // The user left the text box
    let $searchNameEle = $(evt.currentTarget)
    if ($searchNameEle.val() === '') {
      if (this.previousSearchName === '') {
        // The user didnt add any text to the previously empty textbox
        $searchNameEle.removeClass('value-added');
        return;
      }
      // The user left an empty text box.  Restore the previous content
      $searchNameEle.val(this.previousSearchName);
      this.previousSearchName = ''
      return;
    } else if ($searchNameEle.val() === this.previousSearchName) {
      // The user didnt change the text
      return;
    }

    if ($searchNameEle.hasClass('value-added')) {
      // Something was here before.  We must just be changing the name.
      let success = await renameSearch($searchNameEle.val())
      if (!success) {
        // Didnt work!  Change the name back to what it was.
        $searchNameEle.val(this.previousSearchName)
      }
      this.previousSearchName = ''
      return;
    }

    // We are creating a named search.  We can only do this with a quick search or a comparable search.
    // CUR-28555 Look behind searching: /(?<=\/quick_searches\/)\d{26}/ is not supported in older versions of Safari
    // Support added in Safari 16.4: https://developer.apple.com/documentation/safari-release-notes/safari-16_4-release-notes#JavaScript
    // So, for now, we use look ahead searching and we capture two groups and set the match from match[0] to match[1] until our customers catchup.
    var regex = /(?:\/quick_searches\/)(\d{26})/;
    // Extract the 26-digit number using the regular expression
    var match = window.location.href.match(regex);
    let quicksearch_id = null;
    if (match) {
      // Extracted 26-digit number
      quicksearch_id = match[1];
    } else if (window.location.href.indexOf('search/comparables_search/listings')) {
      // TODO:  Do we want to add saving capability for saving address or mls_number searches?

      // We are a flexpanel route comparable search.  We want to save this search.
      match = true;
    }

    if (!match) {
      toast.error('Unable to create a saved search from this search type.');
      return;
    }

    let filter = getState('filter').toString();
    let annotations = getState('annotations');
    saveSearchAs(filter, annotations, quicksearch_id, $searchNameEle.val(), this.previousSearchName )
  }

  async save(evt) {
    evt.preventDefault()
    let $currentTarget = $(evt.currentTarget)
    if ($currentTarget.hasClass('disabled')) return

    if (this.needsRegistration('save')) {
      evt.stopImmediatePropagation()
      return
    }

    let filter = getState('filter')
    let annotations = getState('annotations')
    let changesSaved = await saveSearchChanges(filter.toString(), annotations, this.element.dataset.searchResultsSavedsearchId)
    if (changesSaved) {
      let newUrl = new URL(window.location.href)
      newUrl.searchParams.delete('_filter')
      let myState = history.state
      myState.currentUrl = newUrl.href
      history.replaceState(myState, "", newUrl.href);

      // Because we are not reloading the page (which can take a while)
      // we need to reset the items that we check for changes
      mapSupport.originalFilter = filter.toString()
      mapSupport.filter = filter.toString()
      mapSupport.parsedFilter = filter._data

      $currentTarget.addClass('disabled')
    }
  }

  needsRegistration(cookieInfoForSave) {
    if (Flexmls.currentUser.role === 'idx') {
      // Cannot save if we are idx.  Ask them to register or log in.
      Cookies.set('saveSearchAfterLogin', cookieInfoForSave)
      window.location = Flexmls.currentUser.authUri
      return true
    }
    return false
  }
}
