import { Controller } from "stimulus";
import contactAgent from "../../../assets/javascripts/contact_agent";

export default class extends Controller {
  handleResponse = {
    success: "",
    error: "",
  };

  modalBodyContent = `
  <div id="contactAgentModal">
  </div>`;

  modalFooter = `
    <button class="btn btn-tertiary" type="button" data-dismiss="modal">Cancel</button>
    <button class="btn btn-primary" type="button" data-action="click->contact-agent#submitForm">Send</button>
  `;

  contactAgentModalOpened(evt) {
    let modalDataUrl = evt.target.dataset.modalDataUrl + "?flex_panel=true";

    this.handleResponse.success = evt.target.dataset.successMessage;
    this.handleResponse.error = evt.target.dataset.errorMessage;

    window.dispatchEvent(
      new CustomEvent("display-local-modal", {
        detail: {
          modalCssClasses: "modal-dialog modal-sm c-modal",
          modalContent: this.modalBodyContent,
          modalFooterContent: this.modalFooter,
          modalHeader: `<div class="t-title--large">Contact Agent</div>`,
        },
      })
    );

    $("#contactAgentModal").load(modalDataUrl);
  }

  async requestShowingModalOpened(evt) {
    let modalDataUrl = evt.target.dataset.modalDataUrl + "?flex_panel=true";
    let listingAddress = evt.target.dataset.modalListingAddress;

    this.handleResponse.success = evt.target.dataset.successMessage;
    this.handleResponse.error = evt.target.dataset.errorMessage;

    window.dispatchEvent(
      new CustomEvent("display-local-modal", {
        detail: {
          modalCssClasses: "modal-dialog modal-sm c-modal",
          modalContent: this.modalBodyContent,
          modalFooterContent: this.modalFooter,
          modalHeader: `<div class="t-title--large">Request a Showing</div>`,
        },
      })
    );

    const modalBody = await $.get(modalDataUrl);
    const $modalBody = $(modalBody);
    const modalMessageBody = `I'm interested in viewing ${listingAddress}`;
    $modalBody.find("#contactAgentMessageBody").val(modalMessageBody);
    $("#contactAgentModal").html($modalBody);
  }

  submitForm() {
    $("#contactAgentForm").trigger("submit.rails");
    $("#contactAgentForm")
      .submit(showPageLoadingMsg)
      .on("ajax:success", this.handleSuccess)
      .on("ajax:error", this.handleError)
      .on("ajax:complete", hidePageLoadingMsg);
  }

  handleSuccess = () => {
    toast.success(this.handleResponse.success);
    window.dispatchEvent(new CustomEvent("dismiss-local-modal"));
  };

  handleError = (event, xhr, status, message) => {
    if (xhr.status === 500) {
      // handle server error
      toast.error(this.handleResponse.error, false);
    } else {
      // handle validation error
      this.errors = JSON.parse(xhr.responseText);

      this.removeErrors();

      $.each(this.errors, function (field_name, error_message) {
        this.field = $('[name="listing_message[' + field_name + ']"]');
        this.field.closest(".form-group").addClass("has-error");
        this.field.siblings(".errorMessage").text(error_message);
      });
    }
  };

  removeErrors = () => {
    $("#contactAgentModal").find(".form-group").removeClass("has-error");
    $("#contactAgentModal").find(".errorMessage").text("");
  };
}
