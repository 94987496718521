export class Field {

  constructor(data) {
    this.id           = data.id;
    this.domain       = data.domain;
    this.groupName    = data.groupName;
    this.groupLabel   = data.groupLabel;
    this.type         = data.type;

    if (this.domain === "CustomFields") {
      this.fieldStr = '"' + this.groupName + '"."' + this.id + '"';
    } else {
      this.fieldStr = this.id;
    }
  }

}
