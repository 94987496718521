import MarkerWithLabel from 'markerwithlabel';
import ListingMarker from './listing_marker';

/*

    Price Marker

      Inherits from Listing Marker. It include the price and a property type icon. It's shown when there are
      only a few listings to be plotted on the map.

    Options argument:

      listingId: (required)
      listingStatus: (required)
      lat: (required)
      lng: (required)
      price: (required)
      low_price: (optional)
      high_price: (optional)
      mls_id: (optional)
      property_type: (optional)
      currencySymbol: (required)
      propertyClass: (required)

 */

export default function PriceMarker(opts) {
  this.width = 80;
  this.height = 34;

  this.listingId = opts.listingId;
  this.listingStatus = opts.listingStatus;
  this.propertyClass = opts.propertyClass;
  this.lat = opts.lat;
  this.lng = opts.lng;  
  this.price = opts.price;
  this.low_price = opts.low_price;
  this.high_price = opts.high_price;
  this.mls_id = opts.mls_id;
  this.property_type = opts.property_type;
  this.currencySymbol = opts.currencySymbol;
  this.foreignCurrency = opts.foreignCurrency;

  this.labelAnchor = new google.maps.Point(this.width/2, this.height);

  var labelClasses = 'marker-price';

  if(this.listingStatus) {
    labelClasses += ' marker-price marker-status-' + this.listingStatus.toLowerCase().replace(/\s/g, '-')
  }

  var labelOptions = {
    labelClass: labelClasses,
    labelAnchor: this.labelAnchor,
    labelContent: this._buildPriceMarkerHtml(),
    position: new google.maps.LatLng(this.lat, this.lng),
    icon: " " // hide the normal goggle map pins
  }

  MarkerWithLabel.call(this, labelOptions);

}

PriceMarker.prototype = Object.create(ListingMarker.prototype);

PriceMarker.prototype.constructor = PriceMarker;

//
// Public functions
// 



//
// Private functions
// 


PriceMarker.prototype._buildPriceMarkerHtml = function(){
  var html = '<div class="arrow"></div>';
  html += this._iconHtml();
  html += '<span class="marker-content">' + this._listingPrice() + '</span>';
  return html;
};

PriceMarker.prototype._iconHtml = function() {
  var iconClass;

  switch (this.propertyClass){
    case 'Commercial':
      iconClass = 'flex-icon flex-icon-map_pin_commercial'; break;
    case 'Land':
    case 'Farm':
      iconClass = 'flex-icon flex-icon-map_pin_land'; break;
    case 'Rental':
    case 'MultiFamily':
      iconClass = 'flex-icon flex-icon-map_pin_multifamily'; break;
    default:
      iconClass = 'flexicon flexicon-house--large';
  }

  return '<span class="marker-icon ' + iconClass + '"></span>';
};


PriceMarker.prototype._listingPrice = function() {
  // SCC Price Range display
  if (this.mls_id == '20220727192737510867000000' && (this.property_type == 'B' || (this.property_type == 'A' && this.low_price != undefined && this.high_price != undefined)))
    return this.currencySymbol + this._humanizeCurrency(this.low_price, 3) + " - " + this.currencySymbol + this._humanizeCurrency(this.high_price, 3);
  else {
    let foreign_currency_string = "";
    for (let i in this.foreignCurrency) {
      let currency = this.foreignCurrency[i];
      foreign_currency_string += currency.currency + this._humanizeCurrency(currency.amount.toFixed(2), 3) + " / ";
    }
    return foreign_currency_string + this.currencySymbol + this._humanizeCurrency(this.price, 3);
  }
};


PriceMarker.prototype._humanizeCurrency = function(amount, numPlaces) {
  /* needed because polyfill for log10 is WRONG
     TODO: replace me pfill is fixed */
  function froundPolyfill (f) {
    return new Float32Array([f])[0];
  }
  /*
    9000 -> 9000
    250009 -> 250K
    43392 -> 43.4K
    1305000 -> 1.31M
  */

  var LOG_ONE_MIL = 6,
      amt = Math.abs(amount),
      exp = froundPolyfill(Math.log(amt) / Math.LN10),
      suffix,
      val;

  //if first condition is true, no formatting will be done
  if (amount < 10000) {
    val = amount;
    suffix = '';
  } else if (exp < LOG_ONE_MIL) {
    val = parseFloat( (amt / 1000).toPrecision(numPlaces) );
    suffix = 'K';
  } else {
    val = parseFloat( (amt / 1000000).toPrecision(numPlaces) );
    suffix = 'M';
  }

  return val + suffix;
}
