import {setState} from './state'

export class SearchTemplateViewControl {
  constructor(selector){
    this.control = $(selector);
    $(document).on('click', `${selector} .userViewLink`, this._handleViewLinkClick.bind(this));
  }

  _handleViewLinkClick(e){
    e.preventDefault();
    setState('userViewId', $(e.currentTarget).attr('data-view-id'));
    $('#userViews .selected-view').removeClass('selected-view'); // De-select old view, select new view
    $(e.currentTarget).addClass('selected-view');
    this._tableHeaders();
  }

  _tableHeaders(){
    var propType = $("#propertyTypeSelector option:selected").val();
    var selectedView = $('.selected-view').attr('data-view-id');
    if(propType && selectedView){
    $.ajax({
      url: 'views/' + selectedView + '?property_type=' + propType,
      dataType: 'json',
      success: function(d){
        var tableHeader = $("#listingsDataTable thead tr");
        tableHeader.find('th:not(.preserve)').remove(); // MLS #, Status, and Price are always preserved in the table
        var threeDotMenu = tableHeader.find('th').last().detach(); // Get last element which will be 3 dot menu holder - we want to insert table headers before that
        $(d).each(function(i,tableTitle){
          tableHeader.append('<th>' + tableTitle + '</th>')
        });
        tableHeader.append(threeDotMenu)
      },
      error: function(e){
        console.log('error' + JSON.stringify(e))
      }
    })
    } 
  }
}