import PreviewMarker from './preview_marker';

/*

    Nosy Neighbor Marker

    This inherits from the PreviewMarker and works similarly. This is used when the user has clicked a spot 
    on the map where we can't find a listing. The content of the popup is much more limited than the Preview Marker.

    Options argument:

      address: (required)
      listingId: (required)
      lat: (required)
      lng: (required)

      mapWidth(): (required) A function that returns the width of the map in px.
      
      listingMarker: (required) In the UI, Preview markers are "attached" to a regular listing marker. The
        Preview marker will appear when the user hovers over the listing marker. This attribute will be the
        marker that is associated with this preview marker.

 */



export default function NosyNeighborMarker(options) {
  this.address = options.address;
  
  var opts = $.extend({}, options, {showSlideshow: false});
  delete opts.address;

  PreviewMarker.call(this, opts);
}

NosyNeighborMarker.prototype = Object.create(PreviewMarker.prototype);
NosyNeighborMarker.prototype.constructor = NosyNeighborMarker;


//
// Public functions
// 



//
// Private functions
// 

NosyNeighborMarker.prototype._getPreviewHtml = function() {
  var partial =  '<div class="marker-preview" data-listing-show-url="#">';
      partial += '<div class="lc-thumbnail--container">';
      partial += '<div class="preview-slideshow-container nosy-neighbor-image-container"></div>';
      partial += '<div class="lc-info"><div class="lc-price--container"><div class="t-title--larger"></div></div></div></div><div class="lc-details--container"><div class="lc-address">' + this.address + '</div><div class="lc-status-details"><span class="lc-status lc-status--expired">Public Records</span><div class="lc-details"></div></div></div></div>';
  this._buildPreviewMarker(partial);
}
