import { Controller } from "stimulus";

export default class extends Controller {

  listingClicked(evt) {

    // Before viewing a listing:  are there restrictions on viewing the listing
    if (Flexmls.currentUser.role !== "portal" && Flexmls.currentUser.role !== "vow" && Flexmls.currentUser.role !== "private") {
      // Possible restrictions.  Lets look deeper
      if (this.element.dataset.idxRegistrationDetailRequiresRegistration) {
        // We need to be registered and it doesnt look like we are

        // We want it to load the LDP when it comes back.
        let newLocation = new URL(Flexmls.currentUser.authUri);
        newLocation.searchParams.set('state',newLocation.searchParams.get('state') + "/" + evt.currentTarget.id)

        window.location = newLocation;
        // Probably dont need this.  Safety first.
        // This will prevent the next action from being performed
        evt.stopImmediatePropagation()
      }
    }
  }
}