import { Controller } from "stimulus";
import { 
  updateCartCount,  
  checkCurrentUser,
  getCartCount
} from "../helpers/select_listings_helpers"

export default class extends Controller {

  connect() {
    checkCurrentUser();
    updateSelectedListingsLink();
    appendSelectedListingsToUrl();
  }
}

function appendSelectedListingsToUrl() {
  var count = getCartCount();
  
  if (count > 0) {
    let selectedCollectionLink = $("#selected-cart > a");
    
    let selectedHref = selectedCollectionLink?.attr("href");
    let selected_listing_ids = localStorage.getItem("selectedCart");
    if (selectedHref && selected_listing_ids) {
      let url = new URL(selectedHref);
      url.searchParams.append('selected_listing_ids', selected_listing_ids);
      // window.location.href = url.href;
      selectedCollectionLink.attr("href", url);
    }
    
  }
}

function updateSelectedListingsLink() {
  updateCartCount();
  
  var count = getCartCount();
  
  var selectedCollectionButton = $("#selected-cart > a > div.c-item__title > span");
  var selectedCollectionLink = $("#selected-cart > a");
  
  if (count !== parseInt(selectedCollectionButton, 10)) {
    if (selectedCollectionButton.length) {
      selectedCollectionButton.html(count);
    }
    
    if (selectedCollectionLink.length && count == 0) {
      selectedCollectionLink.removeAttr("href");
    }
  }
}
